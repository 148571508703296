import {Injectable} from "@angular/core";
import {StandardEvent} from "../standard.event";
import {ReplaySubject} from "rxjs";
import {ContentPartner} from "../../../models/content-partner/content-partner.model";

@Injectable()
export class AllProductsLoadedEvent extends StandardEvent<ContentPartner[]> {
    constructor() {
        super(new ReplaySubject<ContentPartner[]>());
    }

    dispatch(data: ContentPartner[]): void {
        this.subject.next(data);
    }
}