import {Injectable} from "@angular/core";
import {of} from "rxjs";
import {SubscriberContract} from "../../contracts/subscriber.contract";

@Injectable()
export class ChangeLanguageSubscriber implements SubscriberContract<any> {
    constructor() {}

    async handle(): Promise<any> {
        return of().toPromise()
    }
}