import { Subscription } from 'rxjs';
import { DynamicLinkService } from './../../../services/dynamic-link.service';
import { AppService } from './../../../services/app.service';
import { Component, EventEmitter, OnInit, Output, OnDestroy } from '@angular/core';

@Component({
  selector: 'qup-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss'],
})
export class ConsentComponent implements OnInit, OnDestroy {
  _subs: Subscription[] = [];
  @Output() changePreferenceClick = new EventEmitter();
  @Output() acceptAllClick = new EventEmitter();

  cookieStatement!: string;

  constructor(
    private appS: AppService,
    private dynamicLinkService: DynamicLinkService
  ) {}

  ngOnInit(): void {
    this._subs.push(
      this.appS.currentLanguage$.subscribe(
        _ => {
          this.cookieStatement = this.dynamicLinkService.cookiesDoc
        }
      )
    )
  }

  ngOnDestroy(): void {
    this._subs.forEach(s => s?.unsubscribe());
  }
}
