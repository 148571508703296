import {Injectable} from "@angular/core";
import {ReplaySubject} from "rxjs";
import {StandardEvent} from "../../../../dispatch/events/standard.event";
import {AddVoucherRequest} from "../../../../interfaces/content-api.interface";


@Injectable()
export class ApplySingleVoucherEvent extends StandardEvent<AddVoucherRequest> {

    constructor() {
        super(new ReplaySubject<AddVoucherRequest>());
    }

    dispatch(payload: AddVoucherRequest): void {
        this.subject.next(payload);
    }
}