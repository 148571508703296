import {Injectable} from "@angular/core";
import {SentryErrorHandler} from "@sentry/angular";
import {ErrorHandlerOptions} from "@sentry/angular/errorhandler";

export class MainErrorHandler extends SentryErrorHandler {
    constructor(options: ErrorHandlerOptions) {
        super(options);
    }

    handleError(error: any): void {
        // Handle chunk errors.
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (chunkFailedMessage.test(error.message)) {
            window.location.reload();
        } else {
            super.handleError(error);
        }
    }
}

export function createErrorHandler(config: ErrorHandlerOptions): MainErrorHandler {
    return new MainErrorHandler(config);
}