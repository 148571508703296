import {Component} from "@angular/core";
import {CognitoService} from "../../../../services/cognito.service";
import {Router} from "@angular/router";

@Component({
    selector: 'qup-forgot-password',
    templateUrl: '../../templates/delete-account/delete-account.component.html',
    styleUrls: ['../../templates/delete-account/delete-account.component.scss']
})
export class DeleteAccountComponent {

    constructor(
        private cognitoProvider: CognitoService,
        private router: Router
    ) {
    }

    deleteAccount(): void {
        this.cognitoProvider.deleteAccount().subscribe(() => {
            this.router.navigate(['account/login']);
        });
    }
}