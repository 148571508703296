<form [formGroup]="formGroup" style="display: contents;">
  <div class="custom-arrow-wrapper">
    <div *ngIf="_multiSelect" class="selected-num" [ngClass]="{'visible': visible}">
      {{selectNum}}</div>
    <svg class="icon" xmlns="http://www.w3.org/2000/svg" role="img" focusable="img">
      <use xlink:href="assets/images/icon__sprite.svg#arrow-down"></use>
    </svg>
  </div>
  <ng-select [clearable]="clearable" [formControlName]="controlName" qupCustomNgSelect [ngClass]="[subClass, klass]"
    aria-placeholder="Select type filter" (change)="change($event)" [searchable]="searchable" [placeholder]="placeholder" [items]="items" [multiple]="_multiSelect"
    [bindLabel]="bindLabel" [bindValue]="bindValue">

    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
      <ng-container *ngIf="_multiSelect; else singleSelectOpt">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" [attr.checked]="item$.selected ? true : null" id="{{ 'item_' + index }}"
            class="custom-control-input" />
          <label for="{{ 'item_' + index }}" class="custom-control-label">{{ item[bindLabel] }}</label>
        </div>
      </ng-container>
      <ng-template #singleSelectOpt>
        <div class="single-select-opt" [ngClass]="item$.selected ? 'selected' : ''">
          {{ item[bindLabel] }}
        </div>
      </ng-template>
    </ng-template>

  </ng-select>
</form>
