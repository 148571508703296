import {Injectable} from "@angular/core";
import {EventsDispatcher} from "../../dispatch/events.dispatcher";
import {SendLostTicketsEmailEvent} from "./dispatch/events/send-lost-tickets-email.event";
import {SendLostTicketsEmailSubscriber} from "./dispatch/subscribers/send-lost-tickets-email.subscriber";


@Injectable()
export class LostTicketsEventRegistry {
    constructor(
        private dispatcher: EventsDispatcher
    ) {
        console.log('loading registry');
        dispatcher
            .register(SendLostTicketsEmailEvent, [SendLostTicketsEmailSubscriber]);
    }
}