<div class="container">
    <div class="row">
        <div class="{{isMobile ? 'col-12' : 'col-8'}}">
            <div>
                <div class="row">
                    <div class="col-12">
                        <h3>{{'LOGIN.title' | translate}}</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class="mb-2">{{'LOGIN.login_text_1' | translate}}</p>
                        <p class="mb-2">{{'LOGIN.login_text_2' | translate}}</p>
                    </div>
                </div>
                <div class="row" *ngIf="environment.federatedLogin">
                    <div class="col-12">
                        <button
                                class="btn btn-lg login-button"
                                (click)="onLoginRedirect()">
                            {{'LOGIN.login_button' | translate}}
                        </button>
                    </div>
                </div>
            </div>
            <form [formGroup]="form" *ngIf="!environment.federatedLogin" (ngSubmit)="submitLogin()">
                <div class="row mb-2">
                    <div class="col-12">
                        <label>{{ 'LOGIN.email_label' | translate }}</label>
                        <input type="email" name="email" formControlName="email">
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-12">
                        <label>{{ 'LOGIN.password_label' | translate }}</label>
                        <input type="password" name="password" formControlName="password">
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button
                                type="submit" [disabled]="!form.valid" class="btn btn-lg login-button">
                            {{'LOGIN.login_button' | translate}}
                        </button>
                    </div>
                </div>
                <div class="row ml-1 mt-2">
                    <a href="/account/register">{{'LOGIN.register_redirect' | translate}}</a>
                </div>
                <div class="row ml-1 mt-2">
                    <a (click)="forgotPassword()">{{'LOGIN.forgot_password_button' | translate}}</a>
                </div>
            </form>
        </div>
        <div class="col-4" *ngIf="!isMobile && environment.federatedLogin">
            <div class="monkey-ball">
                <span class="material-symbols-outlined">key</span>
            </div>
        </div>
    </div>


</div>

<qup-download-app-banner [isMobile]="isMobile"></qup-download-app-banner>