import {Component} from "@angular/core";
import {environment} from "../../../../../environments/environment";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CognitoService} from "../../../../services/cognito.service";
import {first} from "rxjs/operators";
import {RAISE_ERROR} from "../../../../dispatch/events.registry";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";

enum RecoverPasswordState {
    INITIAL,
    CONFIRMATION,
}

@Component({
    selector: 'qup-forgot-password',
    templateUrl: '../../templates/forgot-password/forgot-password.component.html',
    styleUrls: ['../../templates/forgot-password/forgot-password.component.scss']
})
export class ForgotPasswordComponent {

    recoverPasswordState = RecoverPasswordState.INITIAL;
    userName!: string;

    recoverForm: FormGroup = this.formBuilder.group({
        email: [null, [Validators.required, Validators.email]],
    });

    confirmation: FormGroup = this.formBuilder.group({
        confirmationCode: [null, [Validators.required]],
        password: [null, [Validators.required]],
        repeatPassword: [null, [Validators.required]]
    });

    constructor(
        private formBuilder: FormBuilder,
        private cognitoProvider: CognitoService,
        private translateService: TranslateService,
        private router: Router
    ) {
    }

    submitRecover(): void {
        const email = this.recoverForm.get('email')?.value;

        this.cognitoProvider.requestPasswordReset(email).subscribe(() => {
            this.userName = email;
            this.recoverPasswordState = RecoverPasswordState.CONFIRMATION;
        });
    }

    confirmRecovery(): void {
        if (this.confirmation.get('password')?.value !== this.confirmation.get('repeatPassword')?.value) {
            this.translateService.get('REGISTRATION.error_password_match').pipe(first()).subscribe(RAISE_ERROR);
            return;
        }

        let confirmationCode = this.confirmation.get('confirmationCode')?.value;
        let password = this.confirmation.get('password')?.value;

        this.cognitoProvider.confirmPasswordReset(
            this.userName,
            confirmationCode,
            password
        ).pipe(first()).subscribe(() => {
            this.router.navigate(['account/login']);
        });
    }

    protected readonly environment = environment;

    protected readonly RecoverPasswordState = RecoverPasswordState;
}
