import {CheckoutProvider} from "../../../providers/checkout.provider";
import {Injectable} from "@angular/core";
import {of} from "rxjs";
import {SubscriberContract} from "../../contracts/subscriber.contract";
import {Reservation} from "../../../models/checkout/reservation.model";
import {catchError} from "rxjs/operators";

@Injectable()
export class RebuildBillingSubscriber implements SubscriberContract<Reservation|null> {
    constructor(private checkoutProvider: CheckoutProvider) {}

    async handle(reservationId?: number): Promise<Reservation|null> {
        if (reservationId != undefined) {
            return of(null).toPromise();
        }

        this.checkoutProvider.reservation().next(null);
        return this.checkoutProvider.rebuildBilling().pipe(
            catchError(error => of(null))
        ).toPromise();
    }
}