import {Injectable} from "@angular/core";
import {StandardEvent} from "../standard.event";
import {ReplaySubject} from "rxjs";

@Injectable()
export class InvalidStateEvent extends StandardEvent<void> {
    constructor() {
        super(new ReplaySubject<void>());
    }

    dispatch(): void {
        this.subject.next();
    }
}