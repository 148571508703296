<div class="container mt-2">
    <div class="row" [formGroup]="form">
        <div *ngIf="isMobile" class="balance-container mb-2 col-12">
            <div class="row">
                <div class="col-12">
                    <img src="/assets/images/coin_stack.svg" alt="vouchers"/>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <strong>&euro; {{balance | number:'1.2-2'}}</strong>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <span>{{'ACCOUNT.balance_title' | translate}}</span>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12">
                    <button type="button" class="btn logout-button" (click)="signOutClick()">{{'ACCOUNT.logout_button' | translate }}</button>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12" *ngIf="!environment.federatedLogin">
                    <button type="button" class="btn logout-button" (click)="deleteAccountClick()">{{'ACCOUNT.delete_account_button' | translate }}</button>
                </div>
            </div>
        </div>
        <div class="account-container {{isMobile ? 'col-12' : 'col-8' }}">
            <div class="row">
                <div class="col-12">
                    <h3>{{'ACCOUNT.title' | translate}}</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p>{{'ACCOUNT.description_1' | translate}}</p>
                    <p>{{'ACCOUNT.description_2' | translate}}</p>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <label>{{'ACCOUNT.code_input_label' | translate}}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <input type="text" name="voucher" formControlName="voucher"/>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12">
                    <button type="submit" [disabled]="buttonDisabled()" class="btn add-voucher-button" (click)="addVoucher()">{{'ACCOUNT.code_input_button' | translate }}</button>
                </div>
            </div>
        </div>
        <div *ngIf="!isMobile" class="balance-container ml-4 col-4">
            <div class="row">
                <div class="col-12">
                    <img src="/assets/images/coin_stack.svg" alt="vouchers"/>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <strong>&euro; {{balance | number:'1.2-2'}}</strong>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <span>{{'ACCOUNT.balance_title' | translate}}</span>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12">
                    <button type="button" class="btn logout-button" (click)="signOutClick()">{{'ACCOUNT.logout_button' | translate }}</button>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12" *ngIf="!environment.federatedLogin">
                    <button type="button" class="btn logout-button" (click)="deleteAccountClick()">{{'ACCOUNT.delete_account_button' | translate }}</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!--<div class="container share bottom mt-2">-->
<!--    <div class="row">-->
<!--        <div class="col-12">-->
<!--            <h3>{{'ACCOUNT.share_title' | translate}}</h3>-->
<!--        </div>-->
<!--    </div>-->
<!--    <div class="row">-->
<!--        <div class="col-3">-->
<!--            {{shareableVouchers()}}-->
<!--            <strong>&euro; {{balance | number:'1.2-2'}}</strong>-->
<!--        </div>-->
<!--        <div class="col-3">-->
<!--            <div class="buttons-container d-flex justify-content-end">-->
<!--                <div class="mr-2">-->
<!--                    <button class="btn cart-button remove-item"></button>-->
<!--                </div>-->
<!--                <div>-->
<!--                    <button class="btn cart-button add-item"></button>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->
<qup-lost-tickets></qup-lost-tickets>
<qup-download-app-banner [isMobile]="isMobile"></qup-download-app-banner>
