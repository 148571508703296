import {Injectable} from "@angular/core";
import {StandardEvent} from "../standard.event";
import {ReplaySubject} from "rxjs";
import {FiltersInput} from "../../../interfaces/content-api.interface";


@Injectable()
export class FilterProductByCategoryCityEvent extends StandardEvent<FiltersInput> {
    constructor() {
        super(new ReplaySubject<FiltersInput>());
    }

    dispatch(filters: FiltersInput): void {

        this.subject.next(filters);
    }
}