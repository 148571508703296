import {Injectable} from "@angular/core";
import {EventsDispatcher} from "../../dispatch/events.dispatcher";
import {ReservationPlacedEvent} from "../../dispatch/events/checkout/reservation-placed.event";
import {LoyaltySubscriber} from "./dispatch/subscribers/loyalty.subscriber";
import {LoyaltyReservationPlacedEvent} from "./dispatch/events/loyalty-reservation-placed.event";
import {ApplyLoyaltyDiscountSubscriber} from "./dispatch/subscribers/apply-loyalty-discount.subscriber";
import {LoyaltyQuantityChangeEvent} from "./dispatch/events/loyalty-quantity-change.event";
import {CheckoutResetEvent} from "../../dispatch/events/checkout/checkout-reset.event";
import {ResetLoyaltySubscriber} from "./dispatch/subscribers/reset-loyalty.subscriber";
import {OrderOverviewGeneratedEvent} from "../../dispatch/events/checkout/order-overview-generated.event";
import {InjectLoyaltyOverviewSubscriber} from "./dispatch/subscribers/inject-loyalty-overview.subscriber";
import {RaiseErrorEvent} from "../../dispatch/events/core/raise-error.event";

export let RAISE_LOYALTY_AUTH_ERROR: (...parameters: string[]) => void;

@Injectable()
export class LoyaltyEventRegistry {
    constructor(
        private dispatcher: EventsDispatcher
    ) {
        dispatcher
            .register(CheckoutResetEvent, [ResetLoyaltySubscriber])
            .register(ReservationPlacedEvent, [LoyaltySubscriber])
            .register(LoyaltyReservationPlacedEvent, [ApplyLoyaltyDiscountSubscriber])
            .register(LoyaltyQuantityChangeEvent, [LoyaltySubscriber], EventsDispatcher.arrayAsArguments)
            .register(OrderOverviewGeneratedEvent, [InjectLoyaltyOverviewSubscriber])
        ;

        RAISE_LOYALTY_AUTH_ERROR = dispatcher.createInvokeFunction(RaiseErrorEvent);

    }
}