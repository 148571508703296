import {Injectable} from "@angular/core";
import {Observable, of} from "rxjs";
import Auth from "@aws-amplify/auth";
import {fromPromise} from "rxjs/internal-compatibility";
import {catchError, first, map, tap} from "rxjs/operators";
import {ICredentials} from "aws-amplify/lib/Common/types/types";
import {CognitoUser, CognitoUserSession, ISignUpResult} from "amazon-cognito-identity-js";
import {StorageNS, StorageService} from "./storage.service";
import {RAISE_ERROR} from "../dispatch/events.registry";
import {TranslateService} from "@ngx-translate/core";
import {CookieService} from "./cookie.service";

@Injectable()
export class CognitoService {

    public cognitoErrors = new Map([
        ['Incorrect username or password', this.translateService.get('LOGIN.error_invalid_password')],
        ['User does not exist.', this.translateService.get('LOGIN.error_invalid_user')],
        ['Password attempts exceeded', this.translateService.get('LOGIN.error_too_many_attempts')],
        ['Username/client id combination not found.', this.translateService.get('LOGIN.error_unknown_email')],
        ['Password did not conform with policy', this.translateService.get('LOGIN.error_password_not_strong_enough')],
        ['User already exists', this.translateService.get('LOGIN.error_user_already_exists')],
        ['Invalid verification code provided', this.translateService.get('LOGIN.invalid_vertification_code')]
    ]);

    public constructor(
        private storageService: StorageService,
        private translateService: TranslateService,
        private cookieService: CookieService
    ) {
    }


    /**
     * First check if we have a "normal" cognito session running.
     * Then fall back to check whether an "assumed" session is running with an access token in local storage "plus_token"
     */
    public isLoggedIn(): Observable<boolean> {
        return this.user().pipe(
            map((user) => {
                return true;
            }),
            catchError((err) => {
                return this.hasAssumedSession();
            }),
            tap((isLoggedIn) => {
                if (isLoggedIn) {
                    this.storeSubject();
                } else {
                    this.deleteSubject();
                }
            })
        );
    }

    public startLogin(customProvider: string): Observable<ICredentials> {
        return fromPromise(Auth.federatedSignIn({customProvider : customProvider}));
    }

    public startNativeLogin(userName: string, password: string): Observable<CognitoUser> {
        return fromPromise(Auth.signIn(userName, password)).pipe(catchError(this.handleCognitoError.bind(this)));
    }

    public logout(): Observable<void> {
        return fromPromise(Auth.signOut()).pipe(catchError(this.handleCognitoError.bind(this)));
    }

    private session(): Observable<CognitoUserSession> {
        return fromPromise(Auth.currentSession()).pipe(catchError(this.handleCognitoError.bind(this)));
    }

    private assumedSessionToken(): Observable<string | null> {
        return of(this.storageService.getItem(StorageNS.PLUS_TOKEN));
    }

    public hasAssumedSession(): Observable<boolean> {
        return this.assumedSessionToken().pipe(map(
            (token) => {
                return token !== null;
            }
        ));
    }

    public getAccessToken(): Observable<string|null> {
        return this.session().pipe(
            map((session) => {
                return session.getAccessToken().getJwtToken();
            }),
            catchError((err) => {
                return this.assumedSessionToken();
            })
        );
    }

    public getCurrentSubject(): Observable<string> {
        return this.getAccessToken().pipe(
            map((token) => {
                if (token === null) {
                    return '';
                }

                return JSON.parse(atob(token.split('.')[1]))["sub"];
            })
        );
    }

    public user(): Observable<CognitoUser> {
        return fromPromise(Auth.currentAuthenticatedUser()).pipe(catchError(this.handleCognitoError.bind(this)));
    }

    public register(userName: string, password: string): Observable<ISignUpResult> {
        return fromPromise(
            Auth.signUp({
                username: userName,
                password: password,
                attributes: {
                    email: userName
                }
            })).pipe(catchError(this.handleCognitoError.bind(this)));
    }

    public confirmRegistration(userName: string, confirmationCode: string): Observable<any> {
        return fromPromise(Auth.confirmSignUp(userName, confirmationCode)).pipe(catchError(this.handleCognitoError.bind(this)));
    }

    public confirmPasswordReset(userName: string, confirmationCode: string, newPassword: string): Observable<any> {
        return fromPromise(Auth.forgotPasswordSubmit(userName, confirmationCode, newPassword)).pipe(catchError(this.handleCognitoError.bind(this)));
    }

    public requestConfirmationCode(userName: string): Observable<any> {
        return fromPromise(Auth.resendSignUp(userName)).pipe(catchError(this.handleCognitoError.bind(this)));
    }

    public requestPasswordReset(userName: string): Observable<any> {
        return fromPromise(Auth.forgotPassword(userName)).pipe(catchError(this.handleCognitoError.bind(this)));
    }

    public deleteAccount(): Observable<string> {
        return fromPromise(Auth.deleteUser()).pipe(catchError(this.handleCognitoError.bind(this)));
    }

    public handleCognitoError(err: any, caught: Observable<any>): Observable<any> {
        if (err instanceof Error) {
            err = err.message;
        }

        this.cognitoErrors.forEach((value, key) => {
           if (err.indexOf(key) !== -1) {
               value.pipe(first()).subscribe(RAISE_ERROR);
           }
        });
        
        throw caught;
    }

    public storeSubject(): void {
        this.getCurrentSubject().pipe(first()).subscribe((subject) => {
            if (subject == '') {
                return;
            }
            this.cookieService.setCookie('__dagjeuit_subject', subject, {"max-age": 86400, path: "/"});
        });
    }

    public deleteSubject(): void {
        this.cookieService.deleteCookie('__dagjeuit_subject');
    }
}