import {Injectable} from "@angular/core";
import {SubscriberContract} from "../../contracts/subscriber.contract";
import {AttractionProvider} from "../../../providers/attraction.provider";
import {ContentPartner} from "../../../models/content-partner/content-partner.model";
import {tap} from "rxjs/operators";


@Injectable()
export class AttractionMainSearchProductSubscriber implements SubscriberContract<ContentPartner[]> {
    constructor(
        private attractionsMainProvider: AttractionProvider
    ) {
    }

    async handle(searchTerm: string): Promise<ContentPartner[]> {
        return this.attractionsMainProvider.searchContentPartners(searchTerm).pipe(
            tap((response) => {
                this.attractionsMainProvider.contentPartners().next(response);
            })
        ).toPromise();
    }
}