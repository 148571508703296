import { CusSelectComponent } from './cus-select.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomNgSelectDirectiveModule } from '../custom-ng-select-directive/custom-ng-select-directive.module';

@NgModule({
  declarations: [
    CusSelectComponent,
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule,
    CustomNgSelectDirectiveModule
  ],
  exports: [
    CusSelectComponent,
    ReactiveFormsModule,
    FormsModule,
    CustomNgSelectDirectiveModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CusSelectModule { }
