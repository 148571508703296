<div class="btn-container">
  <div (click)="leftArrowClick()" class="arrow arrow-left">
    <button #leftArrow class="btn btn-slider"
      data-toggle="header/navigation/menu/main/slider/next" aria-hidden="false"><svg
        class="icon icon-16" xmlns="http://www.w3.org/2000/svg" role="img" focusable="false">
        <title>Next Slide</title>
        <use xlink:href="assets/images/icon__sprite.svg#arrow-left" fill="black"></use>
      </svg></button>
  </div>

  <div (click)="rightArrowClick()" class="arrow arrow-right">
    <button #rightArrow class="btn btn-slider"
      data-toggle="header/navigation/menu/main/slider/next" aria-hidden="false"><svg
        class="icon icon-16" xmlns="http://www.w3.org/2000/svg" role="img" focusable="false">
        <title>Next Slide</title>
        <use xlink:href="assets/images/icon__sprite.svg#arrow-right" fill="black"></use>
      </svg></button>
  </div>
</div>

<div #contentContainer class="content-container">
  <ng-content></ng-content>
</div>

