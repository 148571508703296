import {Injectable} from "@angular/core";
import {EventsDispatcher} from "../../dispatch/events.dispatcher";
import {ApplySingleVoucherEvent} from "./dispatch/events/apply-single-voucher.event";
import {ApplySingleVoucherSubscriber} from "./dispatch/subscribers/apply-single-voucher.subscriber";

@Injectable()
export class VoucherEventRegistry {
    constructor(
        private dispatcher: EventsDispatcher
    ) {
        dispatcher
            .register(ApplySingleVoucherEvent, [ApplySingleVoucherSubscriber])
        ;
    }
}