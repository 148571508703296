import {Injectable} from "@angular/core";
import {ContentApiService} from "../services/content-api.service";
import {AppService} from "../services/app.service";
import {BehaviorSubject, Observable, ReplaySubject, Subject} from "rxjs";
import {environment, environment as env} from "../../environments/environment";
import {map, tap} from "rxjs/operators";
import {ContentPartner} from "../models/content-partner/content-partner.model";
import {Category} from "../models/home/category.model";
import {Campaign} from "../models/home/campaign.model";
import {AllProductsLoadedEvent} from "../dispatch/events/attractions-main/all-products-loaded.event";
import {DateTime} from "luxon";
import {CalendarItem} from "../models/calendar/calendar-item.model";

@Injectable()
export class AttractionProvider {

    private campaignSubscription = new ReplaySubject<Campaign>(1);
    private categoriesSubscription = new ReplaySubject<Category[]>(1);
    private contentPartnersSubscription = new ReplaySubject<ContentPartner[]>(1);
    private selectedContentPartnerSubscription = new BehaviorSubject<ContentPartner | null>(null);
    private selectedCategorySubscription: BehaviorSubject<string|null> = new BehaviorSubject<string|null>(null);

    constructor(
        private contentApiService: ContentApiService,
        private appService: AppService,
        private allProductsLoaded: AllProductsLoadedEvent
    ) {
    }

    campaign(): ReplaySubject<Campaign> {
        return this.campaignSubscription;
    }

    categories(): ReplaySubject<Category[]> {
        return this.categoriesSubscription;
    }

    contentPartners(): ReplaySubject<ContentPartner[]> {
        return this.contentPartnersSubscription;
    }

    selectedContentPartner(): BehaviorSubject<ContentPartner | null> {
        return this.selectedContentPartnerSubscription;
    }

    selectedCategory(): BehaviorSubject<string|null> {
        return this.selectedCategorySubscription;
    }

    /**
     * SEARCH CONTENT PARTNER
     */

    searchContentPartners(searchTerm: string): Observable<ContentPartner[]> {
        return this.contentApiService.searchProducts(this.appService.currentLanguage, env.campaignUuid, searchTerm).pipe(
            map((data) => {
                return data;
            }),
            tap((data) => this.allProductsLoaded.dispatch(data))
        );
    }

    selectCategory(category: string|null): void {
        this.selectedCategorySubscription.next(category);
    }


    /**
     * FILTER PARTNERS BY CATEGORY AND CITY
     */

    filterPartnersByCategoryCity(categories: string[], cities: string[]): Observable<ContentPartner[]> {
        return this.contentApiService.filterProductsByCategoryAndCity(this.appService.currentLanguage, env.campaignUuid, categories, cities).pipe(
            map((data) => {
                return data;
            })
        );
    }

    /**
     * LOAD ALL CONTENT PARTNERS BY CAMPAIGN
     */

    loadAllProducts(): Observable<ContentPartner[]> {
        return this.contentApiService.getAllContentPartners(this.appService.currentLanguage, env.campaignUuid).pipe(
            map((data) => {
                return data;
            }),
            tap((data) => this.allProductsLoaded.dispatch(data))
        );
    }

    loadCalendar(locationUuid: string, startDate: DateTime, endDate: DateTime): Observable<CalendarItem[]> {
        return this.contentApiService.getCalendar(
            this.appService.currentLanguage,
            environment.campaignUuid,
            locationUuid,
            startDate.toISODate()!,
            endDate.toISODate()!
        ).pipe(
            map((calendarItems) => {
                return calendarItems.map((calendarItem) => {
                    return new CalendarItem(
                        new Date(calendarItem.Date),
                        calendarItem.DiscountAmount,
                        calendarItem.DiscountPercentage,
                        calendarItem.EffectivePrice,
                        calendarItem.ListPrice
                    );
                });
            })
        );
    }
}