import {CheckoutProvider} from "../../../providers/checkout.provider";
import {Injectable} from "@angular/core";
import {of} from "rxjs";
import {SubscriberContract} from "../../contracts/subscriber.contract";

@Injectable()
export class ResetCheckoutSubscriber implements SubscriberContract<boolean> {
    constructor(private checkoutProvider: CheckoutProvider) {}

    async handle(): Promise<boolean> {
        this.checkoutProvider.resetCheckout();
        return of(true).toPromise()
    }
}