<div *ngIf="errors.length" class="error-popup-overlay">
    <div class="error-popup-container col-11">
        <div class="container-fluid">
            <div class="row" *ngFor="let error of errors">
                <div class="col-1">
                    <i class="material-symbols-outlined" *ngIf="popupType == popupsEnum.ERROR" style="color: red">error</i>
                    <i class="material-symbols-outlined" *ngIf="popupType == popupsEnum.WARNING" style="color: darkorange">warning</i>
                </div>
                <div class="col-8 container ml-2">
                    <div class="error-popup-title"><div>{{'POPUPS.error_title' | translate}}</div></div>
                    <div class="error-popup-description">{{error}}</div>
                </div>
                <button class="col-1 btn" (click)="closeOverlay()">
                    <i class="material-symbols-outlined" style="color: #1c1f23; margin: 1rem;">close</i>
                </button>
            </div>
        </div>
    </div>
</div>
