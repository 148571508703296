export class PlusWalletVoucher {
    constructor(
        private _coin: string,
        private _plusRewardVoucher: string,
        private _value: number,
        private _isShareable: boolean,
        private _isUsed: boolean,
        private _isShared: boolean,
    ) {}

    coin(): string {
        return this._coin;
    }

    plusRewardVoucher(): string {
        return this._plusRewardVoucher;
    }

    value(): number {
        return this._value;
    }

    isShareable(): boolean {
        return this._isShareable;
    }

    isUsed(): boolean {
        return this._isUsed;
    }

    isShared(): boolean {
        return this._isShared;
    }

    isUsable(): boolean {
        return !this.isUsed();
    }
}