<div class="container mt-4">
    <div class="row">
        <div class="col-4 pl-0" *ngIf="!isMobile">
            <div class="lost-ticket-container first">
                <img src="assets/images/Lost_Tickets.png" alt="lost-tickets-image"/>
            </div>
        </div>
        <div class="{{isMobile ? 'col-12' : 'col-8'}} pr-0">
            <div class="lost-ticket-container">
                <div *ngIf="isSent">
                    <div *ngIf="isSuccessful">
                        <div class="row">
                            <div class="col-12 pr-2 lost_tickets_description_text">
                                <h2 class="email_sent_text">{{'LOST_YOUR_TICKETS.sent'| translate}}</h2>
                            </div>
                        </div>
                        <div  class="row">
                            <p class="col-12 p-2 email_sent_description">
                                {{'LOST_YOUR_TICKETS.email_sent_note' | translate}}
                            </p>
                        </div>
                    </div>
                    <div *ngIf="!isSuccessful">
                        <div class="row">
                            <div class="col-12 pr-2 lost_tickets_description_text">
                                <h2 class="email_sent_text">{{'LOST_YOUR_TICKETS.failed_title'| translate}}</h2>
                            </div>
                        </div>
                        <div class="row">
                            <p class="col-12 p-2 email_sent_description">
                                {{'LOST_YOUR_TICKETS.order_not_found' | translate}}
                            </p>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <button  class="btn btn-lg send-ticket-button" type="button" (click)="clearMessage()">{{'LOST_YOUR_TICKETS.try_again' | translate}}</button>
                    </div>
                </div>
                <form style="display: contents;" [formGroup]="form" *ngIf="!isSent" (ngSubmit)="submit()">
                    <div class="row">
                        <div class="col-12">
                            <h2>{{'LOST_YOUR_TICKETS.title' | translate}}</h2>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <p>
                            {{'LOST_YOUR_TICKETS.subtitle' | translate}}
                        </p>
                    </div>
                    <div class="row">
                        <label>{{'LOST_YOUR_TICKETS.email_address' | translate}}</label>
                    </div>
                    <div class="row">
                        <input name="email" formControlName="email" type="email"/>
                    </div>
                    <div class="row mt-2">
                        <label>{{'LOST_YOUR_TICKETS.order_number' | translate}}</label>
                    </div>
                    <div class="row">
                        <input name="order-reference" formControlName="orderNumber" type="text"/>
                    </div>
                    <div class="row mt-2">
                        <button  class="btn btn-lg send-ticket-button" type="submit" [disabled]="!form.valid">{{'LOST_YOUR_TICKETS.send_again' | translate}}</button>
                    </div>
                </form>
            </div>
        </div>
     </div>
</div>