import { CusSelectModule } from '../components/shared/cus-select/cus-select.module';
import {Injectable, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttractionsRoutingModule } from './attractions-routing.module';
import { MainComponent } from '../components/attractions/main/main.component';
import { AttractionCardModule } from '../components/shared/attraction-card/attraction-card.module';
import { PaginatorModule } from '../components/shared/paginator/paginator.module';
import { AttractionComponent } from '../components/attraction/attraction.component';
import { HorizontalSliderModule } from '../components/shared/horizontal-slider/horizontal-slider.module';
import { AttractionsToolbarComponent } from './attractions-toolbar/attractions-toolbar.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CusAutocompleteModule } from '../components/shared/cus-autocomplete/cus-autocomplete.module';
import { AttractionLocationPipe } from './pipes/attraction-location.pipe';
import { ScrollingModule } from "@angular/cdk/scrolling";
import { SharedModule } from '../components/shared/shared.module';
import { TicketSelectComponent } from '../components/checkout/tickets/ticket-select.component';
import {OrderOverviewComponent} from "../components/checkout/order-overview/order-overview.component";
import {TimeslotsComponent} from "../components/checkout/timeslots/timeslots.component";
import {VisitGuaranteeComponent} from "../components/checkout/visit-guarantee/visit-guarantee.component";
import {NgxSpinnerModule} from "ngx-spinner";
import {AttractionDetailComponent} from "../components/attraction/detail/attraction-detail.component";
import {AttractionDescriptionHeaderComponent} from "../components/attraction/description-header/attraction-description-header.component";
import {SelectLocationComponent} from "../components/checkout/select-location/select-location.component";
import {BannerComponent} from "../components/attraction/banner/banner.component";
import {GoogleMapsModule} from "@angular/google-maps";
import {LoyaltyModule} from "../modules/loyalty/loyalty.module";
import {NoDiscountComponent} from "../components/checkout/discount/no-discount.component";
import {environment} from "../../environments/environment";
import {VoucherModule} from "../modules/vouchers/voucher.module";
import {PlusModule} from "../modules/plus/plus.module";
import {CalendarComponent} from "../components/checkout/calendar-v2/calendar.component";
import {HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule} from "@angular/platform-browser";
import {NgImageSliderModule} from "ng-image-slider";
import {SeeMapComponent} from "../components/see-map/see-map.component";


@Injectable()
export class HammerConfig extends HammerGestureConfig {
    overrides = {
        pinch: { enable: false },
        rotate: { enable: false }
    };
}

@NgModule({
  declarations: [
    MainComponent,
    AttractionComponent,
    AttractionsToolbarComponent,
    AttractionLocationPipe,
    OrderOverviewComponent,
    TicketSelectComponent,
    TimeslotsComponent,
    VisitGuaranteeComponent,
    AttractionDetailComponent,
    AttractionDescriptionHeaderComponent,
    SelectLocationComponent,
    BannerComponent,
    CalendarComponent,
    SeeMapComponent,
    !environment.modules.loyalty && !environment.modules.vouchers && !environment.modules.plus ? NoDiscountComponent : []
  ],
    imports: [
        CommonModule,
        SharedModule,
        AttractionsRoutingModule,
        AttractionCardModule,
        PaginatorModule,
        HorizontalSliderModule,
        NgSelectModule,
        CusAutocompleteModule,
        ScrollingModule,
        HammerModule,
        CusSelectModule,
        NgxSpinnerModule,
        GoogleMapsModule,
        environment.modules.loyalty ? LoyaltyModule : [],
        environment.modules.vouchers ? VoucherModule : [],
        environment.modules.plus ? PlusModule : [],
        NgImageSliderModule
    ],
  providers: [
  { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig }
  ],
    exports: [
        SharedModule,
        TimeslotsComponent,
        OrderOverviewComponent,
        BannerComponent
    ]
})
export class AttractionsModule {}

