import {Injectable} from "@angular/core";
import {StandardEvent} from "../standard.event";
import {ReplaySubject} from "rxjs";
import {BillingDetails} from "../../../models/checkout/billing-details.model";
import {Reservation} from "../../../models/checkout/reservation.model";

@Injectable()
export class BillingDetailsSubmitEvent extends StandardEvent<[BillingDetails, Reservation]> {
    constructor() {
        super(new ReplaySubject<[BillingDetails, Reservation]>());
    }

    dispatch(billingDetails: [BillingDetails, Reservation]): void {
        this.subject.next(billingDetails);
    }
}