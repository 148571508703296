import {
  AfterContentInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Renderer2,
} from '@angular/core';
/**
 * For this directive to work with sprites you have to follow this structure
 * <div>
 *  <div class="custom-arrow-wrapper">
 *    <svg xmlns="http://www.w3.org/2000/svg" role="img" focusable="img"><use xlink:href="somepath#icon-name"></use></svg>
 *  </div>
 *  <ng-select class="qup-ng-select" qupCustomNgSelect></ng-select>
 * </div>
 */

@Directive({
  selector: '[qupCustomNgSelect]',
})
export class CustomNgSelectFilterDirective implements AfterContentInit {
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private cDR: ChangeDetectorRef
  ) { }

  ngAfterContentInit(): void {
    const arrowEl = this.el.nativeElement.querySelector('.ng-arrow-wrapper');

    if (arrowEl) {
      const arrowSvgEl = this.el.nativeElement.parentElement.querySelector(
        '.custom-arrow-wrapper'
      );
      this.renderer.appendChild(arrowEl, arrowSvgEl);
      this.cDR.detectChanges();
    }
  }
}
