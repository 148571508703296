import {ReservationProduct} from "./reservation-product.model";
import {DateTime} from "luxon";

export class Reservation {
    constructor(
        private _orderReservationUuid: string,
        private _contentPartnerUuid: string,
        private _locationUuid: string,
        private _grandTotal: number,
        private _discountTotal: number,
        private _bookingCosts: number,
        private _visitGuaranteeCosts: number,
        private _maxApplicableVouchers: number,
        private _reservationProducts: ReservationProduct[],
        private _voucherSessionUuid: string,
        private _visitDate?: Date,
        private _usedVouchers: string[] = [],
        private _loyaltyReservationUuid?: string,
        private _timeslotUuid?: string,
        private _timeslotTime?: string
    ) {
    }

    orderReservationUuid(): string {
        return this._orderReservationUuid;
    }

    visitDate(): Date {
        if (this._visitDate == undefined) {
            return new Date();
        }

        return this._visitDate;
    }

    visitDateAsPrettyString(locale?: string|null): string {
        return DateTime.fromJSDate(this.visitDate()).toLocaleString({ month: 'long', day: 'numeric'}, {
            locale: locale?? 'nl'
        });
    }

    visitDateAsString(): string {
        return DateTime.fromJSDate(this.visitDate()).toISODate()!;
    }

    isNoDate(): boolean {
        return this._visitDate == undefined;
    }

    grandTotal(): number {
        return this._grandTotal;
    }

    discountTotal(): number {
        return this._discountTotal;
    }

    bookingCosts(): number {
        return this._bookingCosts;
    }

    visitGuaranteeCosts(): number {
        return this._visitGuaranteeCosts;
    }

    maxApplicableVouchers(): number {
        return this._maxApplicableVouchers;
    }

    reservationProducts(): ReservationProduct[] {
        return this._reservationProducts;
    }

    reservationProductsAsMap(): Map<string, ReservationProduct> {
        const reservationProducts = new Map<string, ReservationProduct>;
        this._reservationProducts.forEach((reservationProduct) => {
            reservationProducts.set(reservationProduct.unique(), reservationProduct);
        });

        return reservationProducts;
    }

    aggregatedReservationProducts(): ReservationProduct[] {
        let productMap = new Map<string, ReservationProduct>();
        this._reservationProducts.forEach((product) => {

            let currentProduct = productMap.get(product.productUuid());
            if (currentProduct) {
                return currentProduct.addQuantity(product.quantity() ?? 0);
            }

            productMap.set(product.productUuid(), new ReservationProduct(
                product.productUuid(),
                product.quantity() ?? 0,
                product.price(),
                product.productName(),
                product.sku(),
                product.productType(),
                product.discount(),
                product.productDate(),
                product.locationName(),
                product.contentPartnerName(),
                product.contentPartnerUuid()
            ));
        });

        return Array.from(productMap.values());
    }

    voucherSessionUuid(): string {
        return this._voucherSessionUuid;
    }

    hasVisitGuarantee(): boolean {
        return this._visitGuaranteeCosts > 0;
    }

    contentPartnerUuid(): string {
        return this._contentPartnerUuid;
    }

    locationUuid(): string {
        return this._locationUuid;
    }

    usedVouchers(): string[] {
        return this._usedVouchers;
    }

    loyaltyReservationUuid(): string|undefined {
        return this._loyaltyReservationUuid;
    }

    timeslotUuid(): string|undefined {
        return this._timeslotUuid;
    }

    timeslotTime(): string|undefined {
        return this._timeslotTime;
    }

    productsInCartAmount(): number {
        let quantity = 0;
        this.reservationProducts().forEach((reservationProduct) => {
            quantity += reservationProduct.quantity();
        });

        return quantity;
    }
}