export class Coordinates {
    constructor(
        private _lat: number,
        private _long: number,
    ) {
    }

    lat(): number {
        return this._lat;
    }

    lon(): number {
        return this._long;
    }


}