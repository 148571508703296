import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/services/app.service';
import { DialogService } from '../dialog/services/dialog.service';
import { CookiesComponent } from '../dialogs/cookies/cookies.component';

// The idea here is when someone wants to read the more detailed
// cookie statement he is navigated to this component

// When the dialog cookie manager is open the overlay
// prevents user clicks on all components except this one

// When navigating away from this component, cookie state is checked again
// and if the data is not set, the dialog is called again,
// preventing user clicks on other components

@Component({
  selector: 'qup-cookie-statement',
  templateUrl: './cookie-statement.component.html',
  styleUrls: ['./cookie-statement.component.scss'],
})
export class CookieStatementComponent implements OnDestroy {
  showPrefClicked = false;
  constructor(private appS: AppService, private dialog: DialogService, private router: Router) {}

  showPref(): void {
    this.showPrefClicked = true;
    this.dialog.open(CookiesComponent, {
      overlayClass: 'cookie-dialog-overlay',
      data: { showPref: true }
    });

    this.router.navigate(['/']);
  }

  ngOnDestroy(): void {
    if (!this.showPrefClicked && !this.appS.cookiePreferences) {
      this.dialog.open(CookiesComponent, {
        overlayClass: 'cookie-dialog-overlay',
      });
    }
  }
}
