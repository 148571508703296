import {AfterViewInit, OnInit, Component, ViewChild, ViewContainerRef} from '@angular/core';
import {EventRegistry} from "../../dispatch/events.registry";
import {AnalyticsEventRegistry} from "../../modules/ga4/analytics-event.registry";
import {ActivatedRoute} from "@angular/router";
import {AnalyticsProvider} from "../../modules/ga4/analytics-provider";
import {LostTicketsService} from "../../services/lost-tickets.service";
import {LostTicketsEventRegistry} from "../../modules/lost-tickets/lost-tickets-event.registry";


export let DYNAMIC_LAYOUT_COMPONENT: ViewContainerRef;

@Component({
  selector: 'qup-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements AfterViewInit, OnInit {

  public embedded = false;

  @ViewChild('dynamicLayoutComponent', {read: ViewContainerRef}) dynamicLayoutComponent!: ViewContainerRef;

  constructor(
      private eventRegistry: EventRegistry,
      private analyticsRegistry: AnalyticsEventRegistry,
      private analyticsProvider: AnalyticsProvider,
      private lostTicketEventRegistry: LostTicketsEventRegistry,
      private activatedRoute: ActivatedRoute
  ) {

  }

  ngAfterViewInit(): void {
    DYNAMIC_LAYOUT_COMPONENT = this.dynamicLayoutComponent;

    this.analyticsProvider.initializeConsent();
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      if (queryParams.embedded) {
        this.embedded = true;
      }
    });
  }
}
