export class Banner {
    constructor(
        private _uuid: string,
        private _desktopBannerUuid: string,
        private _mobileBannerUuid: string,
        private _website: string,
        private _sortIndex: string,
    ) {}

    uuid(): string {
        return this._uuid;
    }

    desktopBannerUuid(): string {
        return this._desktopBannerUuid;
    }

    mobileBannerUuid(): string {
        return this._mobileBannerUuid;
    }

    website(): string {
        return this._website;
    }

    sortIndex(): string {
        return this._sortIndex;
    }

}