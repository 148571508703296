import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from '../components/attractions/main/main.component';
import { AttractionComponent } from '../components/attraction/attraction.component';
import {LoyaltyResolver} from "../resolvers/loyalty.resolver";
import {environment} from "../../environments/environment";
import {PlusResolver} from "../resolvers/plus.resolver";
import {SeeMapComponent} from "../components/see-map/see-map.component";

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    data: {
      showHeaderSearch: true
    }
  },
  {
    path: 'map',
    component: SeeMapComponent,
  },
  environment.modules.plus ?
  {
    path: ':attractionId',
    component: AttractionComponent,
    resolve: {
      plus: PlusResolver
    }
  } : {
        path: ':attractionId',
        component: AttractionComponent,
        children: [
          {
            path: 'locatie', children: [
              {
                path: ':locationId', component: AttractionComponent
              }
            ]
          }
        ]
      },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AttractionsRoutingModule { }
