import {Injectable} from "@angular/core";
import {SubscriberContract} from "../../contracts/subscriber.contract";
import {ContentApiService} from "../../../services/content-api.service";
import {Location} from "../../../models/content-partner/location.model";
import {environment as env} from "../../../../environments/environment";
import {AppService} from "../../../services/app.service";
import {Category} from "../../../models/home/category.model";
import {tap} from "rxjs/operators";
import {AttractionProvider} from "../../../providers/attraction.provider";


@Injectable()
export class LoadHomeCategoriesSubscriber implements SubscriberContract<Category[]> {
    constructor(
        private contentService: ContentApiService,
        private appService: AppService,
        private attractionMainProvider: AttractionProvider
    ) {
    }

    async handle(location: Location): Promise<Category[]> {

        return this.contentService.getCategories(
            env.campaignUuid,
            this.appService.currentLanguage
        ).pipe(
            tap((categories) => {
                this.attractionMainProvider.categories().next(categories);
            })
        ).toPromise();
    }
}