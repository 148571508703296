<div *ngIf="_contentPartner"  class="col-12 mt-2 pt-4 attraction_details">
    <div class="row" >
        <div class="col-12">
            <h3 *ngIf="!isMobile">{{location?.caption()}}</h3>
            <h4>{{_contentPartner.shortDescription()}}</h4>
        </div>
    </div>
    <div class="row">
        <div class="col-12 attraction-specific-description-card">
            <div class="row mb-2">
                <div class="col-12" >
                    <h5>{{'ATTRACTION_SPECIFIC.description' | translate}}</h5>
                    <p [innerHTML]='location?.description()'></p>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-12" >
                    <h5>{{'ATTRACTION_SPECIFIC.ticket_information' | translate}}</h5>
                    <p [innerHtml]="location?.ticketInfo()"></p>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-12" >
                    <h5>{{'ATTRACTION_SPECIFIC.working_hours' | translate}}</h5>
                    <p [innerHtml]="location?.openingInfo()"></p>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="!isMobile">
        <div class="col-12 logo">
            <img src="{{_contentPartner.logo()}}"/>
        </div>
    </div>
</div>