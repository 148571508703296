import {Injectable} from "@angular/core";
import {ReplaySubject} from "rxjs";
import {StandardEvent} from "../standard.event";
import {CheckoutContext} from "../../../models/checkout/checkout-context.model";

@Injectable()
export class CheckoutEnteredEvent extends StandardEvent<CheckoutContext> {
    constructor() {
        super(new ReplaySubject<CheckoutContext>());
    }

    dispatch(context: CheckoutContext): void {
        this.subject.next(context);
    }
}