import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Route, Router} from "@angular/router";
import Auth from "@aws-amplify/auth";
import {catchError, tap} from "rxjs/operators";
import {UiService} from "../../../../services/ui.service";
import {Subscription} from "rxjs";
import {environment} from "../../../../../environments/environment";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CognitoService} from "../../../../services/cognito.service";

@Component({
    selector: 'qup-plus-login',
    templateUrl: '../../templates/login/login.component.html',
    styleUrls: ['../../templates/login/login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

    isMobile = false;
    _subs: Subscription[] = [];

    constructor(
        private uiService: UiService,
        private changeDetectorRef: ChangeDetectorRef,
        private formBuilder: FormBuilder,
        private cognitoProvider: CognitoService,
        private router: Router
    )
    {
    }

    form: FormGroup = this.formBuilder.group({
        password: [null, [Validators.required]],
        email: [null, [Validators.required, Validators.email]],
    });

    ngOnDestroy(): void {
        this._subs.forEach((sub) => sub.unsubscribe());
    }

    ngOnInit(): void {
        this._subs.push(
            this.uiService.isMobileOverlay$
                .pipe(
                    tap((isMobile) => {
                        this.isMobile = isMobile;
                        this.changeDetectorRef.detectChanges();
                    })
                ).subscribe(),
        );
    }

    async onLoginRedirect(): Promise<void> {
        await Auth.federatedSignIn({customProvider : environment.cognitoProvider});
    }

    submitLogin(): void {
        const email = this.form.get('email')?.value;
        const password = this.form.get('password')?.value;

        this.cognitoProvider.startNativeLogin(email, password).pipe(
            catchError((err) => {
                throw err;
            })
        ).subscribe((test) => {
            this.router.navigate(['account/home']);
        });
    }

    forgotPassword(): void {
        this.router.navigate(['account/forgot-password']);
    }

    protected readonly environment = environment;
}