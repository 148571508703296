import {AfterViewInit, Component, Input, ViewChild} from "@angular/core";
import {Location} from "../../../models/content-partner/location.model";
import {Image} from "../../../models/content-partner/image.model";
import {ContentPartner} from "../../../models/content-partner/content-partner.model";
import {ImageObject} from "../../../interfaces/ng-slider.interface";
import {NgImageSliderComponent} from "ng-image-slider";

@Component({
    selector: 'qup-attraction-banner',
    templateUrl: '../../../templates/attraction/banner/banner.component.html',
    styleUrls: ['../../../templates/attraction/banner/banner.component.scss'],
})
export class BannerComponent implements AfterViewInit {

    @ViewChild('imageSlider') slider!: NgImageSliderComponent;
    @Input() contentPartner: ContentPartner | null = null;
    @Input() isMobile = true;
    @Input() embedded = false;
    _location!: Location | null;
    images: ImageObject[] = [];
    imageSize = {
        width: '730',
        height: '450px'
    };
    public selectedImage!: Image;
    private swipeCoord?: [number, number];
    private swipeTime?: number;

    @Input() set location(d: Location | null) {
        if (d === null) {
            if (this.location?.images()) {
                this.images = this.location.images().map((image) => {
                    return {
                        image: image.fullImage(),
                        thumbImage: image.thumbnail(),
                        alt: image.caption()
                    } as ImageObject;
                });
                // this.images = this.location?.images();
            }
        } else {
            this.images = d.images().map((image) => {
                return {
                    image: image.fullImage(),
                    thumbImage: image.thumbnail(),
                    alt: image.caption()
                } as ImageObject;
            });
        }
        this._location = d;
    }

    ngAfterViewInit(): void {
    }
    //
    //
    // set images(d: Image[]) {
    //     this._images = d;
    //     this.selectedImage = this.location?.images()[0] || d[0];
    // }
    //
    // nextImage(): void {
    //     this.selectedImage = this._images[
    //         this._images.indexOf(this.selectedImage) ===
    //         this._images.length - 1
    //             ? 0
    //             : this._images.indexOf(this.selectedImage) + 1
    //         ];
    // }
    //
    // previousImage(): void {
    //     this.selectedImage = this._images[
    //         this._images.indexOf(this.selectedImage) === 0
    //             ? this._images.length - 1
    //             : this._images.indexOf(this.selectedImage) - 1
    //         ];
    // }
    //
    // swipeImages(e: TouchEvent, when: string): void {
    //     const coordinates: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
    //     const time = new Date().getTime();
    //
    //     if (when === 'start') {
    //         this.swipeCoord = coordinates;
    //         this.swipeTime = time;
    //     } else if (when === 'end') {
    //
    //         if (this.swipeCoord && this.swipeTime) {
    //             const direction = [coordinates[0] - this.swipeCoord[0], coordinates[1] - this.swipeCoord[1]];
    //             const duration = time - this.swipeTime;
    //
    //             if (duration < 1000 && Math.abs(direction[0]) > 30 && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) {
    //                 if (direction [0] < 0) {
    //                     this.nextImage();
    //                 } else if (direction[0] > 0) {
    //                     this.previousImage();
    //                 }
    //             }
    //         }
    //     }
    // }

}