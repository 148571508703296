import { AppService } from './app.service';
import { Injectable } from '@angular/core';
import { environment as env } from "../../environments/environment";
import {LocaleEnum} from "../../environments/enums/locale.enum";

const ASSETS_URL = "https://assets.queueup.eu";

const URL = {
  ASSET_DOC_PDF: (name: string, campaignUuid: string, locale: LocaleEnum) => {
    return `/assets/documents/${name}_${locale}.pdf`;
  }
}

@Injectable({
  providedIn: 'root',
})
export class DynamicLinkService {
  constructor(private appS: AppService) {}

  footerDoc(name: string): string {
    return URL.ASSET_DOC_PDF(name, env.campaignUuid, this.appS.currentLanguage);
  }

  get campaignDoc(): string {
    return this.footerDoc('promotional_conditions');
  }

  get generalDoc(): string {
    return this.footerDoc('terms_and_conditions');
  }

  get privacyDoc(): string {
    return this.footerDoc('privacy_statement');
  }

  get retourDoc(): string {
    return this.footerDoc('return_policy');
  }

  get complaintDoc(): string {
    return this.footerDoc('complaint_policy');
  }

  get cookiesDoc(): string {
    return this.footerDoc('cookie_statement');
  }

  get faqDoc(): string {
    return this.footerDoc('faq');
  }
}
