import {Injectable} from '@angular/core';
import {environment as env} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {
    AddVoucherRequest,
    AddVouchersResponse,
    GetCalendarResponse,
    GetCampaignResponse, GetCategoriesResponse,
    GetContentPartnersResponse, SubProduct
} from "../interfaces/content-api.interface";
import {Product} from "../models/content-partner/product.model";
import {map, retry, tap} from "rxjs/operators";
import {Location} from "../models/content-partner/location.model";
import {ContentPartner, DiscountStickerType} from "../models/content-partner/content-partner.model";
import {Image} from "../models/content-partner/image.model";
import {Category} from "../models/home/category.model";
import {Campaign} from "../models/home/campaign.model";
import {Banner} from "../models/home/banner.model";
import {Coordinates} from "../models/content-partner/coordinates.model";
import {AddVoucher} from "../models/checkout/add-voucher.model";
import {LocaleEnum} from "../../environments/enums/locale.enum";
import {AllProductsLoadedEvent} from "../dispatch/events/attractions-main/all-products-loaded.event";
import {DateTime} from "luxon";


const URLS = {
    getCampaignEndpoint: () => `${env.contentApiUri}/campaigns`,
    getCategoriesEndpoint: () => `${env.contentApiUri}/categories`,
    getContentPartnersEndpoint: () => `${env.contentApiUri}/content-partners`,
    getSubProductsEndpoint: () => `${env.contentApiUri}/products`,
    getCalendarEndpoint: () => `${env.contentApiUri}/products/calendar`,
    searchProductsEndpoint: () => `${env.contentApiUri}/content-partners/search`,
    filterProductsByCategoryCityEndpoint: () => `${env.contentApiUri}/content-partners/filters`,
    addVouchersEndpoint: () => `${env.contentApiUri}/session/vouchers`,
    resendProductsEndpoint: () => `${env.contentApiUri}/session/order/resend`,
};

@Injectable({
    providedIn: 'root'
})

export class ContentApiService {

    constructor(
        private http: HttpClient,
        private allProductsLoadedEvent: AllProductsLoadedEvent
    ) {
    }

    /**
     * Get campaign
     */

    mapCampaign(response: GetCampaignResponse): Campaign {

        let banners: Banner[] = [];

        response.BannersDynamo.forEach((banner) => {
            banners.push(new Banner(
                banner.Uuid,
                banner.DesktopBannerUuid,
                banner.MobileBannerUuid,
                banner.Website,
                banner.SortIndex
            ));
        });

        return new Campaign(
            response.CampaignUuid,
            response.CampaignTitle,
            response.CampaignKey,
            response.Website,
            response.RedeemPeriod,
            banners
        );
    }

    getCampaign(campaignUuid: string, locale: string): Observable<Campaign> {
        return this.http.get<GetCampaignResponse>(URLS.getCampaignEndpoint(), {
            params: {
                locale: locale,
                campaign: campaignUuid
            }
        }).pipe(
            retry(2),
            map((data) => {
                return this.mapCampaign(data);
            })
        );
    }


    /**
     * Get categories
     */

    mapCategory(response: GetCategoriesResponse): Category {

        let contentPartners: ContentPartner[] = [];

        response.Products.forEach((partner) => {
            contentPartners.push(this.mapContentPartner(partner));
        });

        return new Category(
            response.CategoryAlias,
            response.SortIndex,
            response.Caption,
            response.CategoryUuid,
            response.IconUuid,
            contentPartners
        );
    }

    getCategories(campaignUuid: string, locale: string): Observable<Category[]> {
        return this.http.get<GetCategoriesResponse[]>(URLS.getCategoriesEndpoint(), {
            params: {
                campaign: campaignUuid,
                locale: locale,
            }
        }).pipe(
            retry(2),
            map((data) => {
                let categories: Category[] = [];
                data.forEach((category) => categories.push(this.mapCategory(category)));
                return categories;
            }),
            tap((categories) => {
                let contentPartners: ContentPartner[] = [];
                categories.forEach((category) => {
                    contentPartners.push(...category.products());
                });
                this.allProductsLoadedEvent.dispatch(contentPartners);
            })
        );
    }


    /**
     * Get content partners
     */

    mapContentPartner(response: GetContentPartnersResponse): ContentPartner {
        const cp = response;

        let locations: Location[] = [];
        let locationImages: Image[] = [];

        cp.Locations?.forEach((location) => {

            location.Images.forEach((image) => {
                locationImages.push(new Image(
                    image.Caption,
                    image.Url,
                ));
            });

            let coordinates = new Coordinates(
                location.Coordinates.Lat,
                location.Coordinates.Lon
            );

            locations.push(new Location(
                location.LocationUuid,
                location.Caption,
                cp.Caption,
                cp.ContentPartnerUuid,
                cp.EffectivePrice,
                location.Alias,
                location.City,
                location.Description,
                location.ShortDescription,
                location.OpeningInfo,
                location.TicketInfo,
                location.Website,
                location.SortIndex,
                location.HasVisitGuarantee,
                location.ShowCalendar,
                location.DiscountStickerType as DiscountStickerType,
                locationImages,
                coordinates,
                location.DisplayOnMap
            ));
        });

        return new ContentPartner(
            cp.ContentPartnerUuid,
            cp.Alias,
            cp.Caption,
            locations,
            cp.ShortDescription,
            cp.HeaderContent,
            cp.SortIndex,
            cp.MaxDiscountPercentage,
            cp.MaxDiscountAmount,
            cp.ListPrice,
            cp.EffectivePrice,
            cp.DiscountStickerType as DiscountStickerType,
            cp.Thumbnail,
            cp.Banner,
            cp.LogoUuid,
            cp.Website,
            cp.Cities,
            cp.ShowCalendar,
            cp.VisitGuarantee,
            cp.SuperDeal
        );
    }


    getContentPartner(locale: string, campaignUuid: string, alias?: string, contentPartnerUuid?: string): Observable<ContentPartner> {
        return this.http.get<GetContentPartnersResponse[]>(URLS.getContentPartnersEndpoint(), {
            params: {
                locale: locale,
                campaign: campaignUuid,
                contentpartneruuid: contentPartnerUuid == undefined ? '' : contentPartnerUuid,
                contentpartneralias: alias == undefined ? '' : alias,
            }
        }).pipe(
            map((data) => {
                return this.mapContentPartner(data[0]);
            })
        );
    }


    getAllContentPartners(locale: string, campaignUuid: string): Observable<ContentPartner[]> {
        let contentPartners: ContentPartner[] = [];

        return this.http.get<GetContentPartnersResponse[]>(URLS.getContentPartnersEndpoint(), {
            params: {locale: locale, campaign: campaignUuid, contentpartneruuid: '', contentpartneralias: ''}
        }).pipe(
            map((data) => {
                data.forEach((partner) => {
                    contentPartners.push(this.mapContentPartner(partner));
                });
                return contentPartners;
            })
        );
    }

    /**
     * Get sub products by content-partner
     */

    getSubProductsByLocation(locale: string, campaignUuid: string, location: Location, selectedDay: Date, contentPartnerUuid: string): Observable<Product[]> {

        return this.http.post<SubProduct[]|null>(URLS.getSubProductsEndpoint(), JSON.stringify({
            Language: locale.substring(0,2),
        }), {
            params: {
                locale: locale,
                campaign: campaignUuid,
                location: location.uuid(),
                day: DateTime.fromJSDate(selectedDay).toISODate()!,
            }
        }).pipe(
            map((response) => {

                if (response == null) {
                    return [];
                }

                let products: Product[] = [];
                response.forEach((subProduct) => {

                    if (subProduct.AvailableStock <= 0) {
                        return;
                    }

                    products.push(new Product(
                        subProduct.Uuid,
                        subProduct.ListPrice,
                        subProduct.BasePrice,
                        subProduct.ProductName,
                        contentPartnerUuid,
                        location,
                        'TICKET',
                        subProduct.AvailableStock,
                        subProduct.MaxDiscount,
                        subProduct.FixedPointCost,
                        subProduct.DiscountStickerType,
                        !selectedDay ? new Date() : new Date(selectedDay),
                        false,
                        subProduct.Conditions
                    ));
                });

                return products;
            })
        );
    }

    /**
     * Search products
     */

    searchProducts(locale: LocaleEnum, campaignUuid: string, search: string): Observable<ContentPartner[]> {
        return this.http.post<GetContentPartnersResponse[]>(URLS.searchProductsEndpoint(), JSON.stringify({
            SearchTerm: search,
        }), {
            params: {
                locale: locale,
                campaign: campaignUuid
            }
        }).pipe(
            map((data) => {
                let contentPartners: ContentPartner[] = [];
                data.forEach((partner) => contentPartners.push(this.mapContentPartner(partner)));
                return contentPartners;
            })
        );
    }

    /**
     * Filter products by category & city
     */

    filterProductsByCategoryAndCity(locale: LocaleEnum, campaignUuid: string, categories: string[], cities: string[]): Observable<ContentPartner[]> {
        return this.http.post<GetContentPartnersResponse[]>(URLS.filterProductsByCategoryCityEndpoint(), JSON.stringify({
            Categories: categories,
            Cities: cities,
        }), {
            params: {
                locale: locale,
                campaign: campaignUuid
            }
        }).pipe(
            map((data) => {
                let contentPartners: ContentPartner[] = [];
                data.forEach((partner) => contentPartners.push(this.mapContentPartner(partner)));
                return contentPartners;
            })
        );
    }


    /**
     * Get calendar
     */

    getCalendar(locale: string, campaignUuid: string, locationUuid: string, startDate: string, endDate: string): Observable<GetCalendarResponse[]> {
        return this.http.post<GetCalendarResponse[]>(URLS.getCalendarEndpoint(), JSON.stringify({
            StartDate: startDate,
            EndDate: endDate
        }), {
            params: {
                locale: locale,
                location: locationUuid,
                campaign: campaignUuid
            }
        });
    }

    /**
     * Add vouchers
     */


    addVouchers(voucherRequest: AddVoucherRequest): Observable<AddVoucher> {
        return this.http.post<AddVouchersResponse>(URLS.addVouchersEndpoint(), voucherRequest).pipe(
            map((data) => new AddVoucher(data.Uuid))
        );
    }


    /**
     * Resend products
     */

    resendProducts(orderNumber: string, email: string): Observable<any> {
        return this.http.post<any>(URLS.resendProductsEndpoint(), JSON.stringify({
            OrderNumber: orderNumber,
            Email: email
        }));
    }
}
