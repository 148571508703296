import { CookiePreferences } from '../interfaces/cookies.interface';
import { Injectable } from '@angular/core';
import {AuthValidation, LogoutAuthValidation, UserLoginResponse} from "../interfaces/lidl-sso.interface";
import {LocaleEnum} from "../../environments/enums/locale.enum";

export enum StorageNS {
  ATTR_VOUCHER_SESSION = 'qup_attraction_voucher_session',
  SELECTED_LANGUAGE = 'qup_selected_language',
  COOKIE_PREFERENCES = 'cookie_pref',
  LIDL_SSO_VALIDATION = 'qup_lidl_sso_validation',
  LIDL_SSO_AUTH = 'qup_lidl_sso_auth',
  AUTH_LOGOUT = 'qup_lidl_sso_logout',
  REMOVE_BANNER = 'remove_banner',
  VOUCHER_SESSION_UUID = 'voucher_session_uuid',
  PLUS_TOKEN = 'plus_token'
}

// values in milliseconds
export const ExpirationMap: { [key in StorageNS]?: number } = {
  [StorageNS.ATTR_VOUCHER_SESSION]: 1000 * 60 * 60 * 24,
};

@Injectable()
export class StorageService {

  get selectedLanguage(): LocaleEnum | null {
    return this.getItem(StorageNS.SELECTED_LANGUAGE);
  }

  set selectedLanguage(d: LocaleEnum | null) {
    this.setItem(StorageNS.SELECTED_LANGUAGE, d);
  }


  get cookiePreferences(): CookiePreferences | null {
    return this.getItem(StorageNS.COOKIE_PREFERENCES);
  }

  set cookiePreferences(d: CookiePreferences | null) {
    this.setItem(StorageNS.COOKIE_PREFERENCES, d);
  }

  /**
   * Remove data from local storage
   * @param ns Specific namespace to be removed from local storage
   */

  removeItem(ns: StorageNS): void {
    localStorage.removeItem(ns);
  }

  /**
   * Lidl SSO validation data
   */

  get authValidationData(): AuthValidation | null {
    return this.getItem(StorageNS.LIDL_SSO_VALIDATION);
  }

  set authValidationData(d: AuthValidation | null) {
    this.setItem(StorageNS.LIDL_SSO_VALIDATION, d);
  }

  /**
   * Lidl SSO auth data
   */

  get lidlSsoAuth(): UserLoginResponse | null {
    return this.getItem(StorageNS.LIDL_SSO_AUTH);
  }

  set lidlSsoAuth(d: UserLoginResponse | null) {
    this.setItem(StorageNS.LIDL_SSO_AUTH, d);
  }

  /**
   * Lidl SSO logout data
   */

  get authLogout(): LogoutAuthValidation | null {
    return this.getItem(StorageNS.AUTH_LOGOUT);
  }

  set authLogout(d: LogoutAuthValidation | null) {
    this.setItem(StorageNS.AUTH_LOGOUT, d);
  }

  /**
   * Voucher session uuid
   */

  get voucherSessionUuid(): string | null {
    return this.getItem(StorageNS.VOUCHER_SESSION_UUID);
  }

  set voucherSessionUuid(d: string | null) {
    this.setItem(StorageNS.VOUCHER_SESSION_UUID, d);
  }

  /**
   * PLUS token
   */

  get plusToken(): string | null {
    return this.getItem(StorageNS.PLUS_TOKEN);
  }

  set plusToken(token: string | null) {
    this.setItem(StorageNS.PLUS_TOKEN, token);
  }

  /**
   * Set item into local storage
   * @param ns StorageNS -> Define the namespace for storing data
   * @param data If data is falsy, the namespace will be removed from storage
   */

  setItem(ns: StorageNS, data: any): void {
    if (data) {
      localStorage.setItem(ns, JSON.stringify(data));
    } else {
      this.removeItem(ns);
    }
  }

  getItem<T>(ns: StorageNS): T | null {
    const d = localStorage.getItem(ns);

    if (d) {
      return JSON.parse(d);
    }

    return null;
  }

  deleteAll(): void {
    localStorage.clear();
  }
}
