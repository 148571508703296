import {Injectable} from "@angular/core";
import {StandardEvent} from "../standard.event";
import {ReplaySubject} from "rxjs";

@Injectable()
export class RaiseErrorEvent extends StandardEvent<any>{

    constructor(
    ) {
        super(new ReplaySubject<any>())
    }

    dispatch(...errors: string[]):void {
        this.subject.next(errors);
    }

}