import {EventsDispatcher} from "./events.dispatcher";
import {QuantityChangeEvent} from "./events/checkout/quantity-change.event";
import {ReservationSubscriber} from "./subscribers/checkout/reservation.subscriber";
import {ReservationPlacedEvent} from "./events/checkout/reservation-placed.event";
import {TimeslotsSubscriber} from "./subscribers/timeslots/timeslots-subscriber";
import {Injectable} from "@angular/core";
import {ResetCheckoutSubscriber} from "./subscribers/checkout/reset-checkout.subscriber";
import {CalendarDateSelectEvent} from "./events/content-partner/calendar-date-select.event";
import {SelectLocationEvent} from "./events/content-partner/select-location.event";
import {SelectTimeslotEvent} from "./events/timeslots/select-timeslot.event";
import {SelectTimeslotSubscriber} from "./subscribers/timeslots/select-timeslot.subscriber";
import {SelectLocationSubscriber} from "./subscribers/content-partner/select-location.subscriber";
import {CheckCheckoutConditionsSubscriber} from "./subscribers/checkout/check-checkout-conditions.subscriber";
import {CheckoutSubmitEvent} from "./events/checkout/checkout-submit.event";
import {NavigateBillingDetailsSubscriber} from "./subscribers/checkout/navigate-billing-details.subscriber";
import {CheckoutEnteredEvent} from "./events/checkout/checkout-entered.event";
import {LoadContentPartnerSubscriber} from "./subscribers/content-partner/load-content-partner-subscriber";
import {LoadProductsSubscriber} from "./subscribers/content-partner/load-products.subscriber";
import {SetCheckoutContextSubscriber} from "./subscribers/checkout/set-checkout-context.subscriber";
import {BillingDetailsSubmitEvent} from "./events/checkout/billing-details-submit.event";
import {NavigatePaymentSubscriber} from "./subscribers/checkout/navigate-payment.subscriber";
import {ToggleVisitGuaranteeEvent} from "./events/checkout/toggle-visit-guarantee.event";
import {SetVisitGuaranteeSubscriber} from "./subscribers/checkout/set-visit-guarantee.subscriber";
import {LoadNoDateProductsSubscriber} from "./subscribers/content-partner/load-no-date-products.subscriber";
import {SetBillingDetailsSubscriber} from "./subscribers/checkout/set-billing-details.subscriber";
import {BillingDetailsEnteredEvent} from "./events/checkout/billing-details-entered.event";
import {RebuildCheckoutSubscriber} from "./subscribers/checkout/rebuild-checkout.subscriber";
import {RebuildInitiatedEvent} from "./events/checkout/rebuild-initiated.event";
import {CreateOrderEvent} from "./events/checkout/create-order.event";
import {CreateOrderSubscriber} from "./subscribers/checkout/create-order.subscriber";
import {GetOrderOverviewEvent} from "./events/checkout/get-order-overview.event";
import {GetOrderOverviewSubscriber} from "./subscribers/checkout/get-order-overview.subscriber";
import {ReserveTimeslotSubscriber} from "./subscribers/timeslots/reserve-timeslot.subscriber";
import {RebuildBillingSubscriber} from "./subscribers/checkout/rebuild-billing.subscriber";
import {LoadHomeDataEvent} from "./events/home/load-home-data.event";
import {LoadHomeCategoriesSubscriber} from "./subscribers/home/load-home-categories.subscriber";
import {LoadCampaignDataSubscriber} from "./subscribers/home/load-campaign-data.subscriber";
import {AttractionMainSearchProductEvent} from "./events/attractions-main/attraction-main-search-product.event";
import {AttractionMainSearchProductSubscriber} from "./subscribers/attractions-main/attraction-main-search-product.subscriber";
import {HomePageSearchProductEvent} from "./events/home/home-page-search-product.event";
import {HomePageSearchProductSubscriber} from "./subscribers/home/home-page-search-product.subscriber";
import {FilterProductByCategoryCityEvent} from "./events/attractions-main/filter-product-by-category-city-event";
import {
    FilterProductByCategoryCitySubscriber
} from "./subscribers/attractions-main/filter-product-by-category-city.subscriber";
import {LoadAllProductsEvent} from "./events/attractions-main/load-all-products.event";
import {LoadAllProductsSubscriber} from "./subscribers/attractions-main/load-all-products.subscriber";
import {RaiseErrorEvent} from "./events/core/raise-error.event";
import {ShowErrorPopupEvent} from "./subscribers/core/show-error-popup.event";
import {SelectContentPartnerEvent} from "./events/content-partner/select-content-partner.event";
import {SelectContentPartnerSubscriber} from "./subscribers/content-partner/select-content-partner.subscriber";
import {GetMerchantReferenceEvent} from "./events/checkout/get-merchant-reference.event";
import {GetMerchantReferenceSubscriber} from "./subscribers/checkout/get-merchant-reference.subscriber";
import {InvalidStateEvent} from "./events/checkout/invalid-state.event";
import {RecoverInvalidStateSubscriber} from "./subscribers/checkout/recover-invalid-state.subscriber";
import {ChangeLanguageEvent} from "./events/home/change-language.event";
import {ChangeLanguageSubscriber} from "./subscribers/home/change-language.subscriber";
import {CreateIngenicoOrderEvent} from "./events/checkout/create-ingenico-order.event";
import {CreateIngenicoOrderSubscriber} from "./subscribers/checkout/create-ingenico.order.subscriber";

export let RAISE_ERROR: (...parameters: string[]) => void;

@Injectable()
export class EventRegistry {

    constructor(
        private dispatcher: EventsDispatcher
    ) {
        dispatcher
            .register(LoadHomeDataEvent, [LoadCampaignDataSubscriber, LoadHomeCategoriesSubscriber])
            .register(QuantityChangeEvent, [ReservationSubscriber])
            .register(ReservationPlacedEvent, [TimeslotsSubscriber, CheckCheckoutConditionsSubscriber])
            .register(CalendarDateSelectEvent, [ResetCheckoutSubscriber, CheckCheckoutConditionsSubscriber, LoadProductsSubscriber])
            .register(SelectTimeslotEvent, [SelectTimeslotSubscriber, CheckCheckoutConditionsSubscriber])
            .register(SelectLocationEvent, [SelectLocationSubscriber, LoadNoDateProductsSubscriber])
            .register(SelectContentPartnerEvent, [SelectContentPartnerSubscriber])
            .register(CheckoutSubmitEvent, [NavigateBillingDetailsSubscriber])
            .register(BillingDetailsSubmitEvent, [SetBillingDetailsSubscriber, NavigatePaymentSubscriber])
            .register(CheckoutEnteredEvent, [ResetCheckoutSubscriber, SetCheckoutContextSubscriber, LoadContentPartnerSubscriber, RebuildCheckoutSubscriber])
            .chain(BillingDetailsEnteredEvent, [RebuildBillingSubscriber, ReserveTimeslotSubscriber])
            .register(ToggleVisitGuaranteeEvent, [SetVisitGuaranteeSubscriber])
            .register(RebuildInitiatedEvent, [LoadProductsSubscriber], (res) => { return [res.visitDate()];})
            .register(CreateOrderEvent, [CreateOrderSubscriber])
            .register(CreateIngenicoOrderEvent, [CreateIngenicoOrderSubscriber])
            .register(GetOrderOverviewEvent, [GetOrderOverviewSubscriber])
            .register(AttractionMainSearchProductEvent, [AttractionMainSearchProductSubscriber])
            .register(HomePageSearchProductEvent, [HomePageSearchProductSubscriber, AttractionMainSearchProductSubscriber])
            .register(FilterProductByCategoryCityEvent, [FilterProductByCategoryCitySubscriber])
            .register(ChangeLanguageEvent, [ChangeLanguageSubscriber, LoadCampaignDataSubscriber, LoadHomeCategoriesSubscriber])
            .register(LoadAllProductsEvent,[LoadAllProductsSubscriber])
            .register(RaiseErrorEvent, [ShowErrorPopupEvent])
            .register(GetMerchantReferenceEvent,[GetMerchantReferenceSubscriber])
            .register(InvalidStateEvent, [RecoverInvalidStateSubscriber])
        ;

        RAISE_ERROR = dispatcher.createInvokeFunction(RaiseErrorEvent);
    }
}