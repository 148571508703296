<div class="container">
    <div class="row">
        <div class="col-12">
            <div>
                <div class="row">
                    <div class="col-12">
                        <h3>{{'ACCOUNT.delete_account_title' | translate}}</h3>
                    </div>
                </div>
            </div>
                <div class="row">
                    <div class="col-12">
                        <p class="mb-1">{{'ACCOUNT.delete_account_intro_1' | translate}}</p>
                        <p class="mb-2">{{'ACCOUNT.delete_account_intro_2' | translate}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button
                                (click)="deleteAccount()" type="button" class="btn btn-lg delete-account-button">
                            {{'ACCOUNT.delete_account_button' | translate}}
                        </button>
                    </div>
                </div>
        </div>
    </div>
</div>