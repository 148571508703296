import {Injectable} from "@angular/core";
import {SubscriberContract} from "../../contracts/subscriber.contract";
import {CheckoutProvider} from "../../../providers/checkout.provider";
import {Reservation} from "../../../models/checkout/reservation.model";

@Injectable()
export class SetVisitGuaranteeSubscriber implements SubscriberContract<Reservation|null> {
    constructor(private checkoutProvider: CheckoutProvider) {}

    async handle(visitGuarantee: boolean): Promise<Reservation|null> {
        return this.checkoutProvider.replayReservation(undefined, undefined, visitGuarantee).toPromise();
    }
}