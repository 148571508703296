import {DateTime} from "luxon";

/**
 * date string format, same format we get from API
 */
export function getDateStr(d: Date): string {
  return DateTime.fromJSDate(d).toISODate()!;
}

/**
 * date string format, same format we get from API
 */

export function buildQueryParams(
    params: { [prop: string]: any },
    separator = true
): string {
  const entries = Object.entries(params);

  if (!entries.length) return '';

  const sParams = new URLSearchParams();

  entries.forEach(([attr, val]) => {
    sParams.append(attr, val);
  });

  return `${separator ? '?' : ''}${sParams.toString()}`;
}


/**
 * Replace query params without reloading the page
 */
export function removeQueryParams(): void {
  window.history.replaceState(
    null,
    null as unknown as string,
    window.location.pathname
  );
}

/**
 * get query param
 */
export function getQueryParam(paramName: string): string | null {
  const searchParams = getAllQueryParams();
  return searchParams.get(paramName);
}

export function getAllQueryParams(): URLSearchParams {
  const url = new URL(decodeURIComponent(window.location.href));
  return new URLSearchParams(url.search.slice(1));
}

/**
* Useful for generating nonce || state || uuid
* @returns random string
*/

export function generateCryptoSecureStr(length = 42): string {
  let charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._';
  let result = '';

  while (length > 0) {
    let random = window.crypto.getRandomValues(new Uint8Array(16));

    random.forEach(function (c) {
      if (length == 0) {
        return;
      }
      if (c < charset.length) {
        result += charset[c];
        length--;
      }
    });
  }
  return result;
}


/**
 * Useful for dynamic imports
 */
export function normalizeCommonJSImport<T>(
  importPromise: Promise<T>
): Promise<T> {
  // CommonJS's `module.exports` is wrapped as `default` in ESModule.
  return importPromise.then((m: any) => (m.default || m) as T);
}


export function returnUrl(): string {
  const url = window.location.origin;
  return url.replace('www.', '');
}
