export enum ProductType {
    TICKET = 'TICKET',
    PHYSICAL_PRODUCT = 'PHYSICAL_PRODUCT',
    REDEEM_VOUCHER = 'REDEEM_VOUCHER'
}


export class ReservationProduct {
    constructor(
        private _productUuid: string,
        private _quantity: number,
        private _price: number,
        private _productName: string,
        private _sku: string,
        private _productType: string,
        private _discount: number,
        private _date: Date,
        private _locationName: string,
        private _contentPartnerName: string,
        private _contentPartnerUuid: string
    ) {}

    price(): number {
        return this._price;
    }

    quantity(): number {
        return this._quantity;
    }

    productUuid(): string {
        return this._productUuid;
    }

    productName(): string {
        return this._productName;
    }

    productType(): string {
        return this._productType;
    }

    discount(): number {
        return this._discount;
    }

    effectivePrice(): number {
        if (!this._price) {
            return 0;
        }

        if (!this._discount) {
            return this._price;
        }

        return this._price - this._discount;
    }

    effectiveTotal(): number {
        if (!this._quantity) {
            return 0;
        }

        return this.effectivePrice() * this._quantity;
    }

    productDate(): Date {
        return this._date;
    }

    addQuantity(quantity: number) {
        this._quantity += quantity;
    }

    contentPartnerName(): string {
        return this._contentPartnerName;
    }

    contentPartnerUuid(): string {
        return this._contentPartnerUuid;
    }

    locationName(): string {
        return this._locationName;
    }

    unique(): string {
        return this.productUuid() + "_" + this.effectivePrice();
    }

    sku(): string {
        return this._sku;
    }
}