import {Injectable} from "@angular/core";
import {SubscriberContract} from "../../contracts/subscriber.contract";
import {Router} from "@angular/router";

@Injectable()
export class NavigateBillingDetailsSubscriber implements SubscriberContract<any> {
    constructor(private router: Router) {}

    async handle(): Promise<boolean> {
        return this.router.navigate(['checkout/billing-details']);
    }
}