import {Component, OnDestroy, OnInit} from "@angular/core";
import {environment} from "../../../../../environments/environment";

@Component({
    selector: 'qup-plus-account',
    templateUrl: '../../templates/download-app/download-app.component.html',
    styleUrls: ['../../templates/download-app/download-app-component.scss']
})
export class DownloadAppComponent {

    protected readonly environment = environment;
}

