import {CheckoutProvider} from "../../../providers/checkout.provider";
import {Injectable} from "@angular/core";
import {SubscriberContract} from "../../contracts/subscriber.contract";
import {of} from "rxjs";

@Injectable()
export class GetMerchantReferenceSubscriber implements SubscriberContract<string> {
    constructor(private checkoutProvider: CheckoutProvider) {}

    async handle(event: string): Promise<string> {
        this.checkoutProvider.merchantReference().next(event);
        return of(event).toPromise();
    }
}