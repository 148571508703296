import {Injectable} from "@angular/core";
import {StandardEvent} from "../../../../dispatch/events/standard.event";
import {ReplaySubject} from "rxjs";
import {ChangeDatePayload} from "../../../../interfaces/change-date.interface";

@Injectable()
export class SendChangeDateEmailEvent extends StandardEvent<ChangeDatePayload> {
    constructor() {
        super(new ReplaySubject<ChangeDatePayload>());
    }

    dispatch(payload: ChangeDatePayload): void {
        this.subject.next(payload);
    }
}