import {Injectable} from "@angular/core";
import {StandardEvent} from "../standard.event";
import {ReplaySubject} from "rxjs";

@Injectable()
export class BillingDetailsEnteredEvent extends StandardEvent<string|undefined> {
    constructor() {
        super(new ReplaySubject<string|undefined>());
    }

    dispatch(orderReservationId: string|undefined): void {
        this.subject.next(orderReservationId);
    }
}