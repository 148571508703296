import {Injectable} from "@angular/core";
import {ReplaySubject} from "rxjs";
import {StandardEvent} from "../../../../dispatch/events/standard.event";
import {Reservation} from "../../../../models/checkout/reservation.model";

@Injectable()
export class GetPlusWalletEvent extends StandardEvent<void> {

    constructor() {
        super(new ReplaySubject<void>());
    }

    dispatch(): void {
        this.subject.next();
    }
}