import {Injectable} from "@angular/core";
import {ReplaySubject} from "rxjs";
import {StandardEvent} from "../standard.event";

@Injectable()
export class GetMerchantReferenceEvent extends StandardEvent<string> {

    constructor() {
        super(new ReplaySubject<string>())
    }

    dispatch(merchantReference: string): void {
        this.subject.next(merchantReference);
    }
}