import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ImageSize} from "../../../attractions/enums/image-size.enum";
import {ContentPartner} from "../../../models/content-partner/content-partner.model";
import {environment} from "../../../../environments/environment";
import {ContentPartnerClickedEvent} from "../../../dispatch/events/content-partner/content-partner-clicked.event";
import {AppService} from "../../../services/app.service";
import {LocaleEnum} from "../../../../environments/enums/locale.enum";
import {Subscription} from "rxjs";

@Component({
    selector: 'qup-attraction-card',
    templateUrl: './attraction-card.component.html',
    styleUrls: ['./attraction-card.component.scss']
})
export class AttractionCardComponent implements OnInit, OnDestroy {
    // click anywhere on the card to select it
    @Input() selectable = false;
    @Input() product!: ContentPartner;
    @Input() mobileFullWidth = false;
    @Input() enableHeart: boolean = false;
    public emptyDiscountStickerText = false;
    private _subs: Subscription[] = [];

    constructor(
        private router: Router,
        private contentPartnerClickedEvent: ContentPartnerClickedEvent,
        private appService: AppService
    ) { }

    ngOnInit(): void {
        this._subs.push(
            this.appService.currentLanguage$.subscribe((data) => {
                this.emptyDiscountStickerText = data == LocaleEnum.en_GB;
            })
        );
    }

  cardLocation(): string {
    return this.product.cities().length == 1 ? this.product?.cities()[0] : 'ATTRACTION_CARD.multiple_locations';
  }

  cardThumbnail(): string {
    if (this.product.thumbnail()) {
      return this.product.thumbnail().replace("{{resolution}}", ImageSize.THUMBNAIL);
    } else {
      return '/assets/images/logo.png';
    }
  }

  cardDiscountPercentage(): number {
    return Math.round(this.product.maxDiscountPercentage() * 100);
  }

  goToAttraction(alias: string): void {
    this.contentPartnerClickedEvent.dispatch(this.product);
    this.router.navigate([`${environment.attractionsRoutingPathName}/${alias}`]);
  }

  ngOnDestroy(): void {
     this._subs.forEach((sub) => sub.unsubscribe());
  }

  displayOnMap(product: ContentPartner): boolean {
    let displayOnMap = true;
    product.locations().forEach((location) => {
        displayOnMap = location.displayOnMap();
        return;
    });

    return displayOnMap;
  }
}
