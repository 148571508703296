import {Injectable} from "@angular/core";
import {StandardEvent} from "../standard.event";
import {ReplaySubject} from "rxjs";

@Injectable()
export class HomePageSearchProductEvent extends StandardEvent<string|undefined> {
    constructor() {
        super(new ReplaySubject<string|undefined>());
    }

    dispatch(event: string): void {
        this.subject.next(event);
    }
}