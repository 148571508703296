import {Injectable} from "@angular/core";
import {SubscriberContract} from "../../contracts/subscriber.contract";
import {ErrorProvider} from "../../../providers/error.provider";

@Injectable()
export class ShowErrorPopupEvent implements SubscriberContract<void> {
    constructor(
        private errorProvider: ErrorProvider
    ) {}

    async handle(error: string[]): Promise<void> {
        this.errorProvider.raiseError(error)
    }
}