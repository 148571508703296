import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, fromEventPattern, Subject, Subscription } from 'rxjs';
import { pluck, startWith } from 'rxjs/operators';

declare var window: any;

const mediaQueryList = window.matchMedia('(max-width: 960px)');

@Injectable()
export class UiService implements OnDestroy {
  private _subs: Subscription[] = [];

  public _isMobileOverlay$ = new BehaviorSubject<boolean>(
    mediaQueryList.matches
  );

  resetForm$ = new Subject<void>();

  showHeaderSearch$ = new BehaviorSubject(false);

  resetLostTicketsForm$ = new Subject();

  isMobileOverlay$ = this._isMobileOverlay$.asObservable();

  private _mediaQueryListener$ = fromEventPattern(
    (handler) => mediaQueryList.addListener(handler),
    (handler) => {
      mediaQueryList.removeListener(handler);
    }
  ).pipe(startWith(mediaQueryList), pluck('matches'));

  constructor() {
    this._subs.push(
      this._mediaQueryListener$.subscribe((matches) => {
        this._isMobileOverlay$.next(matches);
      })
    );
  }

  /**
   * Reset search form in header
   */
  resetSearchForm(): void {
    this.resetForm$.next();
  }

  resetLostTicketsForm(): void {
    this.resetLostTicketsForm$.next();
  }

  ngOnDestroy(): void {
    this._subs.forEach((s) => s?.unsubscribe());
  }

}
