import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {LostTicketsEventRegistry} from "../lost-tickets-event.registry";
import {SendLostTicketsEmailEvent} from "../dispatch/events/send-lost-tickets-email.event";
import {LostTicketsProvider} from "../lost-tickets.provider";
import {UiService} from "../../../services/ui.service";
import {LostTicketsService} from "../../../services/lost-tickets.service";
import {first} from "rxjs/operators";

@Component({
  selector: 'qup-lost-tickets',
  templateUrl: './../../../templates/lost-tickets/lost-tickets.component.html',
  styleUrls: ['./../../../templates/lost-tickets/lost-tickets.component.scss'],
})

export class LostTicketsComponent implements OnInit, OnDestroy {

  private _subs: Subscription[] = [];
  public isSent = false;
  public isSuccessful = false;
  public isMobile = false;

  form: FormGroup = this.formBuilder.group({
    orderNumber: [null, [Validators.required]],
    email: [null, [Validators.required, Validators.email]],
  });

  constructor(
      private lostTicketService: LostTicketsService,
      private formBuilder: FormBuilder,
      private uiService: UiService,
      private changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this._subs.push(
        this.uiService.isMobileOverlay$.subscribe((isMobile) => {
          this.isMobile = isMobile;
          this.changeDetectorRef.detectChanges();
        }),
    );
  }

  submit(): void {
    const email = this.form.get('email')?.value;
    const orderNumber = this.form.get('orderNumber')?.value;

    if (email && orderNumber) {
      this.lostTicketService.sendLostTicketsEmail({
        Email: email,
        OrderNumber: orderNumber
      }).pipe(first()).subscribe((res) => {
          this.isSuccessful = true;
          this.isSent = true;

        console.log(this.isSent, this.isSuccessful);
        }, e => {
          this.isSuccessful = false;
          this.isSent = true;
        });
      }
  }

  clearMessage(): void {
    this.isSent = false;
    this.isSuccessful = false;
  }

  ngOnDestroy(): void {
    this.clearMessage();
    this._subs.forEach(s => s?.unsubscribe());
  }
}
