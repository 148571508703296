import {CheckoutProvider} from "../../../providers/checkout.provider";
import {Injectable} from "@angular/core";
import {SubscriberContract} from "../../contracts/subscriber.contract";
import {ContentApiService} from "../../../services/content-api.service";
import {Product} from "../../../models/content-partner/product.model";
import {tap} from "rxjs/operators";
import {of} from "rxjs";
import {Location} from "../../../models/content-partner/location.model";


@Injectable()
export class LoadNoDateProductsSubscriber implements SubscriberContract<Product[]> {
    constructor(
        private checkoutProvider: CheckoutProvider,
        private contentService: ContentApiService,
    ) {}

    async handle(location: Location): Promise<Product[]> {
        const context = this.checkoutProvider.checkoutContext().getValue();
        if (context == null) {
            return of([]).toPromise();
        }

        if (location.showCalendar()) {
            return of([]).toPromise();
        }

        return this.contentService.getSubProductsByLocation(
            context.language(),
            context.campaignUuid(),
            location,
            new Date(),
            context.contentPartnerUuid()!
        ).pipe(
            tap((data) => {
                this.checkoutProvider.products().next(data);
            })
        ).toPromise();
    }
}