export class Image {
    constructor(
        private _caption: string,
        private _url: string,
    ) {
    }

    caption(): string {
        return this._caption;
    }

    url(): string {
        return this._url.replace('{{resolution}}', '730x450');
    }

    thumbnail(): string {
        return this._url.replace('{{resolution}}', '730x450');
    }

    fullImage(): string {
        return this._url.replace('{{resolution}}', '1080x');
    }

}