import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

@Injectable()
export class CoreProvider {

    private changeDetectorSubscription = new Subject<void>();


    constructor() { }

    changeDetector(): Subject<void> {
        return this.changeDetectorSubscription;
    }

}