import {Injectable} from "@angular/core";
import {EventsDispatcher} from "../../dispatch/events.dispatcher";
import {BillingDetailsEnteredEvent} from "../../dispatch/events/checkout/billing-details-entered.event";
import {InitializeCheckoutSubscriber} from "./dispatch/subscribers/initialize-checkout.subscriber";
import {OrderCompleteEvent} from "../../dispatch/events/checkout/order-complete.event";
import {FinalizeCheckoutSubscriber} from "./dispatch/subscribers/finalize-checkout.subscriber";

@Injectable()
export class AnalyticsEventRegistry {
    constructor(
        private dispatcher: EventsDispatcher
    ) {
        dispatcher
            .register(BillingDetailsEnteredEvent, [InitializeCheckoutSubscriber])
            .register(OrderCompleteEvent, [FinalizeCheckoutSubscriber])
        ;
    }
}