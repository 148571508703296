import {CheckoutProvider} from "../../../providers/checkout.provider";
import {Injectable} from "@angular/core";
import {SubscriberContract} from "../../contracts/subscriber.contract";
import {of} from "rxjs";
import {Location} from "../../../models/content-partner/location.model";

@Injectable()
export class SelectLocationSubscriber implements SubscriberContract<Location> {
    constructor(private checkoutProvider: CheckoutProvider) {}

    async handle(event: Location): Promise<Location> {
        this.checkoutProvider.selectedLocation().next(event);
        return of(event).toPromise();
    }
}