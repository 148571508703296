import {ReservationProduct} from "../checkout/reservation-product.model";
import {Location} from "./location.model";

export class Product {
    constructor(
        private _productUuid: string,
        private _listPrice: number,
        private _price: number,
        private _productName: string,
        private _contentPartnerUuid: string,
        private _location: Location,
        private _productType: string,
        private _availableStock: number,
        private _maxDiscount: number,
        private _fixedPointCost: number,
        private _discountStickerType?: string,
        private _date?: Date,
        private _superDeal?: boolean,
        private _conditions?: string,
    ) {}

    price(): number {
        return this._price;
    }

    discountPercentage(): number {
        return Math.floor(this.maxDiscount() / this.listPrice() * 100);
    }

    effectivePrice(): number {
        if (!this._price) {
            return 0;
        }

        if (!this._maxDiscount) {
            return this._price;
        }

        return this._price - this._maxDiscount;
    }

    hasDiscount(): boolean {
        return this.effectivePrice() < this.listPrice();
    }

    listPrice(): number {
        if (this._listPrice == 0) {
            return this._price;
        }

        return this._listPrice;
    }

    productUuid(): string {
        return this._productUuid;
    }

    productName(): string {
        return this._productName;
    }

    productType(): string|undefined {
        return this._productType;
    }

    availableStock(): number {
        return this._availableStock ?? 0;
    }

    maxDiscount(): number {
        return this._maxDiscount;
    }

    discountStickerType(): string|undefined {
        return this._discountStickerType;
    }

    productDate(): Date|undefined {
        return this._date;
    }

    superDeal(): boolean {
        if (!this._superDeal) {
            return false;
        }

        return this._superDeal;
    }

    conditions(): string | undefined {
        return this._conditions;
    }

    fixedPointCost(): number {
        return this._fixedPointCost;
    }

    contentPartnerUuid(): string {
        return this._contentPartnerUuid;
    }

    location(): Location {
        return this._location;
    }
}