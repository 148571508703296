<div class="grid">
    <div class="row">
        <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <h2>Account verwijderen?</h2>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-8">
                    <p>
                        Als je niet langer mee wil doen met de promotie kan je je gegevens bij ons laten verwijderen. Dit kan gemakkelijk vanuit de Coop Dagje Uit app.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="{{isMobile ? 'col-12' : 'col-4'}} mt-2">
            <h4 class="mb-2">Stap 1 - Ga naar Account</h4>
            <img class="phone" src="assets/images/how_to_delete_2.jpeg" alt="Dagjes Uit"/>

        </div>
        <div class="{{isMobile ? 'col-12' : 'col-5'}} mt-2">
            <h4 class="mb-2">Stap 2 - Klik op de Account verwijderen knop</h4>
            <img class="phone" src="assets/images/how_to_delete_1.jpeg" alt="Dagjes Uit"/>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-12">
            Je account wordt dan direct uit ons systeem verwijderd, <strong>LET OP!</strong> dit kunnen wij niet ongedaan maken.
        </div>
    </div>
</div>