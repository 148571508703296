export class CheckoutContext {
    constructor(
        private _language: string,
        private _campaignUuid: string,
        private _contentPartnerAlias: string,
        private _locationUuid?: string,
        private _contentPartnerUuid?: string,
    ) {
    }

    language(): string {
        return this._language;
    }

    campaignUuid(): string {
        return this._campaignUuid;
    }

    contentPartnerAlias(): string {
        return this._contentPartnerAlias;
    }

    locationUuid(): string|undefined {
        return this._locationUuid;
    }

    contentPartnerUuid(): string|undefined {
        return this._contentPartnerUuid;
    }
}