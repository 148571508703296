import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import {ContentPartner} from "../../models/content-partner/content-partner.model";
import {AttractionProvider} from "../../providers/attraction.provider";
import {Subscription} from "rxjs";
import {UiService} from "../../services/ui.service";
import {MarkerClusterer} from "@googlemaps/markerclusterer";
import {SelectContentPartnerEvent} from "../../dispatch/events/content-partner/select-content-partner.event";
import {
    FilterProductByCategoryCityEvent
} from "../../dispatch/events/attractions-main/filter-product-by-category-city-event";
import {AttractionsToolbarComponent} from "../../attractions/attractions-toolbar/attractions-toolbar.component";
import {FiltersInput} from "../../interfaces/content-api.interface";

interface Location {
    coordinates: {lat: number, lng: number},
    contentPartner: ContentPartner,
}

interface AttractionLocalFilters {
    cityFilter: string[];
    categoryFilter: string[];
    sortBy: string;
}

@Component({
    selector: 'qup-see-map',
    templateUrl: '../../templates/attractions/see-map/see-map.component.html',
    styleUrls: ['../../templates/attractions/see-map/see-map.component.scss'],
})

export class SeeMapComponent implements OnInit, OnDestroy {
    _subs: Subscription[] = [];
    @ViewChild(AttractionsToolbarComponent) toolbar!: AttractionsToolbarComponent;
    isMobile = true;
    public contentPartners: ContentPartner[] = [];
    public selectedContentPartner!: ContentPartner | null;
    center: google.maps.LatLngLiteral = {lat: 52.0907, lng: 5.1214};
    @ViewChild("map") mapElement: any;
    map: any;
    localFilters: AttractionLocalFilters = {cityFilter: [], categoryFilter: [], sortBy: ''};

    constructor(
        private uiService: UiService,
        private changeDetectorRef: ChangeDetectorRef,
        private attractionMainProvider: AttractionProvider,
        private selectContentPartnerEvent: SelectContentPartnerEvent,
        private filterProductsByCategoryCityEvent: FilterProductByCategoryCityEvent,
    ) {
    }

    ngOnInit(): void {
        this._subs.push(
            this.uiService.isMobileOverlay$.subscribe((isMobile) => {
                this.isMobile = isMobile;
                this.changeDetectorRef.detectChanges();
            }),
            this.attractionMainProvider.selectedCategory().subscribe(
                (selectedCategory) => {
                    this.localFilters = {
                        cityFilter: [],
                        categoryFilter: selectedCategory !== null ? [selectedCategory] : [],
                        sortBy: '',
                    };
                    this.filterProducts();
                }
            ),
            this.attractionMainProvider.selectedContentPartner().subscribe((data) => this.selectedContentPartner = data),
            this.attractionMainProvider.contentPartners().subscribe((data) => {
                this.contentPartners = data;
                this.initMapClusters(data);
            })
        );
    }

    filterProducts(): void {
        const {cityFilter, categoryFilter, sortBy} = this.localFilters;

        let filters: FiltersInput = {categories: categoryFilter, cities: cityFilter, sortBy: sortBy};
        this.filterProductsByCategoryCityEvent.dispatch(filters);
    }

    initMapClusters(data: ContentPartner[]): void {

        const infoWindow = new google.maps.InfoWindow({
            content: "",
            disableAutoPan: true,
        });

        let locationsCoordinates: Location[] = [];

        data.forEach((cP) => {
            cP.locations().forEach((location) => {
                locationsCoordinates.push({coordinates: {lat: location.coordinates().lat(), lng: location.coordinates().lon()}, contentPartner: cP});
            });
        });

        this.map = new google.maps.Map(
            this.mapElement.nativeElement,
            {center: this.center, streetViewControl: true, zoom: 8, mapTypeControl: false}
        );

        const markers = locationsCoordinates.map((location) => {
            const marker = new google.maps.Marker({position: location.coordinates, map: this.map});

            marker.addListener("click", () => {
                this.selectContentPartnerEvent.dispatch(location.contentPartner);
                this.map.setCenter(location.coordinates);
                infoWindow.setContent(location.contentPartner.caption());
                infoWindow.open(this.map, marker);
            });
            return marker;
        });

        new MarkerClusterer({map: this.map, markers: markers});
    }

    closeSelectedContentPartner(): void {
        // this.loadAllProductsEvent.dispatch();
        this.selectedContentPartner = null;
    }

    ngOnDestroy(): void{
        this._subs.forEach((s) => s?.unsubscribe());
    }

}
