import {Injectable} from "@angular/core";

interface GoogleAnalyticsEvent {
    event?: string,
    ecommerce: EcommerceEvent|null
}

export enum ConsentBoolean {
    denied = 'denied',
    granted = 'granted'
}

export interface ConsentConfiguration {
    analytics_storage: ConsentBoolean,
    ad_storage: ConsentBoolean,
    ad_personalization: ConsentBoolean,
    ad_user_data: ConsentBoolean,
}

export interface EcommerceItem {
    item_id: string,
    item_name: string,
    affiliation?: string,
    coupon?: string,
    discount?: number,
    index?: number,
    item_brand: string,
    item_category?: string,
    item_category2?: string,
    item_category3?: string,
    item_category4?: string,
    item_category5?: string,
    item_list_id?: string,
    item_list_name?: string,
    item_variant?: string,
    location_id?: string,
    price?: number,
    quantity?: number
}

interface EcommerceEvent {
    currency: string,
    value: number,
    tax?: number,
    shipping?: number,
    coupon?: string,
    transaction_id?: string,
    items: EcommerceItem[]
}

interface DataLayer {
    push(i: GoogleAnalyticsEvent): void;
}

declare global {
    interface Window {
        // @ts-ignore
        dataLayer: DataLayer|any
    }
}

function gtag(){window.dataLayer.push(arguments);}

@Injectable()
export class GoogleAnalyticsGtmService {

    constructor(

    ) {
    }

    public initializeConsent(): void {
        // @ts-ignore
        gtag('consent', 'default', {
            analytics_storage: ConsentBoolean.denied,
            ad_personalization: ConsentBoolean.denied,
            ad_storage: ConsentBoolean.denied,
            ad_user_data: ConsentBoolean.denied
        } as ConsentConfiguration);
    }

    public updateConsent(consentConfiguration: ConsentConfiguration): void {
       // @ts-ignore
        gtag('consent', 'update', consentConfiguration);
    }

    public viewItem(items: EcommerceItem[]): void {
        this.sendEvent({
            event: 'view_item',
            ecommerce: this.getStandardEcommerceObject(items)
        });
    }

    public addToCart(items: EcommerceItem[]): void {
        this.sendEvent({
            event: 'add_to_cart',
            ecommerce: this.getStandardEcommerceObject(items)
        });
    }

    public removeFromCart(items: EcommerceItem[]): void {
        this.sendEvent({
            event: 'remove_from_cart',
            ecommerce: this.getStandardEcommerceObject(items)
        });
    }

    public beginCheckout(items: EcommerceItem[]): void {
        this.sendEvent({
            event: 'begin_checkout',
            ecommerce: this.getStandardEcommerceObject(items)
        });
    }

    public purchase(transactionId: string, grandTotal: number, items: EcommerceItem[]): void {

        let ecommerceObject = this.getStandardEcommerceObject(items);

        ecommerceObject.value = grandTotal;
        ecommerceObject.transaction_id = transactionId;

        this.sendEvent({
            event: 'purchase',
            ecommerce: ecommerceObject
        });
    }

    private getStandardEcommerceObject(items: EcommerceItem[]): EcommerceEvent {
        return {
            currency: 'EUR',
            value: this.calculateItemsSubTotal(items),
            items: items
        };
    }

    private calculateItemsSubTotal(items: EcommerceItem[]): number {
        let subTotal = 0;

        items.forEach((item) => {
            subTotal += ((item.price?? 0) - (item.discount?? 0)) * (item.quantity ?? 0);
        });

        return subTotal;
    }

    private sendEvent(event: GoogleAnalyticsEvent): void {
        // Actively blocking GTM, return.
        if (!window.dataLayer) {
            return;
        }

        window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.

        window.dataLayer.push(event);
    }
}