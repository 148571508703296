import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment as env} from "../../environments/environment";

export interface GetSessionTokenResponse {
    success: boolean,
    response: TokenResponse
}

export interface TokenResponse {
    token: string;
}

const URLS = {
    getSessionTokenEndpoint: (): string => `${env.contentApiUri}/session/token`,
}

@Injectable({
    providedIn: 'root'
})
export class PlatformService {

    constructor(
        private http: HttpClient
    ) {
    }

    getSessionToken(campaignUuid: string): Observable<GetSessionTokenResponse>{
        return this.http.get<GetSessionTokenResponse>(URLS.getSessionTokenEndpoint(), {
            params: {
                campaign: campaignUuid
            }
        });
    }
}