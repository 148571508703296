import {Injectable} from "@angular/core";
import {BehaviorSubject, forkJoin, Observable, of, ReplaySubject} from "rxjs";
import {catchError, first, map, mergeMap, tap} from "rxjs/operators";
import {Reservation} from "../models/checkout/reservation.model";
import {ReservationProduct} from "../models/checkout/reservation-product.model";
import {CheckoutService, CreateReservationRequest, ProductEntry} from "../services/checkout.service";
import {environment as env} from "../../environments/environment";
import {AvailableTimeslots} from "../models/timeslots/available-timeslots.model";
import {TimeslotsService} from "../services/timeslots.service";
import {ReservationPlacedEvent} from "../dispatch/events/checkout/reservation-placed.event";
import {ContentApiService} from "../services/content-api.service";
import {Timeslot} from "../models/timeslots/timeslot";
import {Location} from "../models/content-partner/location.model";
import {ContentPartner} from "../models/content-partner/content-partner.model";
import {Product} from "../models/content-partner/product.model";
import {CheckoutContext} from "../models/checkout/checkout-context.model";
import {BillingDetails} from "../models/checkout/billing-details.model";
import {RebuildInitiatedEvent} from "../dispatch/events/checkout/rebuild-initiated.event";
import {Order} from "../models/checkout/order.model";
import {PaymentService} from "../services/payment.service";
import {AdyenOnSubmitResponse, CheckoutPaymentRequest} from "../interfaces/payment.interface";
import {OrderOverview} from "../models/checkout/order-overview/order-overview.model";
import {GetOrderOverviewEvent} from "../dispatch/events/checkout/get-order-overview.event";
import {ReserveTimeslot} from "../models/timeslots/reserve-timeslot.model";
import {AppService} from "../services/app.service";
import {BillingDetailInput} from "../interfaces/ui-components.interface";
import {CreateOrderEvent} from "../dispatch/events/checkout/create-order.event";
import {CheckoutResetEvent} from "../dispatch/events/checkout/checkout-reset.event";
import {StorageNS, StorageService} from "../services/storage.service";
import { Voucher} from "../interfaces/content-api.interface";
import {SelectedProduct} from "../models/checkout/selected-product.model";
import {ReservationReplayedEvent} from "../dispatch/events/checkout/reservation-replayed.event";
import {DateTime} from "luxon";
import {CookieService} from "../services/cookie.service";

interface checkoutConditions {
    reservationGrandTotalAboveZero: Observable<boolean>,
    reservationTimeSlotsSelected: Observable<boolean>
}

export interface checkoutConditionsResolved {
    reservationGrandTotalAboveZero: boolean,
    reservationTimeSlotsSelected: boolean
}


@Injectable()
export class CheckoutProvider {

    private contentPartnerSubscription = new ReplaySubject<ContentPartner>(1);
    private locationsSubscription = new ReplaySubject<Location[]>(1);
    private productSubscription = new ReplaySubject<Product[]>(1);

    private reservationSubscription = new ReplaySubject<Reservation | null>(1);

    private timeslotSubscription = new ReplaySubject<AvailableTimeslots | null>(1);

    private selectedTimeslotSubscription = new BehaviorSubject<Timeslot | null>(null);
    private selectedLocationSubscription = new BehaviorSubject<Location | null>(null);
    private checkoutConditionsMetSubscription = new BehaviorSubject<boolean>(false);
    private checkoutContextSubscription = new BehaviorSubject<CheckoutContext | null>(null);
    private billingDetailsSubscription = new BehaviorSubject<BillingDetails | null>(null);
    private createOrderSubscription = new BehaviorSubject<Order | null>(null);
    private getOrderOverviewSubscription = new BehaviorSubject<OrderOverview | null>(null);
    private reserveTimeslotSubscription = new BehaviorSubject<ReserveTimeslot|null>(null);
    private merchantReferenceSubscription = new ReplaySubject<string | null>();
    private voucherErrorMessageSubscription = new BehaviorSubject<string>('');


    constructor(
        private checkoutService: CheckoutService,
        private paymentService: PaymentService,
        private timeslotsService: TimeslotsService,
        private contentApiService: ContentApiService,
        private appService: AppService,
        private reservationPlacedEvent: ReservationPlacedEvent,
        private reservationReplayedEvent: ReservationReplayedEvent,
        private rebuildInitiatedEvent: RebuildInitiatedEvent,
        private createOrderEvent: CreateOrderEvent,
        private getOrderOverviewEvent: GetOrderOverviewEvent,
        private checkoutResetEvent: CheckoutResetEvent,
        private storageService: StorageService,
        private cookieService: CookieService
    ) {
    }

    checkoutContext(): BehaviorSubject<CheckoutContext | null> {
        return this.checkoutContextSubscription;
    }

    contentPartner(): ReplaySubject<ContentPartner> {
        return this.contentPartnerSubscription;
    }

    reservation(): ReplaySubject<Reservation | null> {
        return this.reservationSubscription;
    }

    timeslots(): ReplaySubject<AvailableTimeslots | null> {
        return this.timeslotSubscription;
    }

    locations(): ReplaySubject<Location[]> {
        return this.locationsSubscription;
    }

    products(): ReplaySubject<Product[]> {
        return this.productSubscription;
    }

    isCheckoutConditionsMet(): BehaviorSubject<boolean> {
        return this.checkoutConditionsMetSubscription;
    }

    selectedTimeslot(): BehaviorSubject<Timeslot | null> {
        return this.selectedTimeslotSubscription;
    }

    selectedLocation(): BehaviorSubject<Location | null> {
        return this.selectedLocationSubscription;
    }

    billingDetails(): BehaviorSubject<BillingDetails | null> {
        return this.billingDetailsSubscription;
    }

    order(): BehaviorSubject<Order | null> {
        return this.createOrderSubscription;
    }

    orderOverview(): BehaviorSubject<OrderOverview | null> {
        return this.getOrderOverviewSubscription;
    }

    merchantReference(): ReplaySubject<string | null> {
        return this.merchantReferenceSubscription;
    }

    voucherErrorMessage(): BehaviorSubject<string> {
        return this.voucherErrorMessageSubscription;
    }

    /**
     * RESET & REBUILD CHECKOUT
     */

    resetCheckout(): void {
        this.reservationSubscription.next(null);
        this.timeslotSubscription.next(null);
        this.selectedTimeslotSubscription.next(null);
        this.checkoutConditionsMetSubscription.next(false);
        this.productSubscription.next([]);

        this.checkoutResetEvent.dispatch();
    }

    rebuildBilling(): Observable<Reservation|null> {
        return this.retrieveReservation().pipe(
            tap((reservation) => {
                if (reservation == null) {
                    return;
                }

                this.reservation().next(reservation);
            })
        );
    }

    rebuildCheckout(): Observable<Reservation | null> {
        return this.reservation().pipe(
            first(),
            mergeMap((reservation) => {
                if (reservation != null) {
                    return of(reservation);
                }

                return this.retrieveReservation().pipe(
                    first(),
                    map((reservation) => {
                        if (reservation == null) {
                            return null;
                        }

                        const context = this.checkoutContext().getValue();
                        if (
                            reservation.contentPartnerUuid() != context?.contentPartnerUuid()
                        ) {
                            return null;
                        }

                        return reservation;
                    })
                );

            }),
            tap((reservation) => {
                if (reservation == null) {
                    return this.reservationSubscription.next(null);
                }

                this.contentPartner().pipe(first()).subscribe((cp) => {
                    cp.locations().forEach((loc) => {
                        if (loc.uuid() == reservation.locationUuid()) {
                            this.selectedLocation().next(loc);
                        }
                    });
                    this.reservation().next(reservation);
                    this.rebuildInitiatedEvent.dispatch(reservation);
                    this.reservationPlacedEvent.dispatch(reservation);
                });
            })
        );
    }

    /**
     * RESERVATION
     */

    replayReservation(loyaltyReservationUuid?: string, voucherSessionUuid?: string, visitGuarantee?: boolean, vouchers?: Voucher[]): Observable<Reservation | null> {

        this.reservationSubscription.pipe(first()).subscribe((currentReservation) => {
            if (currentReservation == null) {
                return;
            }

            if (voucherSessionUuid == undefined) {
                voucherSessionUuid = currentReservation.voucherSessionUuid();
            }

            if (loyaltyReservationUuid == undefined) {
                loyaltyReservationUuid = currentReservation.loyaltyReservationUuid();
            }

            if (visitGuarantee == undefined) {
                visitGuarantee = currentReservation.hasVisitGuarantee();
            }

            this.checkoutService.createReservation(this.placeReservationRequest(currentReservation.aggregatedReservationProducts(), voucherSessionUuid, vouchers, visitGuarantee, loyaltyReservationUuid)).pipe(
                first(),
                tap((reservation) => {
                    this.reservationReplayedEvent.dispatch(reservation);
                })
            ).subscribe((reservation) => {
                this.reservationSubscription.next(reservation);
            }, e => {
                this.voucherErrorMessage().next('VOUCHER_DOES_NOT_EXIST');
            });
        });

        return this.reservationSubscription;
    }

    private placeReservationRequest(products: ReservationProduct[]|SelectedProduct[], voucherSessionUuid?: string, vouchers: Voucher[] = [], visitGuarantee: boolean = false, loyaltyReservationUuid?: string): CreateReservationRequest {
        let productEntries: ProductEntry[] = [];
        let productDate: Date | undefined;
        products.forEach((resProd) => {
            productDate = resProd.productDate();

            productEntries.push({
                ProductUuid: resProd.productUuid(),
                Quantity: resProd.quantity() ?? 0
            });
        });

        if (!productDate) {
            productDate = new Date();
        }

        return {
            Date: DateTime.fromJSDate(productDate).toISODate()!,
            VisitGuarantee: visitGuarantee,
            Lines: productEntries,
            CampaignUuid: env.campaignUuid,
            VoucherSessionUuid: voucherSessionUuid,
            Codes: vouchers,
            Language: 'nl',
            LoyaltyReservationUuid: loyaltyReservationUuid ?? null,
            Store: env.project
        };
    }

    placeReservation(products: SelectedProduct[], voucherSessionUuid?: string, vouchers: Voucher[] = []): Observable<Reservation> {

        const voucherSessionFromStorage = this.storageService.voucherSessionUuid;

        if (voucherSessionFromStorage) {
            voucherSessionUuid = voucherSessionFromStorage;
        }

        return this.checkoutService.createReservation(this.placeReservationRequest(products, voucherSessionUuid, vouchers)).pipe(
            map((reservation) => {
                this.reservationPlacedEvent.dispatch(reservation);
                this.reservationSubscription.next(reservation);
                this.storageService.setItem(StorageNS.VOUCHER_SESSION_UUID, reservation.voucherSessionUuid());
                return reservation;
            }),
        );
    }

    retrieveReservation(): Observable<Reservation | null> {
        return this.checkoutService.retrieveReservation(this.checkoutContext().getValue()?.language() ?? 'nl');
    }

    /**
     * CHECK CONDITIONS
     */

    checkoutConditionsMet(): Observable<checkoutConditionsResolved> {

        const conditions: checkoutConditions = {
            reservationGrandTotalAboveZero: this.reservation().pipe(first(), map((res) => {
                return res != null && res.grandTotal() > 0;
            })),
            reservationTimeSlotsSelected: this.timeslots().pipe(first(), map((res) => {
                if (res == null) {
                    return false;
                }

                if (res.availableTimeslots().length == 0) {
                    return true;
                }

                return this.selectedTimeslotSubscription.getValue() != null;
            }))
        };

        return forkJoin(conditions);
    }

    /**
     * CREATE ORDER
     */

    private createOrderRequest(billingDetails: BillingDetails, orderReservation: Reservation, state?: AdyenOnSubmitResponse): CheckoutPaymentRequest {

        let billingDetailsInput: BillingDetailInput = {
            salutation: 'UNKNOWN',
            firstName: billingDetails.firstName(),
            suffix: billingDetails.suffix(),
            lastName: billingDetails.lastName(),
            email: billingDetails.email(),
            phoneNumber: billingDetails.phoneNumber(),
            postalCode: billingDetails.postalCode(),
            countryUuid: billingDetails.countryUuid(),
            streetName: billingDetails.streetName(),
            houseNumber: billingDetails.houseNumber(),
            houseNumberExt: billingDetails.houseNumberExt(),
            city: billingDetails.city(),
            metaData: billingDetails.metaData().serialize()
        };

        const parsedUrl = new URL(`${window.location.origin}/checkout/payment-result/`);
        const campaignCookie = this.cookieService.getCookie("__campaign_uri");
        if (campaignCookie) {

            let parsedUri = parsedUrl.searchParams;
            let campaignUri = new URLSearchParams(campaignCookie);

            campaignUri.forEach((val, key) => {
                parsedUri.set(key, val);
            });
            parsedUrl.search = campaignUri.toString();
        }

        return {
            orderReservationUuid: orderReservation.orderReservationUuid(),
            campaignUuid: env.campaignUuid,
            psp_provider: 'adyen',
            paymentMethod: 'IDEAL',
            newsletters: billingDetails.newsletters(),
            billingDetail: billingDetailsInput,
            psp_data: {
                campaign_uuid: env.campaignUuid,
                external_id: orderReservation.orderReservationUuid(),
                invoice_id: orderReservation.orderReservationUuid(),
                amount: orderReservation.grandTotal().toString(),
                locale: this.appService.currentLanguage,
                store: env.project,
                order_uuid: orderReservation.orderReservationUuid(),
                currency_code: 'EUR',
                return_url: parsedUrl.toString(),
                state_data: {
                    merchantAccount: env.paymentMethod.payload.merchant_account,
                    reference: orderReservation.orderReservationUuid(),
                    returnUrl: parsedUrl.toString(),
                    amount: {
                        value: orderReservation.grandTotal(),
                        currency: 'EUR',
                    },
                    paymentMethod: state?.data.paymentMethod
                },
                browser_info: {
                    accept_header: 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
                    color_depth: screen.colorDepth,
                    java_enabled: navigator.javaEnabled(),
                    javascript_enabled: true,
                    language: window.navigator.language,
                    screen_height: window.innerHeight,
                    screen_width: window.innerWidth,
                    timezone_offset: new Date().getTimezoneOffset(),
                    user_agent: window.navigator.userAgent,
                }
            }
        };
    }

    createOrder(billingDetails: BillingDetails, orderReservation: Reservation, state?: AdyenOnSubmitResponse, dropin?: any, contentPartnerUuid?: string): Observable<Order> {
        return this.paymentService.createPayment(this.createOrderRequest(billingDetails, orderReservation, state), dropin, contentPartnerUuid).pipe(
            map((data) => {
                this.createOrderSubscription.next(data);
                return data;
            })
        );
    }

    /**
     * CREATE DUC ORDER
     */

    private createDucOrderRequest(billingDetails: BillingDetails, orderReservation: Reservation): CheckoutPaymentRequest {

        let billingDetailsInput: BillingDetailInput = {
            salutation: 'UNKNOWN',
            firstName: billingDetails.firstName(),
            suffix: billingDetails.suffix(),
            lastName: billingDetails.lastName(),
            email: billingDetails.email(),
            phoneNumber: billingDetails.phoneNumber(),
            postalCode: billingDetails.postalCode(),
            countryUuid: billingDetails.countryUuid(),
            streetName: billingDetails.streetName(),
            houseNumber: billingDetails.houseNumber(),
            houseNumberExt: billingDetails.houseNumberExt(),
            city: billingDetails.city(),
            metaData: billingDetails.metaData().serialize()
        };

        return {
            orderReservationUuid: orderReservation.orderReservationUuid(),
            campaignUuid: env.campaignUuid,
            paymentMethod: 'IDEAL',
            newsletters: [],
            billingDetail: billingDetailsInput,
        };
    }

    createDucOrder(billingDetails: BillingDetails, orderReservation: Reservation): Observable<Order> {
        return this.paymentService.createPayment(this.createDucOrderRequest(billingDetails, orderReservation), undefined).pipe(
            map((data) => {
                this.createOrderSubscription.next(data);

                if (data.redirectUrl()) {
                    this.paymentService.redirectIngenicoCheckout(data.redirectUrl());
                }

                return data;
            })
        );
    }


    /**
     * GET ORDER OVERVIEW
     */

    getOrderOverviewByTransaction(transactionId: string): Observable<OrderOverview> {
        return this.checkoutService.getOrderOverviewByTransaction(env.campaignUuid, 'nl', transactionId).pipe(
            map((data) => {
                this.getOrderOverviewSubscription.next(data);
                this.getOrderOverviewEvent.dispatch(data);
                return data;
            }),
            tap((data) => {
                this.reservation().next(data.reservation());
            })
        );
    }

    /**
     * RETRIEVE & RESERVE TIMESLOT
     */

    retrieveTimeSlotsForReservation(orderReservationUuid: string): Observable<AvailableTimeslots> {
        return this.timeslotsService.getAvailableTimeslots(orderReservationUuid).pipe(
            catchError((e) => {
                this.timeslotSubscription.error(e);
                return of(new AvailableTimeslots([]));
            }),
            map((data) => {
                this.timeslotSubscription.next(data);

                return data;
            })
        );
    }


    reserveTimeslot(timeslot: string, orderReservationId: string): Observable<ReserveTimeslot> {
        return this.timeslotsService.reserveTimeslot(timeslot, orderReservationId).pipe(
            map((data) => {
                this.reserveTimeslotSubscription.next(data);
                return data;
            })
        );
    }

    getVouchersOfVoucherSession(voucherSessionUuid: string): Observable<string[]> {
        return this.checkoutService.getVouchersFromSession(voucherSessionUuid).pipe(
            map((result) => {
                let flattenedCodes: string[] = [];
                result.codes.forEach((codeEntry) => {
                    flattenedCodes.push(codeEntry.code);
                });

                return flattenedCodes;
            })
        );
    }

    removeVouchersFromVoucherSession(voucherSessionUuid: string, codes: string[]): Observable<void> {
        return this.checkoutService.removeVouchersFromSession({
            VoucherSessionUuid: voucherSessionUuid,
            CampaignUuid: env.campaignUuid,
            Language: 'nl',
            Codes: codes
        }).pipe(first());
    }

    addVouchersToVoucherSession(voucherSessionUuid: string, codes: string[]): Observable<any> {

        let Codes = codes.map((code) => {
            return {
                Code: code,
                Origin: "MANUAL"
            };
        });

        return this.checkoutService.addVouchersToSession({
            VoucherSessionUuid: voucherSessionUuid,
            CampaignUuid: env.campaignUuid,
            AcceptLanguage: 'nl',
            Codes,
        });
    }

    exchangeVouchers(vouchers: string[]): Observable<string[]> {
        return this.checkoutService.exchangeVouchers({
            CampaignId: env.campaignUuid,
            Vouchers: vouchers
        }).pipe(
            map((vouchersResult) => {
                let exchangedVouchers: string[] = [];
                vouchers.forEach((voucher) => {
                    vouchersResult[voucher].forEach((voucherResult: string) => {
                        exchangedVouchers.push(voucherResult);
                    });
                });
                return exchangedVouchers;
            })
        );
    }
}