<div class="grid">
    <div class="row">
        <div class="col-8">
            <div class="row">
                <div class="col-12">
                    <h2>{{ 'DOWNLOAD_APP.title' | translate }}</h2>
                    <h2 class="secondary">{{ 'DOWNLOAD_APP.sub_title' | translate}}</h2>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <p>
                        {{ 'DOWNLOAD_APP.text' | translate}}
                    </p>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <a href="{{environment.playStoreUrl}}">
                        <img class="store" src="assets/images/play_store.svg" alt="Download at the Google Playstore" />
                    </a>
                    <a href="{{environment.appleStoreUrl}}">
                        <img class="store" src="assets/images/app_store.svg" alt="Download at the Apple App Store" />
                    </a>
                </div>
            </div>
        </div>
        <div class="col-4">
            <img class="phone" src="assets/images/dagjeuit_app.png" alt="Dagjes Uit"/>
        </div>
    </div>

</div>