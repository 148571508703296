import {Injectable} from "@angular/core";
import {StandardEvent} from "../standard.event";
import {ReplaySubject} from "rxjs";
import {OrderOverview} from "../../../models/checkout/order-overview/order-overview.model";

@Injectable()
export class OrderCompleteEvent extends StandardEvent<OrderOverview> {
    constructor() {
        super(new ReplaySubject<OrderOverview>());
    }

    dispatch(orderOverview: OrderOverview): void {
        this.subject.next(orderOverview);
    }
}