import {Injectable} from "@angular/core";
import {StandardEvent} from "../standard.event";
import {ReplaySubject} from "rxjs";

@Injectable()
export class CalendarDateSelectEvent extends StandardEvent<Date> {
    constructor() {
        super(new ReplaySubject<Date>());
    }

    dispatch(calendarDate: Date): void {
        this.subject.next(calendarDate);
    }
}