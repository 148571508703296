import {Injectable} from "@angular/core";
import {EventsDispatcher} from "../../dispatch/events.dispatcher";
import {SendChangeDateEmailEvent} from "./dispatch/events/send-change-date-email.event";
import {SendChangeDateEmailSubscriber} from "./dispatch/subscribers/send-change-date-email.subscriber";

@Injectable()
export class ChangeDateEventRegistry {
    constructor(
        private dispatcher: EventsDispatcher
    ) {
        dispatcher
            .register(SendChangeDateEmailEvent, [SendChangeDateEmailSubscriber]);
    }
}