import {Injectable} from "@angular/core";
import {SubscriberContract} from "../../../../dispatch/contracts/subscriber.contract";
import {PlusProvider} from "../../plus.provider";
import {PlusService} from "../../plus.service";
import {tap} from "rxjs/operators";
import {StorageService} from "../../../../services/storage.service";
import {PlusWallet} from "../../models/plus-wallet.model";
import {Reservation} from "../../../../models/checkout/reservation.model";

@Injectable()
export class GetPlusWalletSubscriber implements SubscriberContract<PlusWallet> {
    constructor(
        private plusProvider: PlusProvider,
        private plusService: PlusService,
        private storageService: StorageService,
    ) {}

    async handle(): Promise<PlusWallet> {
        return this.plusService.getWallet().pipe(
            tap((wallet) => {
                this.plusProvider.wallet().next(wallet);
            })
        ).toPromise();
    }
}
