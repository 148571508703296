import {Component, Input, OnInit} from "@angular/core";
import {tap} from "rxjs/operators";
import {UiService} from "../../../../services/ui.service";

@Component({
    selector: 'qup-how-to-delete',
    templateUrl: '../../templates/how-to-delete/how-to-delete.component.html',
    styleUrls: ['../../templates/how-to-delete/how-to-delete.component.scss']
})
export class HowToDeleteComponent implements OnInit {
    @Input('isMobile') isMobile = false;

    constructor(private uiService: UiService) {

    }

    ngOnInit(): void {
        this.uiService.isMobileOverlay$
            .pipe(
                tap((isMobile) => {
                    this.isMobile = isMobile;
                })
            ).subscribe();
    }
}