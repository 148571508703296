import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from "@angular/router";
import {Observable, of} from "rxjs";
import {Location} from "@angular/common";
import {StorageNS, StorageService} from "../services/storage.service";

@Injectable({providedIn: 'root'})
export class PlusResolver implements Resolve<string | null> {
    constructor(
        private router: Router,
        private storageService: StorageService,
        private location: Location
    ) {}
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<string | null> {
        let queryParams = Object.assign({}, state.root.queryParams);

        const plusToken = queryParams["token"];
        if (plusToken) {
            this.storageService.setItem(StorageNS.PLUS_TOKEN, plusToken);
            delete queryParams.token;
            this.router.navigate([this.location.path().split('?')[0]], { queryParams: queryParams});
            return of(plusToken);
        } else {
            return of(null);
        }

    }
}