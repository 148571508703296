import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from "@angular/router";
import {PlatformService} from "../services/platform.service";
import {Observable, of} from "rxjs";
import {environment as env } from '../../environments/environment';
import {map} from "rxjs/operators";
import {Location} from "@angular/common";

@Injectable({ providedIn: 'root'})
export class AuthResolver implements Resolve<string> {
    constructor(
        private platformService: PlatformService,
        private router: Router,
        private location: Location
    ) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<string> {

        // Loyalty implementation.
        const queryParamToken = state.root.queryParams["id_token"];
        if (queryParamToken) {
            localStorage.setItem("token", queryParamToken);
            this.router.navigate([this.location.path().split('?')[0]]);
            return of(queryParamToken);
        }

        // Platform token.
        const authToken = localStorage.getItem("token");
        if (authToken) {
            return of(authToken);
        }

        // Retrieve new token, and set this in storage.
        return this.platformService.getSessionToken(env.campaignUuid).pipe(map((data) => {
            localStorage.setItem("token", data.response.token);
            return data.response.token;
        }));
    }
}