import {Product} from "../content-partner/product.model";

export class SelectedProduct {
    constructor(
        private _product: Product,
        private _quantity: number
    ) {
    }

    public product(): Product {
        return this._product;
    }

    public quantity(): number {
        return this._quantity;
    }

    public productDate(): Date {
        return this._product.productDate()!;
    }

    public productUuid(): string {
        return this._product.productUuid();
    }
}