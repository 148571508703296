import {Injectable} from "@angular/core";
import {StandardEvent} from "../standard.event";
import {ReplaySubject} from "rxjs";
import {Product} from "../../../models/content-partner/product.model";

@Injectable()
export class ProductsLoadedEvent extends StandardEvent<Product[]> {
    constructor() {
        super(new ReplaySubject<Product[]>());
    }

    dispatch(products: Product[]): void {
        this.subject.next(products);
    }
}