<div class="container mb-4 p-0">
  <ng-container *ngIf="contentPartner">

    <div class="row p-0 m-0 container_box">
      <div class="{{!embedded && !isMobile ? 'col-md-6' : 'col-md-12'}}" *ngIf="!embedded && !isMobile">
        <qup-attraction-banner [embedded]="embedded" [contentPartner]="contentPartner" [isMobile]="isMobile" [location]="selectedLocation"></qup-attraction-banner>
        <div class="row">
          <div class="col-12">
            <div  *ngIf="selectedLocation" class="row {{!embedded && !isMobile ? 'pl-1 pr-1' : ''}}">
              <qup-attraction-detail [location]="selectedLocation" [contentPartner]="contentPartner" [isMobile]="isMobile"></qup-attraction-detail>
            </div>
          </div>
        </div>
      </div>
      <div class="{{!embedded && !isMobile ? 'col-md-6' : 'col-md-12 mt-4'}} p-0 m-0 container_box secondary">
          <div class="attraction_title text-center" *ngIf="!embedded && isMobile">
             <h3>{{contentPartner?.caption()}}</h3>
          </div>
          <div class="attraction_title text-center" *ngIf="!embedded && !isMobile">
              <h4>Tickets kopen</h4>
          </div>
          <qup-attraction-banner *ngIf="isMobile" [embedded]="embedded" [contentPartner]="contentPartner" [isMobile]="isMobile" [location]="selectedLocation"></qup-attraction-banner>
        <qup-select-location *ngIf="contentPartner.locations().length > 1"></qup-select-location>

          <div *ngIf="selectedLocation" class="row m-0 p-2 calendar-container">

              <div class="login_container mb-4" *ngIf="!isLoggedIn">
                  <div class="row">
                      <div class="col-12">
                          <p>
                              {{'LOGIN.login_text_1' | translate}}
                          </p>
                          <p class="mt-2">
                              {{'LOGIN.login_text_2' | translate}}
                          </p>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-12 login-row">
                          <button
                                  class="btn btn-lg login-button"
                                  (click)="triggerLogin()">
                              {{'LOGIN.login_button' | translate}}
                          </button>
                      </div>
                  </div>
              </div>

            <qup-calendar-v2 *ngIf="isLoggedIn" [contentPartner]="contentPartner" [mobile]="isMobile" [embedded]="embedded" class="col-12"></qup-calendar-v2>
            <qup-ticket-dropdown [mobile]="isMobile" *ngIf="isLoggedIn" class="col-12" #ticketSelectComponent></qup-ticket-dropdown>

            <qup-timeslots-component class="col-12"></qup-timeslots-component>

            <qup-discount-component class="col-12" *ngIf="checkoutConditionsMet"></qup-discount-component>

            <div class="row w-100 p-0 m-0 mt-2" *ngIf="isLoggedIn">
              <button type="button" [disabled]="!checkoutConditionsMet" class="btn btn-primary ml-auto" (click)="submitCheckout()">
                <span>{{'ATTRACTION_SPECIFIC.next' | translate}}</span>
              </button>
            </div>
          </div>
      </div>
        <div class="{{!embedded && !isMobile ? 'col-md-6' : 'col-md-12'}}" *ngIf="!embedded && isMobile">
            <div class="row mb-4">
                <div class="col-12">
                    <div  *ngIf="selectedLocation" class="row {{!embedded && !isMobile ? 'pl-1 pr-1' : ''}}">
                        <qup-attraction-detail [location]="selectedLocation" [contentPartner]="contentPartner" [isMobile]="isMobile"></qup-attraction-detail>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <qup-visit-guarantee [visitGuaranteeAmount]="visitGuaranteeAmount" *ngIf="displayVisitGuaranteePopup" #visitGuaranteePopup></qup-visit-guarantee>
  </ng-container>
</div>
