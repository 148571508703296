import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {environment as env} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {AvailableTimeslots} from "../models/timeslots/available-timeslots.model";
import {TimeslotReserveResponse, TimeSlotResponseEnvelope} from "../interfaces/timeslot.interface";
import {Timeslot} from "../models/timeslots/timeslot";
import {ReserveTimeslot} from "../models/timeslots/reserve-timeslot.model";

const URLS = {
    getTimeslotsEndpoint: () => `${env.contentApiUri}/timeslots/availability/`,
    reserveTimeslotsEndpoint: () => `${env.contentApiUri}/timeslots/reserve`
};

@Injectable({
    providedIn: 'root'
})

export class TimeslotsService {

    constructor(
        private http: HttpClient,
    ) {
    }

    mapTimeslots(timeslotsResponse: TimeSlotResponseEnvelope): AvailableTimeslots {

        let timeslots: Timeslot[] = [];

        timeslotsResponse.data.forEach((entry) => {
            let timeslot = new Timeslot(
                entry.available_stock,
                entry.max_stock,
                entry.from,
                entry.to,
                entry.name
            );
            timeslots.push(timeslot);
        });

        return new AvailableTimeslots(
            timeslots
        );
    }


    /**
     * Get Timeslots
     */

    getAvailableTimeslots(orderReservationUuid: string): Observable<AvailableTimeslots> {
        return this.http.get<TimeSlotResponseEnvelope>(URLS.getTimeslotsEndpoint() + orderReservationUuid).pipe(
            map((res) => {
                return this.mapTimeslots(res);
            })
        );
    }

    /**
     * Reserve timeslot
     */

    mapReserveTimeslot(timeslotReserveResponse: TimeslotReserveResponse): ReserveTimeslot {
        return new ReserveTimeslot(
            timeslotReserveResponse.TimeslotUuid,
            timeslotReserveResponse.Timeslot,
            timeslotReserveResponse.ReservationAmount,
            timeslotReserveResponse.Time
        );
    }


    reserveTimeslot(timeslot: string, orderReservationId: string): Observable<ReserveTimeslot> {
        return this.http.post<TimeslotReserveResponse>(URLS.reserveTimeslotsEndpoint(), JSON.stringify({
            timeslot: timeslot,
            order_reservation_id: orderReservationId,
        })).pipe(
            map((res) => {
                return this.mapReserveTimeslot(res);
            })
        );
    }
}
