<div class="container custom-responsive-container">
  <qup-attractions-toolbar (seeMapClicked)="goToMapPage()"></qup-attractions-toolbar>

<!--  <header class="section-head mt-4 mb-4" *ngIf="filteredProducts.length > 0">-->
<!--    <h2 class="section-head-title">{{'ATTRACTIONS_MAIN.title' | translate}}</h2>-->
<!--  </header>-->
  <div class="row flex-wrap-mobile">
      <qup-attraction-card [mobileFullWidth]="true" [selectable]="true" class="col-md-3 p-1 justify-center" *ngFor="let product of filteredProducts" [product]="product" [enableHeart]="false"></qup-attraction-card>
  </div>
  <div class="row">
    <div class="col-12 pt-4 text-center" *ngIf="filteredProducts && filteredProducts.length > 0">
      <qup-paginator [total]="filteredProducts.length" [index]="index" (PageChange)="onPageChange($event)"></qup-paginator>
    </div>
  </div>
  <div class="container no-results mb-16 mt-8" style="text-align: center" *ngIf="filteredProducts.length <= 0">
      <div class="row">
        <div class="col-12">
          <span>Helaas kunnen wij met deze zoekopdracht niets vinden!</span>
        </div>
      </div>
      <div class="row second-line">
        <div class="col-12">
          <span>Probeer het met een andere zoekterm nogmaals!</span>
        </div>
      </div>

  </div>
</div>
