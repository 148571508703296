import {Injectable} from "@angular/core";
import {ReplaySubject} from "rxjs";
import {StandardEvent} from "../standard.event";
import {SelectedProduct} from "../../../models/checkout/selected-product.model";

@Injectable()
export class QuantityChangeEvent extends StandardEvent<SelectedProduct[], Map<string, SelectedProduct>> {

    constructor() {
        super(new ReplaySubject<SelectedProduct[]>());
    }

    dispatch(products: Map<string, SelectedProduct>): void {
        let flattenedProducts: SelectedProduct[] = [];
        products.forEach((product) => {

            const productQuantity = product.quantity();
            if (productQuantity && productQuantity > 0) {
                flattenedProducts.push(product);
            }
        });

        this.subject.next(flattenedProducts);
    }
}