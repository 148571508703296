import {Component, OnDestroy, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SelectLocationEvent} from "../../../dispatch/events/content-partner/select-location.event";
import {Location} from "../../../models/content-partner/location.model";
import {CheckoutProvider} from "../../../providers/checkout.provider";
import {Subscription} from "rxjs";

@Component({
    selector: 'qup-select-location',
    templateUrl: '../../../templates/checkout/select-location/select-location.component.html',
    styleUrls: ['../../../templates/checkout/select-location/select-location.component.scss']
})

export class SelectLocationComponent implements OnInit, OnDestroy {

    private _subs: Subscription[] = [];

    constructor(
        private fb: FormBuilder,
        private selectLocationEvent: SelectLocationEvent,
        private checkoutProvider: CheckoutProvider
    ) {
    }

    public locations: Location[] = [];
    form!: FormGroup;

    ngOnInit(): void {
        this.form = this.fb.group({location: ['']});
        this._subs.push(
            this.checkoutProvider.locations().subscribe((data) => this.locations = data)
        );
    }

    changeSelectedLocation(selectedLocation: Location): void {
        this.selectLocationEvent.dispatch(selectedLocation);
    }

    ngOnDestroy(): void {
        this._subs.forEach((s) => s?.unsubscribe());
    }
}