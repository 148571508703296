import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomNgSelectFilterDirective } from './custom-ng-select-filter.directive';

@NgModule({
  declarations: [CustomNgSelectFilterDirective],
  imports: [
    CommonModule
  ],
  exports: [ CustomNgSelectFilterDirective]
})
export class CustomNgSelectDirectiveModule { }
