import {RouterModule, Routes} from "@angular/router";
import {ChangeDateComponent} from "./components/change-date.component";
import {NgModule} from "@angular/core";

const routes: Routes = [
    {
        path: '',
        component: ChangeDateComponent
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class ChangeDateRoutingModule { }