import {Injectable} from "@angular/core";
import {StandardEvent} from "../standard.event";
import {ReplaySubject} from "rxjs";
import {Location} from "../../../models/content-partner/location.model";

@Injectable()
export class SelectLocationEvent extends StandardEvent<Location> {
    constructor() {
        super(new ReplaySubject<Location>());
    }

    dispatch(location: Location): void {
        this.subject.next(location);
    }
}