import {ModuleWithProviders, NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../../components/shared/shared.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AnalyticsEventRegistry} from "./analytics-event.registry";
import {EventsDispatcher} from "../../dispatch/events.dispatcher";
import {AnalyticsProvider} from "./analytics-provider";
import {GoogleAnalyticsGtmService} from "./services/google-analytics-gtm.service";
import {CheckoutProvider} from "../../providers/checkout.provider";
import {InitializeCheckoutSubscriber} from "./dispatch/subscribers/initialize-checkout.subscriber";
import {FinalizeCheckoutSubscriber} from "./dispatch/subscribers/finalize-checkout.subscriber";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule
    ],
    providers: [
        GoogleAnalyticsGtmService,
        InitializeCheckoutSubscriber,
        FinalizeCheckoutSubscriber
    ],
    declarations: [

    ],
    exports: [

    ]
})

export class AnalyticsModule {
    static forRoot(): ModuleWithProviders<AnalyticsModule> {
        return {
            ngModule: AnalyticsModule,
            providers: [
                {
                    provide: AnalyticsEventRegistry,
                    useFactory: LoadAnalyticsEventRegistry,
                    deps: [EventsDispatcher]
                },
                {
                    provide: AnalyticsProvider,
                    useFactory: LoadAnalyticsProvider,
                    deps: [GoogleAnalyticsGtmService, CheckoutProvider]
                }
            ]
        };
    }
}

export function LoadAnalyticsEventRegistry(dispatcher: EventsDispatcher): any {
    return new AnalyticsEventRegistry(dispatcher);
}

export function LoadAnalyticsProvider(googleAnalyticsGtmService: GoogleAnalyticsGtmService, checkoutProvider: CheckoutProvider): any {
    return new AnalyticsProvider(googleAnalyticsGtmService, checkoutProvider);
}