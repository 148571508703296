<div class="discount-message container-fluid card mb-4" *ngIf="this.products.length && lastTicketWarning">
    <h4>Let op, laatste kaartjes!</h4>
    <div class="row">
        <div class="col-12">
            Kaartjes met deze korting zijn bijna uitverkocht, wees er snel bij!
        </div>
    </div>
</div>
<div class="ticket-select container-fluid card mb-4" *ngIf="this.products.length">
    <h3 class="primary">{{"CHECKOUT.tickets_container_title" | translate}}</h3>
    <h3 class="secondary">{{"CHECKOUT.tickets_container_payoff" | translate}}</h3>
    <div class="row align-items-center" *ngFor="let product of products">
        <div class="col-md-12">
            <div class="row product">
                <div class="col-12 product-title">
                    <strong>{{product.productName()}}</strong>
                </div>
            </div>
            <div class="row">
                <div class="{{isMobile ? 'col-12' : 'col-8'}} quantity">
                    <div class="row">
                        <div class="col-12 prices">
                            <span class="strike-through">&euro;{{product.listPrice() | number:'1.2-2'}}</span>
                            <span class="effective-price">&euro;{{product.effectivePrice() | number:'1.2-2'}}</span>
                            <span class="ticket-label">{{"CHECKOUT.tickets_ticket_label" | translate}}</span>
                        </div>
                    </div>
                </div>
                <div class="{{isMobile ? 'col-6' : 'col-2'}}">
                    <div class="quantity-container d-flex justify-content-start">
                        <img src="assets/images/amount-icon.png" alt="" class="amount-icon">
                        <span>{{reservationProducts.getValue().get(product.productUuid())?.quantity() ?? 0}}</span>
                    </div>
                </div>
                <div class="{{isMobile ? 'col-6' : 'col-2'}}">
                    <div class="buttons-container d-flex justify-content-end">
                        <div class="mr-2">
                            <button class="btn cart-button remove-item {{(reservationProducts.getValue().get(product.productUuid())?.quantity() ?? 0) === 0 ? 'disabled' : ''}}" (click)="decreaseQuantity(product)"></button>
                        </div>
                        <div>
                            <button class="btn cart-button add-item {{(reservationProducts.getValue().get(product.productUuid())?.quantity() ?? 0) === maxSelectableQuantity ? 'disabled' : ''}}" (click)="increaseQuantity(product)"></button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>