import {AppService} from '../../services/app.service';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {environment as env} from "../../../environments/environment";
import {UiService} from 'src/app/services/ui.service';
import {filter, skipWhile, tap} from 'rxjs/operators';
import {BehaviorSubject, Subscription} from 'rxjs';
import {CheckoutProvider} from "../../providers/checkout.provider";
import {CheckoutSubmitEvent} from "../../dispatch/events/checkout/checkout-submit.event";
import {CheckoutEnteredEvent} from "../../dispatch/events/checkout/checkout-entered.event";
import {CheckoutContext} from "../../models/checkout/checkout-context.model";
import {ContentPartner} from "../../models/content-partner/content-partner.model";
import {Location} from "../../models/content-partner/location.model";
import {DateTime} from "luxon";
import {CognitoService} from "../../services/cognito.service";

@Component({
    selector: 'qup-attraction-specific',
    templateUrl: '../../templates/attraction/attraction.component.html',
    styleUrls: ['../../templates/attraction/attraction.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class AttractionComponent implements OnInit, OnDestroy, AfterViewInit {

    private _subs: Subscription[] = [];
    isMobile!: boolean;

    selectedLocation: Location | null = null;
    checkoutConditionsMet = false;
    contentPartner: ContentPartner|null = null;
    hasVisitGuarantee = false;
    displayVisitGuaranteePopup = false;

    visitGuaranteeAmount = new BehaviorSubject<number>(0);
    selectedDate = new BehaviorSubject<string|null>(null);

    isLoggedIn: boolean = false;

    embedded: boolean = false;

    constructor(
        public router: Router,
        private route: ActivatedRoute,
        private uiService: UiService,
        private changeDetectorRef: ChangeDetectorRef,
        private appService: AppService,
        private checkoutProvider: CheckoutProvider,
        private checkoutSubmitEvent: CheckoutSubmitEvent,
        private checkoutEnteredEvent: CheckoutEnteredEvent,
        private activatedRoute: ActivatedRoute,
        private cognitoProvider: CognitoService
    ) {

    }

    ngAfterViewInit(): void {

        this.activatedRoute.queryParams.subscribe((queryParams) => {
            if (queryParams.embedded) {
                this.embedded = true;
            }
        });

        this.route.params.subscribe((data) => {
            if (!data['attractionId']) {
                throw new Error('attractionId is not set in the path');
            }

            this.checkoutEnteredEvent.dispatch(new CheckoutContext(
                this.appService.currentLanguage,
                env.campaignUuid,
                data['attractionId'] as string
            ));
        });


        window.scrollTo(0,0);
    }

    ngOnInit(): void {

        this.selectedDate.next(null);

        this._subs.push(
            this.uiService.isMobileOverlay$
                .pipe(
                    tap((isMobile) => {
                        this.isMobile = isMobile;
                        this.changeDetectorRef.detectChanges();
                    })
                ).subscribe(),
            this.cognitoProvider.isLoggedIn().subscribe((isLoggedIn) => {
                this.isLoggedIn = isLoggedIn;
            }),
            this.checkoutProvider.contentPartner().pipe(filter((cp) => {
                return cp.alias() === this.route.snapshot.params['attractionId'];
            })).subscribe((contentPartner) => {
                this.contentPartner = contentPartner;
            }),
            this.checkoutProvider.selectedLocation().pipe(skipWhile(val => this.contentPartner === null)).subscribe((location) => {
                this.selectedLocation = location;
            }),
            this.checkoutProvider.reservation().subscribe((reservation) => {
                if (reservation == null) {
                    this.hasVisitGuarantee = false;
                    return;
                }

                this.hasVisitGuarantee = reservation.hasVisitGuarantee();
                this.visitGuaranteeAmount.next(reservation.visitGuaranteeCosts());
                this.selectedDate.next(DateTime.fromJSDate(reservation.visitDate()).toISODate());
            }),
            this.checkoutProvider.isCheckoutConditionsMet().subscribe((value) => {
                this.checkoutConditionsMet = value;
            }),
        );
    }


    ngOnDestroy(): void {
        this._subs.forEach((s) => s?.unsubscribe());
    }

    submitCheckout(): void {
        this.checkoutSubmitEvent.dispatch();
    }

    goBack() {
        this.router.navigate(['/']);
    }

    triggerLogin(): void {
        if (env.federatedLogin) {
            this.cognitoProvider.startLogin(env.cognitoProvider);
        } else {
            this.router.navigate(['account/login']);
        }
    }
}
