import {CheckoutProvider} from "../../../providers/checkout.provider";
import {Injectable} from "@angular/core";
import {SubscriberContract} from "../../contracts/subscriber.contract";
import {Timeslot} from "../../../models/timeslots/timeslot";
import {of} from "rxjs";

@Injectable()
export class SelectTimeslotSubscriber implements SubscriberContract<Timeslot> {
    constructor(private checkoutProvider: CheckoutProvider) {}

    async handle(event: Timeslot): Promise<Timeslot> {
        this.checkoutProvider.selectedTimeslot().next(event);
        return of(event).toPromise();
    }
}