import {NgxSpinnerService} from "ngx-spinner";
import {Injectable} from "@angular/core";
import {CoreProvider} from "../providers/core.provider";

@Injectable()
export class LoadingStateService {
    constructor(
        private spinnerService: NgxSpinnerService,
        private coreProvider: CoreProvider
    ) {

    }

    start(name?: string): void {
        this.spinnerService.show(name);
        setTimeout(() => {
            this.spinnerService.hide();
            this.coreProvider.changeDetector().next();
        }, 3000);
    }

    end(name?: string): void {
        this.spinnerService.hide();

        setTimeout(() => {
            this.coreProvider.changeDetector().next();
        }, 10);
    }
}