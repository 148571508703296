import {Injectable, ViewContainerRef} from "@angular/core";
import {StandardEvent} from "../standard.event";
import {ReplaySubject} from "rxjs";

@Injectable()
export class OrderOverviewGeneratedEvent extends StandardEvent<ViewContainerRef> {
    constructor() {
        super(new ReplaySubject<ViewContainerRef>());
    }

    dispatch(ref: ViewContainerRef): void {
        this.subject.next(ref);
    }
}