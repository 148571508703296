import { Injectable } from '@angular/core';
import {Observable, of} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {
  GetUserResponse,
  PlaceLoyaltyReservationRequest,
  PlaceReservationResponse
} from "../interfaces/loyalty.interface";
import {environment as env} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {LoyaltyReservation} from "../models/loyalty/loyalty-reservation.model";
import {RAISE_ERROR} from "../dispatch/events.registry";

const URLS = {
  getUserEndpoint: () => `${env.loyaltyUri}/user`,
  placeReservationEndpoint: () => `${env.loyaltyUri}/reservations`,
}

@Injectable({
  providedIn: 'root'
})

export class LoyaltyService {

  constructor(
      private http: HttpClient,
  ) { }

  /**
   * Get loyalty user information
   */

  getUser(): Observable<GetUserResponse>{
    return this.http.get<GetUserResponse>(URLS.getUserEndpoint());
  }

  /**
   * Place loyalty reservation
   */

  placeReservation(request: PlaceLoyaltyReservationRequest): Observable<LoyaltyReservation|null> {
    return this.http.post<PlaceReservationResponse>(URLS.placeReservationEndpoint(), request).pipe(
        catchError((err) => {
            if (err.status == 416) {
              RAISE_ERROR("Je hebt niet genoeg punten voor korting!");
            }

            return of(null);
        }),
        map((res) => {
          if (res == null) return res;
          return new LoyaltyReservation(
              res.data.reservation_id,
              res.data.balance,
              res.data.effective_balance,
              res.data.voucher_session_uuid,
              res.data.amount,
              res.data.conversion_ratio,
              res.data.application_type
          );
        })
    );
  }
}
