import {Injectable} from "@angular/core";
import {CheckoutProvider} from "../../../providers/checkout.provider";
import {SubscriberContract} from "../../contracts/subscriber.contract";
import {Order} from "../../../models/checkout/order.model";
import {CreateOrderEventPayload} from "../../../interfaces/payment.interface";

@Injectable()
export class CreateIngenicoOrderSubscriber implements SubscriberContract<Order> {
    constructor(private checkoutProvider: CheckoutProvider) {}

    async handle(payload: CreateOrderEventPayload): Promise<Order> {
        return this.checkoutProvider.createDucOrder(payload.billingDetails, payload.orderReservation).toPromise();
    }
}