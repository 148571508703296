import { DynamicLinkService } from './../../services/dynamic-link.service';
import { Subscription } from 'rxjs';
import { AppService } from './../../services/app.service';
import {Component, OnInit, OnDestroy, Input} from '@angular/core';

interface FooterLinkItem {
  href?: string;
  // router link
  link?: string[];
  label: string;
}

@Component({
  selector: 'qup-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  _subs: Subscription[] = [];

  @Input('isMobile') isMobile = false;
  footerLinks!: FooterLinkItem[][];

  constructor(private appS: AppService, private dLS: DynamicLinkService) {}

  generateFooter(): void {
    this.footerLinks = [[
      {
        label: 'FOOTER.how_does_it_work',
        link: ['/', 'how-it-works'],
      },
      {
        label: 'FOOTER.campaign_conditions',
        href: this.dLS.campaignDoc,
      },
      {
        label: 'FOOTER.general_conditions',
        href: this.dLS.generalDoc,
      },
      {
        label: 'FOOTER.privacy_statement',
        href: this.dLS.privacyDoc,
      }
      ],
      [
      {
        label: 'FOOTER.return_policy',
        href: this.dLS.retourDoc,
      },
      {
        label: 'FOOTER.complaints',
        href: this.dLS.complaintDoc,
      },
      {
        label: 'FOOTER.cookie_statement',
        href: this.dLS.cookiesDoc,
      },
      {
        label: 'FOOTER.faq',
        href: this.dLS.faqDoc,
      }]
    ];
  }

  ngOnInit(): void {
    this._subs.push(
      this.appS.currentLanguage$.subscribe((_) => {
        this.generateFooter();
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.forEach((s) => s?.unsubscribe());
  }
}
