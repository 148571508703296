import {Component, Input, ViewEncapsulation} from '@angular/core';
import {ProductTab} from "../../../attractions/enums/product-tab.enum";
import {ContentPartner} from "../../../models/content-partner/content-partner.model";
import {Location} from "../../../models/content-partner/location.model";
import {Image} from "../../../models/content-partner/image.model";


@Component({
    selector: 'qup-attraction-detail',
    templateUrl: '../../../templates/attraction/detail/attraction-detail.component.html',
    styleUrls: ['../../../templates/attraction/detail/attraction-detail.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class AttractionDetailComponent {

    constructor() { }

    public selectedImage!: Image;
    public activatedSection: ProductTab = ProductTab.PRICE;
    _contentPartner!: ContentPartner;
    _images: Image[] = [];
    pTabEnum = ProductTab;
    multipleLocations = false;

    @Input() location!: Location | null;
    @Input() isMobile = true;
    @Input() set contentPartner(d: ContentPartner) {
        this._contentPartner = d;
        this.multipleLocations = !!d?.locations()?.length;
    }
}