import {UiService} from 'src/app/services/ui.service';
import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AttractionsToolbarComponent} from '../../../attractions/attractions-toolbar/attractions-toolbar.component';
import {Router} from '@angular/router';
import {ContentPartner} from "../../../models/content-partner/content-partner.model";
import {AttractionProvider} from "../../../providers/attraction.provider";
import {Subscription} from "rxjs";
import {FilterProductByCategoryCityEvent} from "../../../dispatch/events/attractions-main/filter-product-by-category-city-event";
import {FiltersInput} from "../../../interfaces/content-api.interface";
import {environment} from "../../../../environments/environment";

interface AttractionLocalFilters {
    cityFilter: string[];
    categoryFilter: string[];
    sortBy: string;
}

@Component({
    selector: 'qup-main',
    templateUrl: '../../../templates/attractions/main/main.component.html',
    styleUrls: ['../../../templates/attractions/main/main.component.scss'],
})
export class MainComponent implements AfterViewInit, OnDestroy {
    @ViewChild(AttractionsToolbarComponent) toolbar!: AttractionsToolbarComponent;
    _subs: Subscription[] = [];
    public filteredProducts: ContentPartner[] = [];
    public index = 1;
    localFilters: AttractionLocalFilters = {cityFilter: [], categoryFilter: [], sortBy: ''};

    constructor(
        private uiService: UiService,
        private router: Router,
        private filterProductsByCategoryCityEvent: FilterProductByCategoryCityEvent,
        private attractionMainProvider: AttractionProvider
    ) {
    }
    ngAfterViewInit(): void {
        this._subs.push(
            this.attractionMainProvider.contentPartners().subscribe((data) => this.filteredProducts = data),
            this.attractionMainProvider.selectedCategory().subscribe(
                (selectedCategory) => {
                    this.localFilters = {
                        cityFilter: [],
                        categoryFilter: selectedCategory !== null ? [selectedCategory] : [],
                        sortBy: '',
                    };
                    this.filterProducts();
                }
            )
        );
    }

    onPageChange(event: any): void {
        this.index = event.index;
        this.filteredProducts = this.getPartialData(event.pageSize, event.index - 1);
    }

    getPartialData(pageSize: number, index: number): any {
        return this.filteredProducts.slice(
            index * pageSize,
            index * pageSize + pageSize
        );
    }

    filterProducts(): void {
        const {cityFilter, categoryFilter, sortBy} = this.localFilters;

        let filters: FiltersInput = {categories: categoryFilter, cities: cityFilter, sortBy: sortBy};
        this.filterProductsByCategoryCityEvent.dispatch(filters);
    }

    goToMapPage(): void {
        this.router.navigate([environment.attractionsRoutingPathName, 'map']);
    }

    ngOnDestroy(): void {
        this.uiService.resetSearchForm();
        this._subs.forEach(s => s?.unsubscribe());
    }
}
