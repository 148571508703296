import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {ToggleVisitGuaranteeEvent} from "../../../dispatch/events/checkout/toggle-visit-guarantee.event";
import {CheckoutSubmitEvent} from "../../../dispatch/events/checkout/checkout-submit.event";
import {BehaviorSubject, Subscription} from "rxjs";


@Component({
    selector: 'qup-visit-guarantee',
    templateUrl: '../../../templates/checkout/visit-guarantee/visit-guarantee.component.html',
    styleUrls: ['../../../templates/checkout/visit-guarantee/visit-guarantee.component.scss'],
})


export class VisitGuaranteeComponent implements OnInit, OnDestroy {

    @Input() visitGuaranteeAmount: BehaviorSubject<number> | undefined;

    visitGuaranteeCosts = 0;

    private _subs: Subscription[] = [];

    constructor(
        private toggleVisitGuaranteeEvent: ToggleVisitGuaranteeEvent,
        private checkoutSubmitEvent: CheckoutSubmitEvent
    ) {
    }

    ngOnInit(): void {

        if (this.visitGuaranteeAmount == undefined) {
            return;
        }

        this._subs.push(
            this.visitGuaranteeAmount.subscribe((amount) => {
                this.visitGuaranteeCosts = amount;
            })
        );
    }

    toggleVisitGuarantee(changeEvent: Event): void {
        const target = changeEvent.target as HTMLInputElement;
        this.toggleVisitGuaranteeEvent.dispatch(target.checked);
    }

    closePopup(): void {
    }

    submitCheckout(): void {
        this.checkoutSubmitEvent.dispatch();
    }

    ngOnDestroy(): void {
        this._subs.forEach((sub) => sub.unsubscribe());
    }
}