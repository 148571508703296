import {CheckoutProvider} from "../../../providers/checkout.provider";
import {Injectable} from "@angular/core";
import {SubscriberContract} from "../../contracts/subscriber.contract";

@Injectable()
export class CheckCheckoutConditionsSubscriber implements SubscriberContract<any> {
    constructor(private checkoutProvider: CheckoutProvider) {}

    async handle(): Promise<void> {
        this.checkoutProvider.checkoutConditionsMet().subscribe((res) => {
            this.checkoutProvider.isCheckoutConditionsMet().next(
                res.reservationTimeSlotsSelected && res.reservationGrandTotalAboveZero
            );
        })
    }
}