import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {AddPlusVoucherResponse, GetPlusWalletResponse} from "../../interfaces/plus.interface";
import {environment} from "../../../environments/environment";
import {PlusWalletVoucher} from "./models/get-plus-wallet.model";
import {AddPlusVoucher} from "./models/add-plus-voucher.model";
import {StorageService} from "../../services/storage.service";
import {PlusWallet} from "./models/plus-wallet.model";
import {flatMap} from "rxjs/internal/operators";
import {CognitoService} from "../../services/cognito.service";


@Injectable({
    providedIn: 'root'
})

export class PlusService {

    constructor(
        private http: HttpClient,
        private storageService: StorageService,
        private cognitoService: CognitoService
    ) {
    }

    get purchaserSub(): string {
        const plusToken = this.storageService.plusToken;
        let decodedToken = '';

        if (plusToken != null) {
            decodedToken = JSON.parse(atob(plusToken.split('.')[1]))["sub"];
        }

        return decodedToken;
    }


    /**
    Get wallet
     */

    mapPlusWallet(response: GetPlusWalletResponse): PlusWalletVoucher {
        return new PlusWalletVoucher(
          response.Coin,
          response.PlusRewardVoucher,
          response.Value,
          response.IsShareable,
          response.IsUsed,
          response.IsShared
        );
    }



    getWallet(): Observable<PlusWallet> {
        let wallet: PlusWalletVoucher[] = [];

        return this.cognitoService.getAccessToken().pipe(
            flatMap((accessToken) => {
                let headers = new HttpHeaders(
                    {
                        'skip': 'true',
                        'authorization': accessToken?.toString() ?? '',
                    }
                );

                return this.http.get<GetPlusWalletResponse[]>(`${environment.contentApiUri}/${environment.project.toLowerCase()}/wallet`, {headers: headers}).pipe(
                    map((response) => {
                        response.sort((a, b) => {

                            // Use the unsharable vouchers first.
                            if (a.IsShareable && !b.IsShareable) {
                                return 1;
                            }
                            if (!a.IsShareable && b.IsShareable) {
                                return -1;
                            }

                            // Group them per Plus voucher
                            if (a.PlusRewardVoucher > b.PlusRewardVoucher) {
                                return 1;
                            }
                            if (a.PlusRewardVoucher < b.PlusRewardVoucher) {
                                return -1;
                            }
                            return 0;
                        }).forEach((item) => wallet.push(this.mapPlusWallet(item)));
                        return new PlusWallet(
                            wallet.filter((voucher) => voucher.isUsable()).length,
                            wallet.filter((voucher) => voucher.isUsable())
                        );
                    })
                );
            })
        );

    }

    // getTickets(): Observable<any>


    /**
     * Add voucher
     */

    addVoucher(payload: string): Observable<AddPlusVoucher> {
        return this.cognitoService.getAccessToken().pipe(
            flatMap((accessToken) => {
                let headers = new HttpHeaders(
                    {
                        'skip': 'true',
                        'authorization': accessToken?.toString() ?? '',
                    }
                );

                return this.http.post<AddPlusVoucherResponse>(`${environment.contentApiUri}/${environment.project.toLowerCase()}/wallet/voucher`,
                    {Vouchers: [payload], CampaignId: environment.campaignUuid}, {headers: headers}
                ).pipe(
                    map((res) => new AddPlusVoucher(
                        res.SavedVouchers,
                        res.ExistingVouchers,
                        res.InvalidVouchers
                    ))
                );
            })
        );
    }

}
