<div class="cus-container">
    <div class="text">
      {{ 'COOKIES.banner_description' | translate }}
      <a [href]="cookieStatement" target="_blank" class="read-more">{{'COOKIES.read_more' | translate}}</a>
    </div>

    <div class="actions">
      <button (click)="changePreferenceClick.emit()" type="button"
      class="btn btn-secondary">{{'COOKIES.change_preference' | translate}}</button>
      <button (click)="acceptAllClick.emit()" type="button" class="btn btn-primary">{{'COOKIES.accept_all' | translate}}</button>
    </div>
</div>
