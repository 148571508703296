  <footer class="footer mt-8" role="contentinfo">
    <div class="container-responsive large">
        <div class="row">
            <div class="col-8">
                <ul class="list-unstyled mb-0">
                    <li class="mb-4 pt-2">
                        <ul class="footer-list" *ngFor="let column of footerLinks">
                            <ng-container *ngFor="let fL of column">
                                <li class="footer-list-item">
                                    <ng-container *ngIf="fL.href; else routerLinkTmp">
                                        <a class="footer-list-item-link" target="_blank" [href]="fL.href">{{fL.label | translate}}</a>
                                    </ng-container>
                                    <ng-template #routerLinkTmp>
                                        <a class="footer-list-item-link" [routerLink]="fL.link">{{fL.label | translate}}</a>
                                    </ng-template>
                                </li>
                            </ng-container>
                        </ul>
                    </li>
                </ul>
            </div>
            <div class="col-4">
                <div class="payment-icons mt-2 d-flex justify-content-center">
                    <img src="assets/images/ideal.png" alt="" class="payment-icon mr-2">
                    <img src="assets/images/bancontact.png" alt="" class="payment-icon mr-2">
                    <img src="assets/images/mastercard.png" alt="" class="payment-icon mr-2" >
                    <img src="assets/images/visa.png" alt="" class="payment-icon" style="height:13px;margin-top:0.5rem;">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <ul class="company-information">
                    <li class="footer-info-item mr-4">
                        <a class="size16" style="pointer-events: none;" href="#">{{'FOOTER.company_info_1' |translate}}</a>
                    </li>
                    <li class="footer-info-item mr-4">
                        <a class="size16" style="pointer-events: none;" href="#">{{'FOOTER.company_info_2' |translate}}</a>
                    </li>
                    <li class="footer-info-item mr-4">
                        <a class="size16" style="pointer-events: none;" href="#">{{'FOOTER.company_info_3' |translate}}</a>
                    </li>
                    <li class="footer-info-item mr-4">
                        <a class="size16"
                           [href]="'mailto:' + ('FOOTER.company_info_4' | translate)">{{'FOOTER.company_info_4' |translate}}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
  </footer>
