import {Injectable} from "@angular/core";
import {SubscriberContract} from "../../contracts/subscriber.contract";
import {CheckoutProvider} from "../../../providers/checkout.provider";
import {BillingDetails} from "../../../models/checkout/billing-details.model";
import {of} from "rxjs";
import {Reservation} from "../../../models/checkout/reservation.model";

@Injectable()
export class SetBillingDetailsSubscriber implements SubscriberContract<BillingDetails|null> {
    constructor(private checkoutProvider: CheckoutProvider) {}

    async handle(event: [BillingDetails, Reservation]): Promise<BillingDetails|null> {
        this.checkoutProvider.billingDetails().next(event[0]);
        return of(event[0]).toPromise();
    }
}