import {Injectable} from "@angular/core";
import {ReplaySubject} from "rxjs";
import {StandardEvent} from "../../../../dispatch/events/standard.event";
import {Reservation} from "../../../../models/checkout/reservation.model";

@Injectable()
export class WalletChangeQuantityEvent extends StandardEvent<any[]> {

    constructor() {
        super(new ReplaySubject<any[]>());
    }

    dispatch(cargo: [Reservation, number]): void {
        this.subject.next(cargo);
    }
}