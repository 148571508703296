import {ContentPartner, DiscountStickerType} from "./content-partner.model";
import {Image} from "./image.model";
import {Coordinates} from "./coordinates.model";

export class Location {
    constructor(
        private _uuid: string,
        private _caption: string,
        private _contentPartnerCaption: string,
        private _contentPartnerUuid: string,
        private _effectivePrice: number,
        private _alias: string,
        private _city: string,
        private _description: string,
        private _shortDescription: string,
        private _openingInfo: string,
        private _ticketInfo: string,
        private _website: string,
        private _sortIndex: number,
        private _hasVisitGuarantee: boolean,
        private _showCalendar: boolean,
        private _discountStickerType: DiscountStickerType,
        private _images: Image[],
        private _coordinates: Coordinates,
        private _displayOnMap: boolean
    ) {}

    uuid(): string {
        return this._uuid;
    }

    caption(): string {
        return this._caption;
    }

    contentPartnerCaption(): string {
        return this._contentPartnerCaption;
    }

    alias(): string {
        return this._alias;
    }

    city(): string {
        return this._city;
    }

    description(): string {
        return this._description;
    }

    shortDescription(): string {
        return this._shortDescription;
    }

    openingInfo(): string {
        return this._openingInfo;
    }

    ticketInfo(): string {
        return this._ticketInfo;
    }

    website(): string {
        return this._website;
    }

    hasVisitGuarantee(): boolean {
        return this._hasVisitGuarantee;
    }

    showCalendar(): boolean {
        return this._showCalendar;
    }

    discountStickerType(): DiscountStickerType {
        return this._discountStickerType;
    }

    images(): Image[] {
        return this._images;
    }

    coordinates(): Coordinates {
        return this._coordinates;
    }

    contentPartnerUuid(): string {
        return this._contentPartnerUuid;
    }

    effectivePrice(): number {
        return this._effectivePrice;
    }

    sortIndex(): number {
        return this._sortIndex;
    }

    displayOnMap(): boolean {
        return this._displayOnMap;
    }
}