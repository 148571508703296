import {ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import {catchError, first, tap} from "rxjs/operators";
import {UiService} from "../../../../services/ui.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CognitoService} from "../../../../services/cognito.service";
import {Router} from "@angular/router";
import {Observable, of, Subscription} from "rxjs";
import {environment} from "../../../../../environments/environment";
import {RAISE_ERROR} from "../../../../dispatch/events.registry";
import {TranslateService} from "@ngx-translate/core";
import {ISignUpResult} from "amazon-cognito-identity-js";

enum RegistrationState {
    INITIAL = 1,
    CONFIRMATION = 2,
    DONE = 3
}
@Component({
    selector: 'qup-plus-register',
    templateUrl: '../../templates/register/register.component.html',
    styleUrls: ['../../templates/register/register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {

    isMobile = false;
    _subs: Subscription[] = [];

    registration: FormGroup = this.formBuilder.group({
        password: [null, [Validators.required]],
        repeatPassword: [null, [Validators.required]],
        email: [null, [Validators.required, Validators.email]],
    });

    confirmation: FormGroup = this.formBuilder.group({
        confirmationCode: [null, [Validators.required]],
        email: [null, [Validators.required, Validators.email]],
    });

    registrationState = RegistrationState.INITIAL;
    userName!: string;
    password!: string;

    constructor(
        private uiService: UiService,
        private changeDetectorRef: ChangeDetectorRef,
        private formBuilder: FormBuilder,
        private cognitoProvider: CognitoService,
        private router: Router,
        private translateService: TranslateService
    ) {
    }

    ngOnDestroy(): void {
        this._subs.forEach((sub) => sub.unsubscribe());
    }

    ngOnInit(): void {
        this._subs.push(
            this.uiService.isMobileOverlay$
                .pipe(
                    tap((isMobile) => {
                        this.isMobile = isMobile;
                        this.changeDetectorRef.detectChanges();
                    })
                ).subscribe(),
        );
    }

    submitRegistration():void {
        if (this.registration.get('password')?.value !== this.registration.get('repeatPassword')?.value) {
            this.translateService.get('REGISTRATION.error_password_match').pipe(first()).subscribe(RAISE_ERROR);
            return;
        }

        this.cognitoProvider.register(this.registration.get('email')?.value, this.registration.get('password')?.value).pipe(
            first(),
            catchError((err) => {
                console.log(err);
                throw err;
            })
        ).subscribe((result) => {

            this.userName = this.registration.get('email')?.value;
            this.password = this.registration.get('password')?.value;

            if (!result.userConfirmed) {
                this.registrationState = RegistrationState.CONFIRMATION;
                return;
            }

            this.registrationState = RegistrationState.DONE;
        });
    }

    confirmRegistration(): void {
        this.cognitoProvider.confirmRegistration(this.userName, this.confirmation.get('confirmationCode')?.value)
            .pipe(
                first(),
                catchError((err) => {
                    console.log(err);
                    throw err;
                })
            )
            .subscribe(() => {
                this.cognitoProvider.startNativeLogin(this.userName, this.password).pipe(first()).subscribe(() => {
                    this.router.navigate(['account/home']);
                });
            });
    }

    protected readonly environment = environment;
    protected readonly RegistrationState = RegistrationState;
}