import {Injectable} from "@angular/core";
import {SubscriberContract} from "../../contracts/subscriber.contract";
import {ContentApiService} from "../../../services/content-api.service";
import {AppService} from "../../../services/app.service";
import {environment as env} from "../../../../environments/environment";
import {Campaign} from "../../../models/home/campaign.model";
import {tap} from "rxjs/operators";
import {AttractionProvider} from "../../../providers/attraction.provider";

@Injectable()
export class LoadCampaignDataSubscriber implements SubscriberContract<Campaign> {
    constructor(
        private attractionMainProvider: AttractionProvider,
        private contentService: ContentApiService,
        private appService: AppService
    ) {
    }

    async handle(): Promise<Campaign> {
        return this.contentService.getCampaign(
            env.campaignUuid,
            this.appService.currentLanguage
        ).pipe(
            tap((campaign) => {
                this.attractionMainProvider.campaign().next(campaign);
            })
        ).toPromise();
    }
}