import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { MatButtonModule } from '@angular/material/button';
// import { MatIconModule } from '@angular/material/icon';

import { CarouselComponent } from './carousel.component';
import { CarouselSlideComponent } from './carousel-slide/carousel-slide.component';
import {
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
  HammerModule
} from '@angular/platform-browser';
import {ModuleWithProviders} from "@angular/core";

// https://github.com/angular/angular/issues/10541#issuecomment-300761387
@Injectable()
export class CusCarouselHammerConfig extends HammerGestureConfig {
  overrides = {
    pinch: { enable: false },
    rotate: { enable: false }
  };
}
@NgModule({
  declarations: [CarouselComponent, CarouselSlideComponent],
  imports: [CommonModule, HammerModule],
  exports: [CarouselComponent, CarouselSlideComponent, HammerModule]
})
export class CusCarouselModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: CusCarouselModule,
      providers: [
        { provide: HAMMER_GESTURE_CONFIG, useClass: CusCarouselHammerConfig }
      ]
    };
  }
 }
