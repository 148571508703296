import {ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef} from "@angular/core";
import {SubscriberContract} from "../../../../dispatch/contracts/subscriber.contract";
import {LoyaltyOrderOverviewComponent} from "../../components/loyalty-order-overview.component";

@Injectable()
export class InjectLoyaltyOverviewSubscriber implements SubscriberContract<void> {

    public static currentOverviewComponent: ComponentRef<any>|null = null;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver
    ) {}

    async handle(orderOverview: ViewContainerRef): Promise<void> {
        if (InjectLoyaltyOverviewSubscriber.currentOverviewComponent) {
            InjectLoyaltyOverviewSubscriber.currentOverviewComponent.destroy();
        }

        if (orderOverview == undefined) return;

        let factory = this.componentFactoryResolver.resolveComponentFactory(LoyaltyOrderOverviewComponent);
        InjectLoyaltyOverviewSubscriber.currentOverviewComponent = orderOverview.createComponent(factory);
    }
}