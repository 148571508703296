import {Location} from "./location.model";

export enum DiscountStickerType {
    FIXED_PERCENTAGE = "FIXED_PERCENTAGE",
    FIXED_AMOUNT     = "FIXED_AMOUNT"
}

export class ContentPartner {
    constructor(
        private _uuid: string,
        private _alias: string,
        private _caption: string,

        private _locations: Location[],

        private _shortDescription: string,
        private _headerContent: string,
        private _sortIndex: number,

        private _maxDiscountPercentage: number,
        private _maxDiscountAmount: number,
        private _listPrice: number,
        private _effectivePrice: number,

        private _discountStickerType: DiscountStickerType,

        private _thumbnail: string,
        private _banner: string,
        private _logo: string,
        private _website: string,
        private _cities: string[],

        private _showCalendar: boolean,
        private _visitGuarantee: boolean,
        private _superDeal: boolean,
        private _isFavourite?: boolean

    ) {
    }

    uuid(): string {
        return this._uuid;
    }

    alias(): string {
        return this._alias;
    }

    caption(): string {
        return this._caption;
    }

    locations(): Location[] {
        return this._locations;
    }

    shortDescription(): string {
        return this._shortDescription;
    }

    headerContent(): string {
        return this._headerContent;
    }

    sortIndex(): number {
        return this._sortIndex;
    }

    maxDiscountPercentage(): number {
        return this._maxDiscountPercentage;
    }

    maxDiscountPercentageAsInteger(): number {
        return Math.round(this.maxDiscountPercentage() * 100);
    }

    maxDiscountAmount(): number {
        return this._maxDiscountAmount;
    }

    discountStickerType(): string {
        return this._discountStickerType;
    }

    thumbnail(): string {
        return this._thumbnail;
    }

    banner(): string {
        return this._banner;
    }

    website(): string {
        return this._website;
    }

    cities(): string[] {
        return this._cities;
    }

    showCalendar(): boolean {
        return this._showCalendar;
    }

    visitGuarantee(): boolean {
        return this._visitGuarantee;
    }

    superDeal(): boolean {
        return this._superDeal;
    }

    isFavourite(): boolean | undefined {
        return this._isFavourite;
    }

    effectivePrice(): number {
        return this._effectivePrice;
    }

    listPrice(): number {
        return this._listPrice;
    }

    logo(): string {
        return 'https://assets.queueup.eu/images/x250,png,q90/' + this._logo;
    }
}