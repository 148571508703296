import { NgSelectModule } from '@ng-select/ng-select';
import { CusSelectModule } from '../components/shared/cus-select/cus-select.module';
import { StorageService } from '../services/storage.service';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreRoutingModule } from './core-routing.module';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HttpClientModule } from '@angular/common/http';
import { AppService } from '../services/app.service';
import { UiService } from '../services/ui.service';
import { DialogModule } from './dialog/dialog.module';
import { CookiesComponent } from './dialogs/cookies/cookies.component';
import { ConsentComponent } from './dialogs/consent/consent.component';
import { PreferencesComponent } from './dialogs/preferences/preferences.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CookieStatementComponent } from './cookie-statement/cookie-statement.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SharedModule } from '../components/shared/shared.module';
import { HammerModule} from '@angular/platform-browser';

import { initApp  } from './app.init';
import { CookieService } from '../services/cookie.service';
import {EventsModule} from "../dispatch/events.module";
import {LoyaltyModule} from "../modules/loyalty/loyalty.module";
import {environment} from "../../environments/environment";
import {VoucherModule} from "../modules/vouchers/voucher.module";
import {AnalyticsModule} from "../modules/ga4/analytics-module";
import {ChangeDateModule} from "../modules/change-date/change-date.module";
import {LostTicketsModule} from "../modules/lost-tickets/lost-tickets.module";
import {PlusModule} from "../modules/plus/plus.module";
import {AccountIconComponent} from "./header/account-icon/account-icon.component";
import {PlusRoutingModule} from "../modules/plus/plus-routing.module";
import {AttractionsModule} from "../attractions/attractions.module";
import {CusCarouselModule} from "../components/shared/cus-carousel/cus-carousel.module";
import {NgImageSliderModule} from "ng-image-slider";
import {NewsletterComponent} from "../components/newsletter/newsletter.component";
import {AttractionCardModule} from "../components/shared/attraction-card/attraction-card.module";
import {PromotionComponent} from "../components/promotion/promotion.component";

@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    AccountIconComponent,
    FooterComponent,
    CookiesComponent,
    ConsentComponent,
    PreferencesComponent,
    CookieStatementComponent,
      NewsletterComponent,
      PromotionComponent
  ],
    imports: [
        PlusRoutingModule,
        BrowserAnimationsModule,
        CommonModule,
        CoreRoutingModule,
        HttpClientModule,
        DialogModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        CusSelectModule,
        NgxSpinnerModule,
        SharedModule,
        HammerModule,
        EventsModule.forRoot(),
        CusCarouselModule.forRoot(),
        environment.modules.loyalty ? LoyaltyModule.forRoot() : [],
        environment.modules.vouchers ? VoucherModule.forRoot() : [],
        environment.modules.plus ? PlusModule.forRoot() : [],
        environment.modules.analytics ? AnalyticsModule.forRoot() : [],
        environment.modules.lostTickets ? LostTicketsModule.forRoot() : [],
        environment.modules.changeDate ? ChangeDateModule.forRoot() : [],
        AttractionsModule,
        NgImageSliderModule,
        AttractionCardModule
    ],
  exports: [
    BrowserAnimationsModule,
    CoreRoutingModule,
    HttpClientModule,
    CommonModule,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    HammerModule,
    EventsModule,
      NgImageSliderModule
  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        AppService,
        UiService,
        StorageService,
        { provide: APP_INITIALIZER, useFactory: initApp, deps: [StorageService, CookieService], multi: true},
      ],
    };
  }
}
