<ng-container *ngIf="reservation && reservation.maxApplicableVouchers() > 0">
    <div class="discount-container container-fluid card mb-4" *ngIf="wallet && wallet.balance()">

        <h4>{{ 'CHECKOUT.discount_title' | translate }}</h4>
        <div class="row align-items-center">
            <div class="col-md-12">
                <div class="row mt-2">
                    <div class="col-12">
                        <p [innerHTML]="discountDescription()"></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row summary-container mt-2">
            <div class="col-12 p-2 pt-4 pb-4">
                <div class="row">
                    <div class="col-12">
                        <strong>Totaalprijs</strong>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-12">
                        <s>&euro; {{reservation.grandTotal() + reservation.discountTotal() | number: '1.2-2'}}</s>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-12">
                        <strong class="effective-total mr-1">&euro; {{reservation.grandTotal() | number: '1.2-2'}}</strong>
                        <span class="discount-percentage">(&euro;{{reservation.discountTotal() | number: '1.2-2'}} {{'CHECKOUT.discount' | translate}})</span>
                    </div>
                </div>
            </div>
        </div>

    </div>
</ng-container>