import { Component, ElementRef, ViewChild, Input } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'qup-horizontal-slider',
  templateUrl: './horizontal-slider.component.html',
  styleUrls: ['./horizontal-slider.component.scss']
})
export class HorizontalSliderComponent {
  @Input() scrollVal = 150;
  public hideArrows = new BehaviorSubject<boolean>(false);
  private cardsPerPage = 4;
  @ViewChild('leftArrow') leftArrow!: ElementRef;
  @ViewChild('rightArrow') rightArrow!: ElementRef;
  @ViewChild('contentContainer') contentContainer!: ElementRef;

  ngAfterViewInit(): void {
    this.hideArrows.next(this.contentContainer.nativeElement.children.length <= this.cardsPerPage)
  }

  leftArrowClick(): void {
    this.contentContainer.nativeElement.scrollLeft -= this.scrollVal;
  }

  rightArrowClick(): void {
    this.contentContainer.nativeElement.scrollLeft += this.scrollVal;
  }
}
