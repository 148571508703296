import {AfterViewInit, Component, OnDestroy} from "@angular/core";
import {environment} from "../../../../../environments/environment";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {query} from "@angular/animations";

@Component({
    templateUrl: '../../templates/share/share.component.html',
    styleUrls: ['../../templates/share/share.component.scss']
})
export class ShareComponent implements AfterViewInit, OnDestroy {

    code!: string;
    campaign!: string;
    voucher!: string;

    private _subs: Subscription[] = [];

    missingParams = false;

    constructor(
        private activatedRoute: ActivatedRoute
    ) {

    }

    ngAfterViewInit(): void {
        let code = this.activatedRoute.snapshot.paramMap.get('code');

        if (code == null) {
            this.missingParams = true;
            return;
        }

        let parameters = atob(code).split(';');
        if (parameters.length !== 3) {
            this.missingParams = true;
            return;
        }

        this.campaign = parameters[0];
        this.voucher = parameters[1];
        this.code = parameters[2];
    }

    attemptDeeplink(): void {
        window.open(environment.deeplinkUrl + '/voucher/share/scan?' + this.generateCode());
    }

    generateCode(): string {
        return btoa('campaign=' + this.campaign + '&voucher=' + this.voucher + '&sharingCode=' + this.code);
    }

    ngOnDestroy(): void {
        this._subs.forEach((sub) => sub.unsubscribe());
    }
}