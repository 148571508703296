import {ModuleWithProviders, NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../../components/shared/shared.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CusAutocompleteModule} from "../../components/shared/cus-autocomplete/cus-autocomplete.module";
import {VoucherDiscountComponent} from "./components/voucher-discount.component";
import {EventsDispatcher} from "../../dispatch/events.dispatcher";
import {VoucherEventRegistry} from "./voucher-event.registry";
import {ApplySingleVoucherEvent} from "./dispatch/events/apply-single-voucher.event";
import {VouchersProvider} from "./vouchers.provider";
import {ApplySingleVoucherSubscriber} from "./dispatch/subscribers/apply-single-voucher.subscriber";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        CusAutocompleteModule
    ],
    providers: [],
    declarations: [
        VoucherDiscountComponent
    ],
    exports: [
        VoucherDiscountComponent
    ]
})
export class VoucherModule {
    static forRoot(): ModuleWithProviders<VoucherModule> {
        return {
            ngModule: VoucherModule,
            providers: [
                VouchersProvider,
                ApplySingleVoucherEvent,
                ApplySingleVoucherSubscriber,
                {
                    provide: VoucherEventRegistry,
                    useFactory: LoadVoucherEventRegistry,
                    deps: [EventsDispatcher]
                }
            ]
        };
    }
}

export function LoadVoucherEventRegistry(dispatcher: EventsDispatcher): any {
    return new VoucherEventRegistry(dispatcher);
}