import {Injectable} from "@angular/core";
import {CheckoutProvider} from "../../../../providers/checkout.provider";
import {Reservation} from "../../../../models/checkout/reservation.model";
import {LoyaltyReservation} from "../../../../models/loyalty/loyalty-reservation.model";
import {SubscriberContract} from "../../../../dispatch/contracts/subscriber.contract";

@Injectable()
export class ApplyLoyaltyDiscountSubscriber implements SubscriberContract<Reservation|null> {
    constructor(private checkoutProvider: CheckoutProvider) {}

    async handle(loyaltyReservation: LoyaltyReservation): Promise<Reservation|null>{
        return this.checkoutProvider.replayReservation(loyaltyReservation.loyaltyReservationUuid(), loyaltyReservation.voucherSessionUuid()).toPromise();
    }
}