<div class="visit-guarantee-overlay">
    <div class="visit-guarantee-container col-11">

        <h4 class="visit-guarantee-title">
            {{'CHECKOUT.visit_guarantee_title' | translate}}
        </h4>

        <hr/>
        <h5 class="visit-guarantee-text">
            {{'CHECKOUT.visit_guarantee_text' | translate}}
        </h5>
        <div class="visit-guarantee-toggle-container">
            <label class="visit-guarantee-switch">
                <input type="checkbox" (change)="toggleVisitGuarantee($event)">
                <span class="visit-guarantee-slider round"></span>
            </label>
            <h5 class="visit-guarantee-price-text">{{'CHECKOUT.visit_guarantee' | translate}}</h5>
        </div>


        <div class="visit-guarantee-button-container ml-auto pb-2 pr-2 mt-1">
            <h4 class="visit-guarantee-euro-price">{{visitGuaranteeCosts | currency: 'EUR'}}</h4>
            <button (click)="submitCheckout()" type="button" class="btn btn-primary">
                {{'CHECKOUT.go_further' | translate}}
            </button>
        </div>

    </div>
</div>