import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {environment as env} from "../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {
    CheckoutPaymentRequest, OrderResponseEnvelope,
    PaymentDetailsInput,
    PaymentMethodsResponse
} from "../interfaces/payment.interface";
import {Order} from "../models/checkout/order.model";
import {Router} from "@angular/router";
import {catchError, map} from "rxjs/operators";
import {AppService} from "./app.service";
import {PspData} from "../models/checkout/payment/psp-data.model";
import {ActionData} from "../models/checkout/payment/action-data.model";
import {AdyenCheckoutResultCode} from "../attractions/enums/checkout-result.enum";
import {RAISE_ERROR} from "../dispatch/events.registry";
import {InvalidStateEvent} from "../dispatch/events/checkout/invalid-state.event";
import {CognitoService} from "./cognito.service";
import {flatMap} from "rxjs/internal/operators";

const URLS = {
    getPaymentMethodsEndpoint: () => `${env.paymentProxyUri}/payment/adyen/methods`,
    createPaymentEndpoint: () => `${env.contentApiUri}/session/checkout`,
    submitPaymentDetailsEndpoint: () => `${env.paymentProxyUri}/payment/adyen/details`,
};

@Injectable({
    providedIn: 'root'
})

export class PaymentService {

    constructor(
        private http: HttpClient,
        private router: Router,
        private appService: AppService,
        private invalidStateEvent: InvalidStateEvent,
        private cognitoProvider: CognitoService
    ) {
    }

    paymentHeaders(subject: string, contentPartnerUuid?: string): HttpHeaders {
        if (env.modules.plus && contentPartnerUuid) {
            return new HttpHeaders({
                'Accept-Language': this.appService.currentLanguage.substring(0,2),
                'plusPurchaserSub': subject,
                'contentpartneruuid': contentPartnerUuid,
            });
        } else {
            return new HttpHeaders({
                'Accept-Language': this.appService.currentLanguage.substring(0,2),
            });
        }
    }

    /**
     * Get payment methods
     */

    getPaymentMethods(): Observable<PaymentMethodsResponse> {
        return this.http.post<PaymentMethodsResponse>(URLS.getPaymentMethodsEndpoint(), env.paymentMethod.payload);
    }

    /**
     * Create order payment
     */

    createPayment(checkoutPaymentRequest: CheckoutPaymentRequest, dropin: any, contentPartnerUuid?: string): Observable<Order> {
        return this.cognitoProvider.getCurrentSubject().pipe(
            flatMap((subject) => {
                // Set subject to billing information
                checkoutPaymentRequest.billingDetail!.externalId = subject;

                return this.http.post<OrderResponseEnvelope>(URLS.createPaymentEndpoint(), checkoutPaymentRequest, {
                    headers: this.paymentHeaders(subject, contentPartnerUuid)
                }).pipe(
                    catchError((err) => {
                        RAISE_ERROR('Er is helaas iets mis gegaan met de bestelling, probeer het opnieuw!');
                        this.invalidStateEvent.dispatch();
                        this.router.navigate(['checkout/billing-details']);

                        throw err;
                    }),
                    map((data) => {
                        if (!data.success) {

                            RAISE_ERROR('Er is helaas iets mis gegaan met de bestelling, probeer het opnieuw!');

                            Object.keys(data.errors).forEach((error) => {
                                switch (error) {
                                    case 'RESERVATION_NOT_PAYABLE':
                                        this.invalidStateEvent.dispatch();
                                        this.router.navigate(['checkout/billing-details']);
                                        break;
                                    default:
                                        throw new Error("Failed placing order with response: " + JSON.stringify(data));
                                }
                            });
                        }

                        if (data.response.psp_data?.action) {
                            data.response.psp_data.action.url += '&popOutWebframe=true';
                            dropin.handleAction(data.response.psp_data.action);
                        }
                        return this.mapOrderResponseToOrder(data);
                    }));
                }
            )
        );
    }

    /**
     * Submit payment details
     */

    submitDetails(paymentDetailsInput: PaymentDetailsInput): Observable<any> {
        return this.http.post(URLS.submitPaymentDetailsEndpoint(), paymentDetailsInput);
    }


    mapOrderResponseToOrder(data: OrderResponseEnvelope): Order {

        let actionData = null;
        let pspData = null;

        if (data.response.psp_data) {
            if (data.response.psp_data.action) {
                actionData = new ActionData(
                    data.response.psp_data.action.method,
                    data.response.psp_data.action.paymentData,
                    data.response.psp_data.action.paymentMethodType,
                    data.response.psp_data.action.type,
                    data.response.psp_data.action.url
                );
            }

            pspData = new PspData(
                data.response.psp_data.merchantReference,
                data.response.psp_data.pspReference,
                data.response.psp_data.resultCode,
                data.response.psp_data.amount,
                data.response.psp_data.paymentData,
                data.response.psp_data.details,
                data.response.psp_data.redirect,
                actionData,
            );
        }

        return new Order(
            data.response.orderNumber,
            data.response.orderUuid,
            data.response.paymentId,
            data.response.redirectUrl,
            data.response.psp_provider,
            pspData,
        );
    }

    navigateByResultCode(resultCode: AdyenCheckoutResultCode, merchantReference?: string): void {
        this.router.navigate(['checkout/payment-result'], {
            queryParams: {
                resultCode: resultCode,
                merchantReference: merchantReference
            }
        });
    }

    /**
     * INGENICO checkout redirect
     */

    redirectIngenicoCheckout(url: string): void {
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = url;
        document.body.appendChild(form);
        form.submit();
    }
}
