import {Injectable} from "@angular/core";
import {SubscriberContract} from "../../../../dispatch/contracts/subscriber.contract";
import {Reservation} from "../../../../models/checkout/reservation.model";
import {CheckoutProvider} from "../../../../providers/checkout.provider";
import {of} from "rxjs";
import {catchError} from "rxjs/operators";
import {AddVoucherRequest} from "../../../../interfaces/content-api.interface";
import {StorageService} from "../../../../services/storage.service";

@Injectable()
export class ApplySingleVoucherSubscriber implements SubscriberContract<Reservation|null>  {
    constructor(private checkoutProvider: CheckoutProvider, private storageService: StorageService) {}

    async handle(event: AddVoucherRequest): Promise<Reservation|null>{

        const voucherSessionFromStorage = this.storageService.voucherSessionUuid;
        let voucherSessionUuid = event.VoucherSessionUuid;

        if (voucherSessionFromStorage) {
            voucherSessionUuid = voucherSessionFromStorage;
        }

        return this.checkoutProvider.replayReservation(undefined, voucherSessionUuid, undefined, event.Codes).pipe(
            catchError((e) => {
                this.checkoutProvider.reservation().next(null);
                return of(null);
            })
        ).toPromise();
    }
}