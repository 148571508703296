
<div class="container">
    <qup-attractions-toolbar [hideMap]="true"></qup-attractions-toolbar>
    <div [ngClass]="isMobile ? 'row justify-content-center' : 'row align-items-center'">

        <div *ngIf="!isMobile && !selectedContentPartner" class="col-4 content-partners-list-container">
            <qup-attraction-card [mobileFullWidth]="true" [selectable]="true" class="col-xs-2 p-1"
                                 *ngFor="let partner of contentPartners"
                                 [product]="partner" [enableHeart]="false">
            </qup-attraction-card>
        </div>

        <div *ngIf="selectedContentPartner && !isMobile" class="col-4">
            <div class="container">
                <div class="row">
                    <div class="close-partner-icon" (click)="closeSelectedContentPartner()">
                        <img src="assets/images/cross.svg" alt="close partner image" class="close-partner-icon">
                    </div>
                    <qup-attraction-card [product]="selectedContentPartner" [enableHeart]="false"></qup-attraction-card>
                </div>
            </div>
        </div>

        <div [ngClass]="isMobile ? 'col-12': 'col-8'">
            <div #map class="map"></div>
        </div>

        <div *ngIf="selectedContentPartner && isMobile" class="my-2">
            <div class="container">
                <div class="row">
                    <div class="close-partner-icon" (click)="closeSelectedContentPartner()">
                        <img src="assets/images/cross.svg" alt="close partner image" class="close-partner-icon">
                    </div>
                    <qup-attraction-card [product]="selectedContentPartner" [enableHeart]="false"></qup-attraction-card>
                </div>
            </div>
        </div>
        <div class="container" *ngIf="isMobile && !selectedContentPartner">
            <div class="wrap-mobile entry-page-cus-container-mobile">
                <qup-horizontal-slider [ngClass]="{'single-product': contentPartners.length === 1}" class="gap-2">
                    <qup-attraction-card *ngFor="let product of contentPartners" [product]="product"
                                         [enableHeart]="false">
                    </qup-attraction-card>
                </qup-horizontal-slider>
            </div>
        </div>
    </div>
</div>
