import {Injectable} from "@angular/core";
import {ReplaySubject} from "rxjs";
import {StandardEvent} from "../../../../dispatch/events/standard.event";
import {AddPlusVoucherRequest} from "../../../../interfaces/plus.interface";

@Injectable()
export class AddPlusVoucherEvent extends StandardEvent<string> {
    constructor() {
        super(new ReplaySubject<string>());
    }

    dispatch(payload: string): void {
        this.subject.next(payload);
    }
}