import {Injectable} from "@angular/core";
import {StandardEvent} from "../standard.event";
import {Timeslot} from "../../../models/timeslots/timeslot";
import {ReplaySubject} from "rxjs";

@Injectable()
export class SelectTimeslotEvent extends StandardEvent<Timeslot>{

    constructor(
    ) {
        super(new ReplaySubject<Timeslot>())
    }

    dispatch(selectedTimeslot: Timeslot):void {
        this.subject.next(selectedTimeslot);
    }

}