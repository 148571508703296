import {Injectable} from "@angular/core";
import {CheckoutProvider} from "../../../providers/checkout.provider";
import {Reservation} from "../../../models/checkout/reservation.model";
import {AvailableTimeslots} from "../../../models/timeslots/available-timeslots.model";
import {SubscriberContract} from "../../contracts/subscriber.contract";

@Injectable()
export class TimeslotsSubscriber implements SubscriberContract<AvailableTimeslots> {
    constructor(private checkoutProvider: CheckoutProvider) {}

    async handle(event: Reservation): Promise<AvailableTimeslots>{
        this.checkoutProvider.selectedTimeslot().next(null);
        return this.checkoutProvider.retrieveTimeSlotsForReservation(event.orderReservationUuid()).toPromise();
    }
}