<div class="cus-container">
  <div (click)="closeClicked.next()" class="close">
    <svg class="icon icon-24" xmlns="http://www.w3.org/2000/svg" role="img" focusable="img">
      <use xlink:href="assets/images/icon__sprite.svg#cross"></use>
    </svg>
  </div>

  <h3 class="cus-header">{{'COOKIES.cookie_preferences_title' | translate}}</h3>
  <div class="cus-description">{{'COOKIES.cookie_preferences_subtitle' | translate}}</div>
  <form [formGroup]="form">
      <div *ngFor="let c of controls;" [ngClass]="{isDisabled: c.disabled}" class="custom-control custom-checkbox form-field-container">
        <input id="{{c.key}}" class="form-check" [formControlName]="c.key" class="custom-control-input"
          type="checkbox" [attr.disabled]="c.disabled ? true : null" class="custom-control-input" />
        <label for="{{c.key}}" class="label custom-control-label">
          <div class="font-weight-bold name">{{c.name | translate }}</div>
          <div class="text-sm description">{{c.desc | translate }}</div>
        </label>
      </div>
  </form>
  <div class="br"></div>
  <div class="text-sm">{{'COOKIES.performance' | translate}} <span class="font-weight-bold">{{performance}}</span></div>
    <div class="actions">
      <button (click)="formSubmit()" type="button"
      class="btn btn-secondary">{{'COOKIES.accept_selected' | translate}}</button>
      <button (click)="acceptAllClicked.next()" type="button" class="btn btn-primary">{{'COOKIES.accept_all' | translate}}</button>
    </div>
</div>
