import {Injectable} from "@angular/core";
import {SubscriberContract} from "../../contracts/subscriber.contract";
import {Reservation} from "../../../models/checkout/reservation.model";
import {CheckoutProvider} from "../../../providers/checkout.provider";
import {catchError, first, mergeMap, tap} from 'rxjs/operators';
import {of} from "rxjs";
import {DateTime} from "luxon";
import {StorageNS, StorageService} from "../../../services/storage.service";


@Injectable()
export class RecoverInvalidStateSubscriber implements SubscriberContract<Reservation|null> {
    constructor(
        private checkoutProvider: CheckoutProvider,
        private storageService: StorageService
    ) {}

    async handle(): Promise<Reservation|null> {
        return this.checkoutProvider.rebuildCheckout().pipe(
            catchError(error => of(null)),
            mergeMap((reservation) => {

                if (!reservation) {
                    return of(null);
                }

                const voucherSessionUuid: string = this.storageService.getItem(StorageNS.VOUCHER_SESSION_UUID) ?? reservation.voucherSessionUuid();

                return this.checkoutProvider.replayReservation(
                    reservation.loyaltyReservationUuid(),
                    voucherSessionUuid,
                    reservation.visitGuaranteeCosts() > 0
                ).pipe(
                    first(),
                    tap((reservation) => {
                        if (reservation == null || !reservation.timeslotTime()) {
                            return;
                        }

                        const timeArray = reservation.timeslotTime()!.split(':');
                        let timeslotDate = DateTime.fromJSDate(
                            reservation.visitDate()).set({
                                hour: parseInt(timeArray[0]),
                                minute: parseInt(timeArray[1])
                            }).setZone('utc', {keepLocalTime: true});

                        this.checkoutProvider.reserveTimeslot(timeslotDate.toJSDate().toISOString(), reservation.orderReservationUuid()).pipe(
                            first()
                        ).subscribe();
                    })
                );
            })
        ).toPromise();
    }
}