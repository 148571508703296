import {Banner} from "./banner.model";

export class Campaign {
    constructor(
        private _campaignUuid: string,
        private _campaignTitle: string,
        private _campaignKey: string,
        private _website: string,
        private _redeemPeriod: string,
        private _banners: Banner[]
    ) {}

    campaignUuid(): string {
        return this._campaignUuid;
    }

    campaignTitle(): string {
        return this._campaignTitle;
    }

    campaignKey(): string {
        return this._campaignKey;
    }

    website(): string {
        return this._website;
    }

    redeemPeriod(): string {
        return this._redeemPeriod;
    }

    banners(): Banner[] {
        return this._banners;
    }


}