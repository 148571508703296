import {UiService} from "../../../../services/ui.service";
import {Component, OnDestroy, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {tap} from "rxjs/operators";
import {Subscription} from "rxjs";
import {PlusWallet} from "../../models/plus-wallet.model";
import {GetPlusWalletEvent} from "../../dispatch/events/get-plus-wallet.event";
import {PlusEventRegistry} from "../../plus-event.registry";
import {PlusProvider} from "../../plus.provider";
import Auth from "@aws-amplify/auth";
import {AddPlusVoucherEvent} from "../../dispatch/events/add-plus-voucher.event";
import {environment} from "../../../../../environments/environment";


@Component({
    selector: 'qup-plus-account',
    templateUrl: '../../templates/account/account.component.html',
    styleUrls: ['../../templates/account/account.component.scss']
})
export class AccountComponent implements OnInit, OnDestroy {

    private _subs: Subscription[] = [];
    isMobile = true;
    form!: FormGroup;
    loggedIn: boolean = false;
    wallet!: PlusWallet;
    balance: number = 0;
    vouchers: string[] = [];
    addDisabled: boolean = false;
    latestVoucher: string = '';
    showFireWorkAnimation: boolean = false;

    constructor(
        private router: Router,
        public getWalletEvent: GetPlusWalletEvent,
        private addVoucherEvent: AddPlusVoucherEvent,
        private plusEventRegistry: PlusEventRegistry,
        private formBuilder: FormBuilder,
        private plusProvider: PlusProvider,
        private uiService: UiService
    ) {
        this.getWalletEvent.dispatch();

    }

    ngOnInit(): void {

        this.checkUserLoggedIn();

        this._subs.push(
            this.plusProvider.wallet().subscribe(data => {
                    if (data) {
                        this.balance = 0;
                        this.wallet = data;
                        data.vouchers().forEach(voucher => {
                            if (voucher.isUsable()) {
                                this.balance += voucher.value();
                            }
                        });

                    }
                },
                error => {
                    console.log('error: ', error);
                },
            ),
            this.plusProvider.latestVoucher().subscribe(voucher => {
                if (voucher) {
                    this.latestVoucher = voucher;
                    this.showFireWorkAnimation = true;
                    this.balance += 5;
                    setTimeout(() => {
                        this.showFireWorkAnimation = false;
                    }, 4500);
                }
            })
        );

        this.getWalletEvent.dispatch();

        this.uiService.isMobileOverlay$
            .pipe(
                tap((isMobile) => {
                    this.isMobile = isMobile;
                })
            ).subscribe();

        this.form = this.formBuilder.group({
            voucher: [null],
        });
    }

    checkUserLoggedIn(): void {
        Auth.currentAuthenticatedUser()
            .then(() => this.loggedIn = true).catch((err: any) => {
            console.error(err);
        });
    }

    async logout(): Promise<void> {
        await Auth.signOut();
    }


    ngOnDestroy(): void {
        this._subs.forEach(sub => sub.unsubscribe());
    }

    signOutClick(): void {
        this.logout();
        this.router.navigate(['/']);
    }

    deleteAccountClick(): void {
        this.router.navigate(['account/delete']);
    }

    addVoucher(): void {

        const voucherToAdd = this.form.get('voucher')?.value;

        if (voucherToAdd) {
            this.addDisabled = true;

            const vouchers = voucherToAdd.split(',');

            if (vouchers.length > 1) {

                vouchers.forEach((voucher: string) => {
                    this.vouchers.push(voucher);
                });

                this.addVoucherEvent.dispatch(vouchers);

            } else {

                this.vouchers.push(voucherToAdd);

                console.log('vouchers: ', this.vouchers);
                this.addVoucherEvent.dispatch(voucherToAdd);

            }

            this.vouchers = [];
        }

        this.addDisabled = false;
        this.form.clearValidators();
        this.form.get('voucher')?.setValue('');
        this.form.reset();
    }

    shareableVouchers(): number {
        return Math.floor(this.wallet.vouchers().filter((voucher) => {
            return voucher.isShareable();
        }).length / 10);
    }

    buttonDisabled(): boolean {
        return this.addDisabled || !this.form.get('voucher')?.value;
    }

    protected readonly environment = environment;
}

