import {Injectable} from "@angular/core";
import {SubscriberContract} from "../../../../dispatch/contracts/subscriber.contract";
import {ChangeDateService} from "../../../../services/change-date.service";
import {ChangeDatePayload} from "../../../../interfaces/change-date.interface";

@Injectable()
export class SendChangeDateEmailSubscriber implements SubscriberContract<ChangeDatePayload> {
    constructor(
        private changeDateService: ChangeDateService
    ) {}

    async handle(event: ChangeDatePayload): Promise<any> {
        return this.changeDateService.sendChangeDateEmail(event);
    }
}
