import {Injectable} from '@angular/core';
import {environment as env} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {LostTicketsProvider} from "../modules/lost-tickets/lost-tickets.provider";
import {LostTicketsPayload} from "../interfaces/lost-tickets.interface";
import {Observable} from "rxjs";


const URLS = {
    lostTicketsEndpoint: () => `${env.contentApiUri}/session/order/resend`,
};

@Injectable({
    providedIn: 'root'
})

export class LostTicketsService {

    constructor(
        private http: HttpClient,
        private lostTicketsProvider: LostTicketsProvider
    ) {
    }

    sendLostTicketsEmail(payload: LostTicketsPayload): Observable<any> {
        return this.http.post(URLS.lostTicketsEndpoint(), payload);
    }
}
