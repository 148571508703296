import {Component, OnDestroy, OnInit} from '@angular/core';
import {ChangeDateEventRegistry} from "../change-date-event.registry";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SendChangeDateEmailEvent} from "../dispatch/events/send-change-date-email.event";
import {ChangeDateProvider} from "../change-date.provider";
import {Subscription} from "rxjs";

@Component({
    selector: 'qup-change-date',
    templateUrl: './../../../templates/change-date/change-date.component.html',
    styleUrls: ['./../../../templates/change-date/change-date.component.scss'],
})
export class ChangeDateComponent implements OnInit, OnDestroy {
    public isSent = false;
    private _subs: Subscription[] = [];

    form: FormGroup = this.formBuilder.group({
        orderNumber: [null, [Validators.required]],
        email: [null, [Validators.required, Validators.email]],
    });

    message = '';

    constructor(
        private changeDateEventRegistry: ChangeDateEventRegistry,
        private formBuilder: FormBuilder,
        private sendChangeDateEmailEvent: SendChangeDateEmailEvent,
        private changeDateProvider: ChangeDateProvider,
    ) {
    }

    ngOnInit(): void {
        this._subs.push(
            this.changeDateProvider.changeDateMessage().subscribe((data) => {
                this.message = data;
                if (data == 'DATE_CHANGE.email_success_sent') {
                    this.isSent = true;
                }
            })
        );
    }

    submit(): void {
        const email = this.form.get('email')?.value;
        const orderNumber = this.form.get('orderNumber')?.value;

        if (email && orderNumber) {
            this.sendChangeDateEmailEvent.dispatch({email: email, order_number: orderNumber});
        }
    }

    clearMessage(): void {
        this.changeDateProvider.changeDateMessage().next('');
    }

    ngOnDestroy(): void {
        this.clearMessage();
        this._subs.forEach(s => s?.unsubscribe());
    }
}
