import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {DateTime, Info} from "luxon";
import {AppService} from "../../../services/app.service";
import {AttractionProvider} from "../../../providers/attraction.provider";
import {CheckoutProvider} from "../../../providers/checkout.provider";
import {first, map, retry, skipWhile, tap} from "rxjs/operators";
import {CalendarDay} from "../../../models/calendar/calendar-day.model";
import {BehaviorSubject, ReplaySubject, Subject, Subscription} from "rxjs";
import {CalendarDateSelectEvent} from "../../../dispatch/events/content-partner/calendar-date-select.event";
import {Location} from "../../../models/content-partner/location.model";
import {ContentPartner} from "../../../models/content-partner/content-partner.model";
import {CalendarItem} from "../../../models/calendar/calendar-item.model";


import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Pipe({
    name: 'removeZeroDecimals'
})
export class RemoveZeroDecimalsPipe implements PipeTransform {
    transform(value: any): string {
        // Convert the number to a string
        const stringValue = value.toString();

        // Check if the number has a decimal point
        if (stringValue.includes('.')) {
            // Split the number into integer and decimal parts
            const [integerPart, decimalPart] = stringValue.split('.');

            // Check if the decimal part is '00' or '0'
            if (decimalPart === '00' || decimalPart === '0') {
                // Return only the integer part
                return integerPart;
            }
        }

        // Return the original value if no changes are needed
        return stringValue;
    }
}

interface LegendItem {
    min_discount: number,
    max_discount: number,
    color: string
}

@Component({
    selector: 'qup-calendar-v2',
    templateUrl: '../../../templates/calendar/calendar.component.html',
    styleUrls: ['../../../templates/calendar/calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnDestroy {
    @Input('embedded') embedded: boolean = false;
    @Input('mobile') isMobile: boolean = false;

    public calendarGrid: CalendarDay[][] = [];

    public colorMap: Map<number, string> = new Map();

    public legend: LegendItem[] = [];

    public daysOfTheWeek: string[] = [];

    public dayOfMonth: Subject<DateTime> = new Subject<DateTime>();

    private processedDayOfMonth: DateTime = DateTime.now();

    public monthTitle: string = '';

    public showCalendar: boolean = false;

    public today: DateTime = DateTime.local();

    public selectedDay: BehaviorSubject<DateTime|null> = new BehaviorSubject<DateTime|null>(null);

    @Input('contentPartner') public contentPartner: ContentPartner|null = null;

    public location: Location|null = null;
    private _subs: Subscription[] = [];

    private swipeCoord?: [number, number];
    private swipeTime?: number;

    public highestUnitPrice: number = 0;
    public lowestEffectivePrice: number = 999;

    private calendarFeed: ReplaySubject<CalendarItem[]> = new ReplaySubject<CalendarItem[]>();

    selectedDate: Date | null = null;
    isProductSelectionVisible = false;


    constructor(
        private attractionProvider: AttractionProvider,
        private appService: AppService,
        private checkoutProvider: CheckoutProvider,
        private calendarDateSelectEvent: CalendarDateSelectEvent,
        private translateService: TranslateService
    ) {
    }

    ngOnInit(): void {
        this.daysOfTheWeek = Info.weekdays("short", {
            locale: this.appService.currentCalendarLocale ?? 'nl'
        });

        this._subs.push(
            this.dayOfMonth.subscribe((dayOfMonth) => {
                this.monthTitle = dayOfMonth.toFormat("MMMM y", {locale: this.appService.currentCalendarLocale!});
                this.processedDayOfMonth = dayOfMonth;
                this.generateCalendarGrid(dayOfMonth.year, dayOfMonth.month);
            }),
            this.checkoutProvider.selectedLocation().pipe(
                skipWhile(val => this.contentPartner === null)
            ).subscribe((location) => {
                this.location = location;
                this.showCalendar = this.contentPartner?.showCalendar()!;
                this.attractionProvider
                    .loadCalendar(this.location?.uuid()!, DateTime.local(), DateTime.local().plus({year: 1}))
                    .pipe(
                        first(),
                        tap((calendarItems) => this.calendarFeed.next(calendarItems)),
                        retry(2)
                    ).subscribe((result) => {
                        this.scanColors();
                        this.dayOfMonth.next(this.processedDayOfMonth);
                    }
                );
            }),
            this.checkoutProvider.reservation().subscribe((reservation) => {
                if (!reservation) {
                    return;
                }

                this.selectedDay.next(DateTime.fromJSDate(reservation.visitDate()));
            })
        );
    }

    scanColors(): void {
        this.calendarFeed
            .pipe(
                map((calendar) => {
                    this.highestUnitPrice = 0;
                    this.lowestEffectivePrice = 999;
                    return calendar.map((calendarItem) => {
                        if (calendarItem.listPrice() > this.highestUnitPrice) {
                            this.highestUnitPrice = calendarItem.listPrice();
                        }
                        if (calendarItem.effectivePrice() < this.lowestEffectivePrice) {
                            this.lowestEffectivePrice = calendarItem.effectivePrice();
                        }
                        return calendarItem.discountAmount();
                    });
                }),
                map((discounts) => {
                    let discountMap: number[] = [];
                    discountMap.push(
                        ...discounts.filter((item, index, array) => array.indexOf(item) === index && item !== 0)
                    );
                    return discountMap.sort((a,b) => {
                        if (a > b) {
                            return 1;
                        }
                        if (a < b) {
                            return -1;
                        }

                        return 0;
                    });
                }),
                first(),
                tap((uniqueSortedDiscounts) => {
                    if (uniqueSortedDiscounts.length <= 0) {
                        return;
                    }

                    let perStep = Math.floor(uniqueSortedDiscounts.length / 6);
                    let remainder = uniqueSortedDiscounts.length - (perStep * 6);

                    let stepAssignments: number[] = [];
                    for (let i = 0; i <= 6; i++) {
                        if (remainder > 0) {
                            stepAssignments.push(perStep + 1);
                            remainder--;
                        } else {
                            stepAssignments.push(perStep);
                        }
                    }

                    let discountIndex = 0;
                    stepAssignments.forEach((value, index) => {
                        uniqueSortedDiscounts.slice(discountIndex, discountIndex + value).forEach((discount) => {
                            this.colorMap.set(discount, 'color_' + (index + 1));
                        });
                        discountIndex += value;
                    });
                })
            ).subscribe();
    }

    generateCalendarGrid(year: number, month: number): void {
        const firstDayOfMonth = DateTime.local(year, month, 1).startOf('day');
        const lastDayOfMonth = firstDayOfMonth.plus({days: (firstDayOfMonth.daysInMonth! -1)}).endOf('day');

        const startDate = firstDayOfMonth.minus({days: Math.abs(1 - firstDayOfMonth.weekday)});
        const endDate = lastDayOfMonth.plus({days: (7 - lastDayOfMonth.weekday)});

        const dateGrid = new Map<number, Map<string, CalendarDay>>();

        for (let date = startDate; date <= endDate; date = date.plus({days: 1})) {
            if (!dateGrid.has(date.weekNumber)) {
                dateGrid.set(date.weekNumber, new Map<string, CalendarDay>());
            }

            dateGrid.get(date.weekNumber)?.set(date.toISODate()!, new CalendarDay(date));
        }

        if (this.location == null) {
            return;
        }

        this.calendarGrid = [];

        this.calendarFeed.pipe(
            map((calendarItems) => {
                calendarItems.filter((calendarItem) => {
                    return (DateTime.fromJSDate(calendarItem.date()) >= firstDayOfMonth && DateTime.fromJSDate(calendarItem.date()) <= lastDayOfMonth);
                }).forEach((calendarItem) => {
                    const calendarDate = DateTime.fromJSDate(calendarItem.date());
                    dateGrid.get(calendarDate.weekNumber)?.set(calendarDate.toISODate()!, new CalendarDay(calendarDate, calendarItem));
                });

                return Array.from(dateGrid.values()).map((item) => {
                    return Array.from(item.values());
                });
            }),
            first()
        ).subscribe((result: CalendarDay[][]) => {
            this.calendarGrid = result;
            this.generateLegend();

        });
    }

    ngOnDestroy(): void {
        this._subs.forEach((sub) => sub.unsubscribe());
    }

    nextMonth(): void {
        this.changeMonth(this.processedDayOfMonth.plus({month: 1}));
    }

    previousMonth(): void {
        this.changeMonth(this.processedDayOfMonth.minus({month: 1}));
    }

    private changeMonth(dayOfMonth: DateTime): void {
        this.dayOfMonth.next(dayOfMonth);
    }

    isCurrentMonth(checkDate: DateTime): boolean {
        return checkDate.month === this.processedDayOfMonth.month;
    }

    selectDay(day: DateTime): void {
        this.selectedDay.next(day);
        this.calendarDateSelectEvent.dispatch(day.toJSDate());
        this.isProductSelectionVisible = true;
    }

    closeProductSelection() {
        this.isProductSelectionVisible = false;
    }

    generateLegend(): void {
        let legend: Map<string, LegendItem> = new Map();
        this.colorMap.forEach((color, discount, ) => {
            let currentItem = legend.get(color);
            if (!currentItem) {
                currentItem = {
                    min_discount: discount,
                    max_discount: discount,
                    color: color
                };
            }

            if (discount > currentItem.max_discount) {
                currentItem.max_discount = discount;
            }

            if (discount < currentItem.min_discount) {
                currentItem.min_discount = discount;
            }
            legend.set(color, currentItem);
        });

        this.legend = Array.from(legend.values());
    }


    swipeCalendarMonth(e: TouchEvent, when: string): void {
        const coordinates: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
        const time = new Date().getTime();

        if (when === 'start') {
            this.swipeCoord = coordinates;
            this.swipeTime = time;
        } else if (when === 'end') {

            if (this.swipeCoord && this.swipeTime) {
                const direction = [coordinates[0] - this.swipeCoord[0], coordinates[1] - this.swipeCoord[1]];
                const duration = time - this.swipeTime;

                if (duration < 1000 && Math.abs(direction[0]) > 30 && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) {
                    if (direction [0] < 0) {
                        this.nextMonth();
                    } else if (direction[0] > 0 ) {
                        this.previousMonth();
                    }
                }
            }
        }
    }

    checkDate(): boolean {

        let visitDate = this.selectedDay.value;

        if (visitDate == null) {
            return false;
        }

        if (
            visitDate.hasSame(DateTime.local(), "day")
            && DateTime.local().hour > 16
        ) {
            return true;
        }

        return false;
    }
}