import {Timeslot} from "./timeslot";

export class AvailableTimeslots {
    constructor(
        private _availableTimeslots: Timeslot[],
    ) {}

    availableTimeslots(): Timeslot[] {
        return this._availableTimeslots;
    }

}