<header>
    <div *ngIf="!isMobile" class="row mb-2 mt-4">
        <div class="row">
            <div class="header-logo-col col-2">
                <a alt="Home" href="/">
                    <img src="assets/images/logo_dagjeuit.gif" width="150"/>
                </a>
            </div>
            <div class="header-navigation-col col-8">
                <nav aria-label="Main" class="mt-4 mb-2" data-controller="header/navigation">
                    <ul class="header-menu-list" data-active="true"
                        data-controller="header/navigation/menu" data-identifier="header/navigation/main">
                        <li *ngFor="let navItem of navigationItems; let i=index;" class="list-item">
                            <a (click)="closeMenu(); handleClick(navItem);" [innerHTML]="navItem.label"
                               [routerLinkActiveOptions]="{exact: true}" [routerLink]="navItem.route"
                               class="header-navigation-link" routerLinkActive="active"></a>
                        </li>
                        <li *ngIf="isLoggedIn" class="list-item balance">
                            <img alt="wallet-coins" src="assets/images/coin_stack_high.svg"/>
                            <a (click)="navigateTo('/account/home')">{{ 'HEADER.balance' | translate }}
                                &euro;{{ walletBalance | number: '1.2-2' }}</a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="header-search-col col-2" *ngIf="showSearch()">
                <div class="search-container col-auto ml-auto wallet-icon-container mt-4">
                    <form [formGroup]="form" style="display: contents;">
                        <div class="row">
                            <div class="col-12">
                                <input class="search" formControlName="search" placeholder="{{'HEADER.search' | translate}}"
                                       type="text">
                                <button (click)="submitSearch()" class="searchButton">
                                    <i class="material-symbols-outlined">search</i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="isMobile" class="header-menu-mobile">
        <div class="row">
            <div class="m-2 col-2">
                <a alt="Home" href="/">
                    <img src="assets/images/logo_dagjeuit.gif" width="80"/>
                </a>
            </div>
            <div class="search-container col-auto ml-auto wallet-icon-container mt-4">
                <form [formGroup]="form" style="display: contents;">
                    <input class="search" formControlName="search" placeholder="{{'HEADER.search' | translate}}"
                           type="text">
                    <button (click)="submitSearch()" class="searchButton">
                        <i class="material-symbols-outlined">search</i>
                    </button>
                </form>
            </div>
            <div class="col-3">
                <div class="menu-icon-container mt-2">
                    <a (click)="toggleMenu()" class="icon" href="javascript:void(0);">
                        <span class="material-symbols-outlined menu-icon">menu</span>
                    </a>
                </div>
            </div>
        </div>
        <div class="row">
            <div *ngIf="toggle" class="col-12 topnav">
                <div id="myLinks">
                    <a (click)="closeMenu(); handleClick(navItem);" *ngFor="let navItem of navigationItems; let i=index;"
                       [innerHTML]="navItem.label" [routerLinkActiveOptions]="{exact: true}"
                       [routerLink]="navItem.route" class="header-navigation-link"
                       class="list-item" routerLinkActive="active"></a>
                    <a (click)="closeMenu(); navigateTo('account/home');" *ngIf="isLoggedIn" class="header-navigation-link ng-star-inserted"
                       routerLinkActive="active"><i class="material-symbols-outlined"
                                                                             style="font-size: 24px">toll</i> {{ 'HEADER.balance' | translate }}
                        &euro;{{ walletBalance | number: '1.2-2' }}</a>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="_showHeaderSearch" class="row mb-4">
        <div class="col-12">
            <div class="hero-container">
                <img *ngIf="!isMobile" alt="PLUS Dagje Uit" class="hero-image" src="assets/images/hero_image.jpg"/>
                <img *ngIf="isMobile" alt="PLUS Dagje Uit" class="hero-image mt-2"
                     src="assets/images/hero_image_mobile.jpg"/>
            </div>
        </div>
    </div>
</header>
