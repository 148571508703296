import {DateTime} from "luxon";
import {CalendarItem} from "./calendar-item.model";

export class CalendarDay {
    constructor(
        private _date: DateTime,
        private _calendarItem?: CalendarItem| null
    ) {}

    hasCalendarItem(): boolean {
        return this._calendarItem !== null && this._calendarItem !== undefined;
    }

    active(): boolean {
        return this.hasCalendarItem();
    }

    calendarItem(): CalendarItem|undefined|null {
        return this._calendarItem;
    }

    date(): DateTime {
        return this._date;
    }

    dateString(): string {
        return this._date.toISODate()!;
    }

    dayOfTheWeek(): number {
        return this._date.weekday;
    }

    month(): number {
        return this._date.month;
    }
}