import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import awsmobile from "./environments/environment";
import Auth from "@aws-amplify/auth";
import {Amplify} from "aws-amplify";

Sentry.init({
  environment: environment.currentEnvironment,
  dsn: environment.sentryUri,
  ignoreErrors: [
    'Non-Error exception captured', // Sentry throws this error when a non-exception is sent to it, ignore error.
    'ChunkLoadError', // This error is caused by a deployment when the chunk can't be found, automated reload added, ignore error.
    'Could not load "util".', // Google maps sometimes doesn't load (due to blockers etc.), ignore error.
    'Unknown Error', // These errors are caused by CORS but also by cancelled requests/ slow requests when navigating away. since CORS is a back-end issue, ignore error.
    'Handled unknown error' // Though they might be an indicator of an issue they are nonsensical and carry no actual information so don't report.
  ],
  integrations: [
    new BrowserTracing({
      tracingOrigins: ["localhost", "https://plusdagjeuit.nl", "https://api.funtrips.io", "https://content-api.prod.funtrips.io"],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.Replay()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.05,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.005,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
});

if (environment.production) {
  enableProdMode();
}

Amplify.configure(awsmobile);
Auth.configure(awsmobile);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
