import {Injectable} from "@angular/core";
import {Reservation} from "../../../models/checkout/reservation.model";
import {CheckoutProvider} from "../../../providers/checkout.provider";
import {SubscriberContract} from "../../contracts/subscriber.contract";

@Injectable()
export class ReplayReservationSubscriber implements SubscriberContract<Reservation|null> {
    constructor(private checkoutProvider: CheckoutProvider) {}

    async handle(): Promise<Reservation|null>{
        return this.checkoutProvider.replayReservation().toPromise();
    }
}