import {Injectable} from "@angular/core";
import {SubscriberContract} from "../../contracts/subscriber.contract";
import {of} from "rxjs";

@Injectable()
export class GetOrderOverviewSubscriber implements SubscriberContract<any> {
    constructor() {}

    async handle(event: any): Promise<any> {
        return of().toPromise();
    }
}