export class ActionData {
    constructor(
        private _method: string,
        private _paymentData: string,
        private _paymentMethodType: string,
        private _type: string,
        private _url: string,
    ) { }

    method(): string {
        return this._method;
    }

    paymentData(): string {
        return this._paymentData;
    }

    paymentMethodType(): string {
        return this._paymentMethodType;
    }

    type(): string {
        return this._type;
    }

    url(): string {
        return this._url;
    }

}