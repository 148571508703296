import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {interceptorsProvider} from "./interceptors/interceptors.provider";
import {NgxSpinnerModule} from "ngx-spinner";
import {LoadingStateService} from "./services/loading-state.service";
import {registerLocaleData} from "@angular/common";
import localeNL from '@angular/common/locales/nl';
import {CoreProvider} from "./providers/core.provider";
import {ErrorProvider} from "./providers/error.provider";
import {LocaleEnum} from "../environments/enums/locale.enum";
import {createErrorHandler} from "./errors/main-error.handler";
import {TranslationLoaderService} from "./services/translation-loader.service";
import {PlusProvider} from "./modules/plus/plus.provider";
import {CognitoService} from "./services/cognito.service";

registerLocaleData(localeNL, 'nl');

export function HttpLoaderFactory(http: HttpClient): any {
    return new TranslationLoaderService(http);
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        NgxSpinnerModule,
        TranslateModule.forRoot({
            defaultLanguage: LocaleEnum.nl_NL,
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
        }),
        CoreModule.forRoot()
    ],
    providers: [
        CoreProvider,
        ErrorProvider,
        PlusProvider,
        interceptorsProvider,
        LoadingStateService,
        CognitoService,
        // plusInterceptorProvider,
        {
            provide: ErrorHandler,
            useValue: createErrorHandler({
                showDialog: false,
            }),
        },
        {provide: LOCALE_ID, useValue: 'nl'}
    ],
    exports: [
        TranslateModule
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
