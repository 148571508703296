import {Injectable, Injector, Type} from "@angular/core";
import {Subscription} from "rxjs";
import {EventContract} from "./contracts/event.contract";
import {SubscriberContract} from "./contracts/subscriber.contract";
import {ChangeRefSubscriber} from "./subscribers/core/change-ref.subscriber";
import {pairwise, startWith} from "rxjs/operators";

@Injectable()
export class EventsDispatcher {

    private subscribers: Subscription[] = [];

    constructor(
        private injector: Injector,
        private changeRefSubscriber: ChangeRefSubscriber
    ) {
    }

    createInvokeFunction<T>(event: Type<EventContract<T>>): (...parameters: any) => void {
        return async (...parameters: any) => {
            const eventInstance = this.injector.get(event) as unknown as EventContract<T>;
            eventInstance.dispatch(...parameters);
        };
    }

    register<T>(event: Type<EventContract<T>>, subscriptions: Type<SubscriberContract<any>>[], resourceCallback?: (resource: T) => any[]): EventsDispatcher {

        const eventInstance = this.injector.get(event) as unknown as EventContract<T>;

        this.subscribers.push(eventInstance.listen().subscribe(async (resource: T) => {
            for (let i =0; i < subscriptions.length; i++) {
                const subscriptionInstance = this.injector.get(subscriptions[i]) as unknown as SubscriberContract<any>;

                let wrappedResource = [resource];
                if (resourceCallback) {
                    wrappedResource = resourceCallback(resource);
                }

                await subscriptionInstance.handle(...wrappedResource);

                this.changeRefSubscriber.handle();
            }
        }));

        return this;
    }

    pairWise<T>(event: Type<EventContract<T>>, subscriptions: Type<SubscriberContract<any>>[], resourceCallback?: (oldResource: T|null, resource: T|null) => any[]): EventsDispatcher {

        const eventInstance = this.injector.get(event) as unknown as EventContract<T>;

        this.subscribers.push(eventInstance.listen().pipe(startWith(null), pairwise()).subscribe(async (resource: [T|null,T|null]) => {
            for (let i =0; i < subscriptions.length; i++) {
                const subscriptionInstance = this.injector.get(subscriptions[i]) as unknown as SubscriberContract<any>;

                let wrappedResource = [...resource];
                if (resourceCallback) {
                    wrappedResource = resourceCallback(...resource);
                }

                await subscriptionInstance.handle(...wrappedResource);

                this.changeRefSubscriber.handle();
            }
        }));

        return this;
    }

    chain<T>(event: Type<EventContract<T>>, subscriptions: Type<SubscriberContract<any>>[], resourceCallback?: (resource: T) => any[]): EventsDispatcher {

        const eventInstance = this.injector.get(event) as unknown as EventContract<T>;

        this.subscribers.push(eventInstance.listen().subscribe((resource: T) => {
            for (let i =0; i < subscriptions.length; i++) {
                const subscriptionInstance = this.injector.get(subscriptions[i]) as unknown as SubscriberContract<any>;

                let wrappedResource = [resource];
                if (resourceCallback) {
                    wrappedResource = resourceCallback(resource);
                }

                subscriptionInstance.handle(...wrappedResource);

                this.changeRefSubscriber.handle();
            }
        }));

        return this;
    }

    unsubscribe(): void {
        this.subscribers.forEach((subscriber) => {
            subscriber.unsubscribe();
        });
    }

    public static arrayAsArguments(parameters: any[]): any[] {
        return parameters;
    }
}