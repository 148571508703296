<div class="wrapper">
    <div class="row">
        <div class="col-12 p-2">
            <div class="hero-container">
                <img *ngIf="!isMobile" class="hero-image" src="assets/images/Koala-PLUS-1135x290.jpg" alt="PLUS Dagje Uit" />
                <img *ngIf="isMobile" class="hero-image mt-2" src="assets/images/Koala-PLUS-385x250.jpg" alt="PLUS Dagje Uit" />
            </div>
            <div class="discount-container">
                <div class="discount-preamb">
                    <span>{{'ATTRACTION_CARD.up_to' | translate}}</span>
                </div>
                <div class="discount-amount">
                    <span>{{highestDiscountPercentage}}%</span>
                </div>
                <div class="discount-postamb">
                    <span>{{'ATTRACTION_CARD.discount' | translate}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="container mt-2">
        <div class="row mb-1">
            <div class="col-12">
                <h2>Ontmoet de liefste koala’s bij Ouwehands Dierenpark!</h2>
            </div>
        </div>
        <div *ngIf="isMobile" class="row mb-4">
            <div class="col-12 align-content-center mt-2">
                <div class="see-trips corner-down" style="width: 100%;" (click)="navigateToApp()">
                    <i class="material-symbols-outlined">phone_iphone</i>
                    <span> Bekijk de deal in de app</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p class="mb-2">
                    Mis deze primeur niet, vanaf donderdag 25 april a.s. zijn er drie koala’s te zien bij Ouwehands Dierenpark! Dit is jouw kans om iets heel bijzonders te ervaren!
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p class="mb-2">
                    Geniet van de rustige aard van de koala's en zie hoe ze klimmen en klauteren in hun gloednieuwe verblijf. Dit zal iedereen betoveren!
                </p>
                <p class="mb-2">
                    Met PLUS Dagje Uit kun je profiteren van de hoogste kortingen op de leukste Dagjes Uit. Gebruik je gespaarde kortingsvouchers voor een magische dag in Ouwehands Dierenpark en ontdek al het moois dat Ouwehands te bieden heeft. Van speelse ontmoetingen met de dieren tot ontspannende wandelingen door groene paden, er is iets voor iedereen wat wils.
                </p>
                <p class="mb-2">
                    Reserveer nu jouw tickets voor Ouwehands Dierenpark en maak slim gebruik van de PLUS Dagje Uit korting voor een heerlijk Dagje Uit. Laat deze kans niet glippen – jouw avontuurlijke dag bij Ouwehands Dierenpark wacht op je!
                </p>
            </div>
        </div>
        <div class="row mb-4">
            <div class="{{isMobile ? 'col-12' : 'col-4'}} align-content-center mt-2">
                <div class="see-trips cta-button corner-down" style="width: 100%" (click)="navigateToMerchant()">
                    <i class="material-symbols-outlined">pets</i>
                    <span> Bestel kaartjes met korting</span>
                </div>
            </div>
            <div *ngIf="isMobile" class="col-12 align-content-center mt-2">
                <div class="see-trips corner-down" style="width: 100%;" (click)="navigateToApp()">
                    <i class="material-symbols-outlined">phone_iphone</i>
                    <span> Bekijk de deal in de app</span>
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-12">
                <p>
                    <iframe width="100%" height="{{isMobile ? '200' : '400'}}" src="https://www.youtube.com/embed/awEyR9saioY?si=Svri1G6NHVk4w6Z2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </p>
            </div>
        </div>
        <div class="row">
            <div class="row-12 mb-2">
                <p>Toch op zoek naar een ander uitje? Ontdek hier alle andere geweldige plekken waar je deze meivakantie naartoe kunt!</p>
            </div>
            <div class="col-12 align-content-center mt-2">
                <div class="see-trips" (click)="navigateToTrips()">
                    <i class="material-symbols-outlined">attractions</i>
                    <span> Bekijk de overige Dagjes Uit</span>
                </div>
            </div>
        </div>
    </div>
</div>