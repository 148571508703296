import { CookiePreferencesCode } from '../attractions/enums/cookie-preference.enum';
import { Injectable } from '@angular/core';

export interface SetCookieOptions {
  /**
   * Max cookie age in seconds
   */
  'max-age'?: number;
  'path'?: string;
}

/**
 * Key used to store selected preferences into a cookie
 */
const COOKIE_PREF_KEY = 'cookies-accepted';
/**
 * cookies expire in 2 years
 */
const COOKIE_PREF_EXP = 63072000;


@Injectable({
  providedIn: 'root',
})
export class CookieService {
  setCookie(
    key: string,
    val: number | string,
    options: SetCookieOptions = {}
  ): void {
    let cookieData = [`${key}=${val};`];

    if (typeof options['max-age'] === 'number') {
      cookieData.push(`max-age=${options['max-age']};`);
    }

    if (typeof options['path'] === 'string') {
      cookieData.push(`path=${options['path']};`);
    }

    window.document.cookie = cookieData.join(' ');
  }

  deleteCookie(key: string): void {
    this.setCookie(key, '', { 'max-age': 0, path: '/'});
  }

  getCookie(key: string): string | null {
    const cookies = window.document.cookie;
    if (cookies && key) {
      const reg = new RegExp(`(^| )${key}=([^;]+)`);

      const match = cookies.match(reg);

      if (match && match[2]) {
        return match[2];
      }
    }

    return null;
  }

  set cookiePreferences(d: CookiePreferencesCode | null) {
    this.setCookie(COOKIE_PREF_KEY, d || '', { 'max-age': COOKIE_PREF_EXP, 'path': '/' });
  }

  get cookiePreferences(): CookiePreferencesCode | null {
    const c = this.getCookie(COOKIE_PREF_KEY) || null;

    if (c) {
      return parseInt(c, 10) as CookiePreferencesCode;
    }

    return null;
  }

  // warning, won't delete HttpOnly cookies
  deleteAll(): void {
    let cookies = document.cookie.split('; ');
    for (let c = 0; c < cookies.length; c++) {
      let d = window.location.hostname.split('.');
      while (d.length > 0) {
        let cookieBase =
          encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) +
          '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
          d.join('.') +
          ' ;path=';
        let p = location.pathname.split('/');
        document.cookie = cookieBase + '/';
        while (p.length > 0) {
          document.cookie = cookieBase + p.join('/');
          p.pop();
        }
        d.shift();
      }
    }
  }
}
