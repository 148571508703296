export class ReserveTimeslot {
    constructor(
        private _timeslotUuid: string,
        private _timeslot: string,
        private _reservationAmount: number,
        private _time: string,
    ) {}

    timeslotUuid(): string {
        return this._timeslotUuid;
    }

    timeslot(): string {
        return this._timeslot;
    }

    reservationAmount(): number {
        return this._reservationAmount;
    }

    time(): string {
        return this._time;
    }


}