import {of, throwError} from "rxjs";
import {Injectable} from "@angular/core";
import {SubscriberContract} from "../../../../dispatch/contracts/subscriber.contract";
import {catchError, tap} from "rxjs/operators";
import {RAISE_ERROR} from "../../../../dispatch/events.registry";
import {PlusService} from "../../plus.service";
import {PlusProvider} from "../../plus.provider";

@Injectable()
export class AddPlusVoucherSubscriber implements SubscriberContract<void> {
    constructor(private plusService: PlusService,
                private plusProvider: PlusProvider) {
    }

    async handle(payload: string): Promise<void>{
        this.plusService.addVoucher(payload)
            .pipe(
                catchError((error) => {
                    return throwError('Error occurred while adding voucher: ', error);
                }),
                tap((response) => {
                    if (response) {
                    }
                    if (response.invalidVouchers() && response.invalidVouchers()!.length > 0) {
                        RAISE_ERROR('Voucher niet juist of al gebruikt!');
                        return of(undefined);
                    } else if (response.existingVouchers() && response.existingVouchers()!.length > 0) {
                        RAISE_ERROR('Voucher al gebruikt!');
                        return of(undefined);
                    } else {
                        this.plusProvider.latestVoucher().next(payload);
                        return of(undefined);
                    }
                })
            ).subscribe();
    }
}


