import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

@Injectable()
export class ErrorProvider {

    private errorsSubscription = new Subject<any>();

    raiseError(error: any): void {
        this.errorsSubscription.next(error);
    }

    clearErrors(): void {
        this.errorsSubscription.next([]);
    }

    errors(): Subject<any> {
        return this.errorsSubscription;
    }
}