import {Injectable} from "@angular/core";
import {
    ConsentBoolean,
    ConsentConfiguration,
    EcommerceItem,
    GoogleAnalyticsGtmService
} from "./services/google-analytics-gtm.service";
import {Reservation} from "../../models/checkout/reservation.model";
import {CheckoutProvider} from "../../providers/checkout.provider";
import {BehaviorSubject} from "rxjs";
import {distinct} from "rxjs/operators";
import {OrderOverview} from "../../models/checkout/order-overview/order-overview.model";
import {ContentPartner} from "../../models/content-partner/content-partner.model";
import {ReservationProduct} from "../../models/checkout/reservation-product.model";

@Injectable()
export class AnalyticsProvider {

    private currentReservation = new BehaviorSubject<Reservation | null>(null);

    constructor(
        private googleAnalyticsGtmService: GoogleAnalyticsGtmService,
        private checkoutProvider: CheckoutProvider
    ) {
        this.checkoutProvider.reservation().pipe(
            distinct()
        ).subscribe((reservation) => {
            this.reservationToCart(reservation);
        });

        this.checkoutProvider.contentPartner().pipe(
            distinct()
        ).subscribe((contentPartner) => this.contentPartnerView(contentPartner));
    }

    public startCheckout(): void {
        let reservation = this.currentReservation.getValue();

        if (reservation !== null) {
            this.googleAnalyticsGtmService.beginCheckout(
                this.reservationToEcommerceItems(reservation)
            );
        }
    }

    public initializeConsent(): void {
        const storedPrefs = localStorage.getItem('cookie_pref');
        if (storedPrefs) {
            const prefs = JSON.parse(storedPrefs);

            let consentObject = {} as ConsentConfiguration;

            if (prefs && prefs.functional) {
                consentObject.analytics_storage = ConsentBoolean.granted;
            }

            if (prefs && prefs.tracking) {
                consentObject.ad_personalization = ConsentBoolean.granted;
                consentObject.ad_user_data = ConsentBoolean.granted;
                consentObject.ad_storage = ConsentBoolean.granted;
            }

            this.googleAnalyticsGtmService.updateConsent(consentObject);
        }
    }

    public purchase(orderOverview: OrderOverview): void {
        this.googleAnalyticsGtmService.purchase(
            orderOverview.orderNumber(),
            orderOverview.reservation().grandTotal(),
            this.reservationToEcommerceItems(orderOverview.reservation())
        );
    }

    public contentPartnerView(contentPartner: ContentPartner): void {
        this.googleAnalyticsGtmService.viewItem(
            [this.contentPartnerToEcommerceItem(contentPartner)]
        );
    }

    private reservationToCart(newReservation: Reservation|null): void {

        const oldReservation = this.currentReservation.getValue();

        oldReservation?.aggregatedReservationProducts().forEach((oldProduct) => {
            newReservation?.aggregatedReservationProducts().forEach((newProduct) => {
                // If the products match, calculate the new quantity
                if (oldProduct.productUuid() == newProduct.productUuid()) {
                    let newEcommerceItem = this.reservationProductToEcommerceItem(newProduct);
                    let newQuantity = newProduct.quantity() - oldProduct.quantity();

                    if (newQuantity < 0) {
                        newEcommerceItem.quantity = Math.abs(newQuantity);
                        this.googleAnalyticsGtmService.removeFromCart([newEcommerceItem]);
                    }

                    if (newQuantity > 0) {
                        newEcommerceItem.quantity = Math.abs(newQuantity);
                        this.googleAnalyticsGtmService.addToCart([newEcommerceItem]);
                    }
                }
            });
        });

        this.currentReservation.next(newReservation);
    }

    private contentPartnerToEcommerceItem(contentPartner: ContentPartner): EcommerceItem {
        return {
            item_id: contentPartner.uuid(),
            item_name: contentPartner.caption(),
            discount: contentPartner.maxDiscountAmount(),
            item_brand: contentPartner.caption(),
            price: contentPartner.listPrice(),
            quantity: 1
        };
    }

    private reservationToEcommerceItems(reservation: Reservation): EcommerceItem[] {
        return reservation.reservationProducts().map(this.reservationProductToEcommerceItem);
    }

    private reservationProductToEcommerceItem(reservationProduct: ReservationProduct): EcommerceItem {
        return {
            item_id: reservationProduct.sku(),
            item_name: reservationProduct.productName(),
            discount: reservationProduct.discount(),
            item_brand: reservationProduct.contentPartnerName(),
            price: reservationProduct.price(),
            quantity: reservationProduct.quantity()
        };
    }
}