import {Injectable} from "@angular/core";
import {LoyaltyReservation} from "../../../../models/loyalty/loyalty-reservation.model";
import {SubscriberContract} from "../../../../dispatch/contracts/subscriber.contract";
import {LoyaltyProvider} from "../../loyalty.provider";
import {Reservation} from "../../../../models/checkout/reservation.model";

@Injectable()
export class LoyaltySubscriber implements SubscriberContract<LoyaltyReservation|null> {
    constructor(private loyaltyProvider: LoyaltyProvider) {}

    async handle(reservation: Reservation, amount?: number): Promise<LoyaltyReservation|null> {
        return this.loyaltyProvider.placeLoyaltyReservation(reservation, amount).toPromise();
    }
}