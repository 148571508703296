import {CheckoutProvider} from "../../../providers/checkout.provider";
import {Injectable} from "@angular/core";
import {SubscriberContract} from "../../contracts/subscriber.contract";
import {ContentApiService} from "../../../services/content-api.service";
import {Product} from "../../../models/content-partner/product.model";
import {first, tap, mergeMap} from "rxjs/operators";
import {of} from "rxjs";
import {ProductsLoadedEvent} from "../../events/content-partner/products-loaded.event";

@Injectable()
export class LoadProductsSubscriber implements SubscriberContract<Product[]> {
    constructor(
        private checkoutProvider: CheckoutProvider,
        private contentService: ContentApiService,
        private productsLoadedEvent: ProductsLoadedEvent,
    ) {}

    async handle(date: Date): Promise<Product[]> {
        return this.checkoutProvider.selectedLocation().pipe(
            first(),
            mergeMap((location) => {

                const context = this.checkoutProvider.checkoutContext().getValue();
                if (context == null || location == null) {
                    return of([]);
                }

                return this.contentService.getSubProductsByLocation(
                    context.language(),
                    context.campaignUuid(),
                    location,
                    date,
                    context.contentPartnerAlias()!
                ).pipe(
                    tap((data) => {
                        this.productsLoadedEvent.dispatch(data);
                        this.checkoutProvider.products().next(data);
                    })
                );
            })
        ).toPromise();
    }
}