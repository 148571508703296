import { skip, map } from 'rxjs/operators';
import { UiService } from 'src/app/services/ui.service';
import {
    Component,
    EventEmitter, Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import { AppService } from '../../services/app.service';
import { getQueryParam } from 'src/app/utils';
import {ContentApiService} from "../../services/content-api.service";
import {environment as env} from "../../../environments/environment";
import {Router} from "@angular/router";
import {AttractionProvider} from "../../providers/attraction.provider";

interface GenericSelectOption {
    id: string;
    label: string;
    count: number;
    icon: string;
}

@Component({
  selector: 'qup-attractions-toolbar',
  templateUrl: './attractions-toolbar.component.html',
  styleUrls: ['./attractions-toolbar.component.scss'],
})

export class AttractionsToolbarComponent implements OnInit, OnDestroy {
  @Output() seeMapClicked = new EventEmitter();
  @Input('hideMap') hideMap = false;
  selectedTypes$: Subject<any[]> = new Subject();
  subs: Subscription[] = [];
  initCategory: string | null = null;

  typeOptions: GenericSelectOption[] = [];

  form!: FormGroup;

  selectedCategory: Subject<string|null> = new Subject<string|null>();
  currentCategory: string|null = null;
  categoryLabel: string = 'dagjes uit';

  categoryIcons: Map<string, string> = new Map(
      [
          ['pretparken', 'attractions'],
          ['dierentuinen', 'pets'],
          ['actief', 'nature_people'],
          ['musea', 'museum'],
          ['wellness', 'sauna']
      ]
  );

  constructor(
    private fb: FormBuilder,
    private appService: AppService,
    private contentApiService: ContentApiService,
    private uiS: UiService,
    private router: Router,
    private attractionProvider: AttractionProvider
  ) {}

  ngOnInit(): void {
    this.checkQueryParams();

    this.form = this.fb.group({
      filters: this.fb.group({
        attractionType: [[]],
        attractionCity: [[]],
      }),
      sort: this.fb.group({
        sortBy: [null],
      }),
    });

    this.subs.push(
        this.attractionProvider.selectedCategory().subscribe((selectedCategory) => {
            this.currentCategory = selectedCategory;

            if (selectedCategory === null) {
                this.categoryLabel = 'Dagjes Uit';
            }

            this.typeOptions.forEach((category) => {
                if (category.id === selectedCategory) {
                    this.categoryLabel = category.label;
                }
            });

        }),
      this.appService.currentLanguage$
        .pipe(
          // current language always emits immediately after init sub
          // because it's derived from a BehaviourSubject, so we skip once
          skip(1)
        )
        .subscribe((_) => {
          this.uiS.resetSearchForm();
          // reinitialize form if language changed
          this.initFormData();
        })
    );

    this.initFormData();
    this.initControlListeners();
  }

  /**
   * Set category from query params if it exists
   */
  setInitCategory(): void {
    if (this.typeOptions?.length) {
      const category = this.typeOptions.find((t) => t.id === this.initCategory);

      if (category) {
        const control = this.form.get('filters.attractionType');
        (control as AbstractControl).patchValue([category.id]);
      }
    }
  }

  checkQueryParams(): void {
    const category = getQueryParam('categorySelect');

    if (category) {
      this.initCategory = category;
    }
  }

  initFormData(): void {
      this.contentApiService.getCategories(env.campaignUuid, this.appService.currentLanguage).pipe(
          map(data => {
              data = data.filter(
                  (i) => !!i?.products()?.length
              );
              return data;
          })
      ).subscribe(
          (data) => {
              this.typeOptions = data
                  .map((c) => ({ sortIndex: c.sortIndex(), id: c.categoryAlias(), label: c.categoryUuid(), count: c.products().length, icon: 'https://assets.queueup.eu/images/24x24,png,q100/' + c.iconUuid() }))
                  .sort(function (a, b) {
                      if (a.sortIndex < b.sortIndex) {
                          return -1;
                      }
                      return a.sortIndex > b.sortIndex ? 1 : 0;
                  });

              // set the init category after we got all the available categories
              this.setInitCategory();
          },
          (error) => {
              console.error('ERROR: ', error);
          }
      );
  }

  // pass through value changes dispatch
  initControlListeners(): void {
    const typesSub = this.form
      .get('filters.attractionType')
      ?.valueChanges.subscribe((val) => {
        this.selectedTypes$.next(val);
      });

    if (typesSub) {
      this.subs.push(typesSub);
    }
  }

  filterCategory(category: string|null): void {

      if (this.currentCategory === category) {
          this.attractionProvider.selectCategory(null);
          return;
      }

      this.attractionProvider.selectCategory(category);
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => s?.unsubscribe());
  }

  navigateMapPage(): void {
      this.router.navigate(['content-partner/map']);
  }

  getCategoryIcon(categoryAlias: string): string {
      if (this.categoryIcons.has(categoryAlias)) {
          return this.categoryIcons.get(categoryAlias)!;
      }

      return 'local_activity';
  }
}
