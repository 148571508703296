<div class="container bottom mt-4">
    <div class="row">
        <div class="{{isMobile ? 'col-12' : 'col-8'}}">
            <div class="row">
                <div class="col-12">
                    <h4>{{'LOGIN.download_app_title' | translate}}</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p>{{'LOGIN.download_app_1' | translate}}</p>
                    <p>{{'LOGIN.download_app_2' | translate}}</p>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <img src="/assets/images/app_store.svg" class="mr-2"/>
                    <img src="/assets/images/play_store.svg"/>
                </div>
            </div>
        </div>
        <div class="col-4 right-container" *ngIf="!isMobile">
            <div class="row">
                <div class="col-12">
                    <div class="scan-qr">
                        <img src="/assets/images/download_app_qr.svg"/>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p>{{'LOGIN.download_app_qr_1' | translate}}</p>
                    <p>{{'LOGIN.download_app_qr_2' | translate}}</p>
                </div>
            </div>
        </div>
    </div>
</div>