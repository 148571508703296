import {Component, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {PopupsEnum} from "../../../../core/enums/popups.enum";
import {ErrorProvider} from "../../../../providers/error.provider";
import {Subscription} from "rxjs";

@Component({
  selector: 'qup-error-popup',
  templateUrl: './error-popup.component.html',
  styleUrls: ['./error-popup.component.scss']
})
export class ErrorPopupComponent implements OnInit, OnDestroy {

  errors: string[] = [];
  public popupType: PopupsEnum = PopupsEnum.ERROR;
  popupsEnum = PopupsEnum;

  private _subs: Subscription[] = [];

  constructor(
      private errorProvider: ErrorProvider
  ) {}

  ngOnInit(): void {
    this._subs.push(
        this.errorProvider.errors().subscribe((errors) => {
          this.errors = errors;
        })
    );
  }

  closeOverlay(): void {
      this.errorProvider.clearErrors();
  }

  ngOnDestroy(): void {
    this._subs.forEach((s) => s?.unsubscribe());
  }
}
