<div class="cus-card-wrapper card shadow" (click)="goToAttraction(product.alias())">
    <div class="card shadow" (click)="selectable ? goToAttraction(product.alias()) : null "
         [ngClass]="{selectable: selectable, 'mobile-full-width': mobileFullWidth}">
        <div class="image">
            <img src="{{cardThumbnail()}}" alt="Card image cap" class="main-image"/>
    <!--        <ul [ngStyle]="{ visibility: product.maxDiscountAmount() > 0 ? 'visible' : 'hidden' }" class="ribbon">-->
    <!--            <div class="ribbon-container">-->
    <!--                <img class="ribbon-image" src="assets/images/discount-sticker.svg" alt="discount-sticker">-->
    <!--                <li [ngClass]="{'ribbon-list-empty-discount-sticker': emptyDiscountStickerText , 'ribbon-list': !emptyDiscountStickerText}">-->
    <!--                    <span class="discount-sticker-text up-to">{{'ATTRACTION_CARD.up_to' | translate}}</span>-->
    <!--                    <div *ngIf="product.discountStickerType() === 'FIXED_AMOUNT'">-->
    <!--                        <span class="fixed-amount-discount">{{product.maxDiscountAmount() | currency: 'EUR'}}</span>-->
    <!--                    </div>-->
    <!--                    <div *ngIf="product.discountStickerType() === 'PERCENTAGE' || product.discountStickerType() === 'FIXED_PERCENTAGE'">-->
    <!--                        <span class="discount">{{cardDiscountPercentage()}}%</span>-->
    <!--                    </div>-->
    <!--                    <span class="discount-sticker-text">{{'ATTRACTION_CARD.discount' | translate}}</span>-->
    <!--                </li>-->
    <!--            </div>-->
    <!--        </ul>-->
            <div class="discount-container">
                <div class="discount-preamb">
                    <span>{{'ATTRACTION_CARD.up_to' | translate}}</span>
                </div>
                <div class="discount-amount">
                    <span>{{cardDiscountPercentage()}}%</span>
                </div>
                <div class="discount-postamb">
                    <span>{{'ATTRACTION_CARD.discount' | translate}}</span>
                </div>
            </div>
        </div>
        <div class="card-body">
            <h3 class="card-title">{{product.caption()}}</h3>
            <div class="card-location">
                <span *ngIf="displayOnMap(product)">{{cardLocation() | translate}}</span>
                <span *ngIf="!displayOnMap(product)">{{'ATTRACTION_CARD.no_location' | translate }}</span>
            </div>
            <p class="card-description" [innerHTML]="product.shortDescription()"></p>
        </div>
    </div>
</div>
