import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent, HttpErrorResponse, HttpResponse
} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {Observable, of} from "rxjs";
import {catchError, first, tap} from "rxjs/operators";
import {environment as env} from "../../environments/environment";
import {PlatformService} from "../services/platform.service";
import {flatMap} from "rxjs/internal/operators";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private platformService: PlatformService
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const authToken = localStorage.getItem("token");

        if (authToken && !request.url.includes(".json") && !request.headers.get("skip")) {
            request = request.clone({
                setHeaders: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            });
        }

        return next.handle(request).pipe(tap((response) => {
                if (response instanceof HttpResponse && response.headers.has("x-qup-platform-authorization")) {
                    const token = response.headers.get("x-qup-platform-authorization");
                    localStorage.setItem("token", token ?? "");
                }
            }),
            catchError((err, caught) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401 || err.status == 403) {

                        if (err.url?.indexOf('wallet') !== -1) {
                            localStorage.removeItem('plus_token');
                            throw err;
                        }

                        localStorage.removeItem('token');
                        localStorage.removeItem('voucher_session_uuid');

                        return this.platformService.getSessionToken(env.campaignUuid).pipe(
                            flatMap((data) => {
                                localStorage.setItem("token", data.response.token);

                                request = request.clone({
                                    headers: request.headers.set("Authorization", `Bearer ${data.response.token}`)
                                });

                                return next.handle(request);
                            }),
                            catchError((err, tokenErrorCaught) => {
                                return tokenErrorCaught;
                            }),
                            first()
                        );
                    }
                }

                throw err;
            })
        );
    }
}