<div class="web-app" *ngIf="embedded">
  <ng-template #dynamicLayoutComponent></ng-template>
  <qup-error-popup></qup-error-popup>
  <router-outlet></router-outlet>
  <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#ffffff" type = "ball-spin-fade" [fullScreen] = "true"><p style="color: white" > {{'LAYOUT.spinner_text' | translate}}  </p></ngx-spinner>
</div>
<div class="web-app" *ngIf="!embedded">
  <ng-template #dynamicLayoutComponent></ng-template>
  <qup-error-popup></qup-error-popup>
  <qup-header></qup-header>
  <router-outlet></router-outlet>
  <qup-footer></qup-footer>
  <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#ffffff" type = "ball-spin-fade" [fullScreen] = "true"><p style="color: white" > {{'LAYOUT.spinner_text' | translate}}  </p></ngx-spinner>
</div>