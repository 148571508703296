import { Component, Input, Output, SimpleChanges, OnChanges, EventEmitter } from '@angular/core';

@Component({
  selector: 'qup-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnChanges {

  @Input() total!: number;
  @Input() pageSize!: number;
  @Input() index!: number;
  @Input() isMobile: boolean = true;
  public totalPages!: number;

  @Output() PageChange = new EventEmitter<{ total: number, pageSize: number, index: number }>();


  constructor() {
  }

  ngOnChanges({ total, pageSize, index }: SimpleChanges): void {
    this.totalPages = Math.ceil(this.total / this.pageSize);
  }

  nextPage(): void {
    if (this.totalPages > this.index) {
      this.changePage(Math.min(this.totalPages, this.index + 1));
    }
  }

  previousPage(): void {
    if (this.totalPages > 1) {
      this.changePage(Math.max(1, this.index - 1));
    }
  }

  changePage(pageNumber: number): void {
    this.index = pageNumber;
    this.PageChange.emit({ total: this.total, pageSize: this.pageSize, index: this.index });
  }

}
