import {Injectable} from "@angular/core";
import {environment as env} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Reservation} from "../models/checkout/reservation.model";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {ReservationProduct} from "../models/checkout/reservation-product.model";
import {OrderOverview} from "../models/checkout/order-overview/order-overview.model";
import {Voucher} from "../interfaces/content-api.interface";


export interface CreateReservationRequest {
    Date: string,
    VisitGuarantee: boolean,
    Lines: ProductEntry[],
    VoucherSessionUuid?: string|null,
    CampaignUuid: string,
    LoyaltyReservationUuid: string|null,
    Codes: Voucher[],

    // Remove this shit
    Language: string,
    SessionToken?: string,
    Store: string
}

export interface ProductEntry {
    ProductUuid: string,
    Quantity: number,
    Price?: number,
    ProductName?: string,
    ProductType?: string,
    Discount?: number
}

interface ProductEntryResponse {
    ProductUuid: string,
    Quantity: number,
    Price: number,
    ProductName: string,
    ProductType: string,
    Discount: number,
    Sku: string
}

export interface OrderOverviewByTransactionResponse {
    PaymentState: string;
    OrderNumber: string;
    Purchase: ReservationPurchaseResponse;
}

interface ReservationPurchaseResponse {
    BookingCost: number,
    LocationName: string,
    LocationUuid: string,
    ContentPartnerUuid: string,
    ContentPartnerName: string,
    VoucherSessionUuid: string,
    OrderReservationUuid: string,
    ReservedProducts: ProductEntryResponse[],
    TotalAmount: number,
    TotalAmountWithDiscount: number,
    TotalDiscount: number,
    VisitDay: string,
    VisitGuarantee: number,
}

interface ReservationResponse {
    OrderReservationUuid: string,
    Conditions: string[],
    BillingDetail: null,
    Purchase: ReservationPurchaseResponse,
    Summary: {
        LoyaltyReservationUuid: string,
        MaxApplicableVouchers: number,
        Discount: number,
        UsedVouchers: [{
            Code: string
        }],
        TimeslotTime: string,
        TimeslotUuid: string,
    },
    VouchersSessions: {
        Uuid: string
    }
}

interface RemoveVouchersFromSessionRequest {
    VoucherSessionUuid: string,
    CampaignUuid: string,
    Language: string,
    Codes: string[]
}

interface GetVouchersFromSessionResponse {
    codes: {
        code: string,
        origin: string
    }[]
}

interface AddVouchersToSessionRequest {
    VoucherSessionUuid: string,
    CampaignUuid: string,
    AcceptLanguage: string,
    Codes: {
        Code: string,
        Origin: string
    }[]
}

interface ExchangeVouchersRequest {
    CampaignId: string,
    Vouchers: string[]
}

@Injectable({
    providedIn: 'root'
})
export class CheckoutService {
    constructor(
        private http: HttpClient
    ) { }

    retrieveReservation(language: string): Observable<Reservation> {
        return this.http.get<ReservationResponse>(`${env.contentApiUri}/session/reservation`, {
            params: {
                'campaign': env.campaignUuid,
                'language': language
            }
        }).pipe(
            map((response) => this.mapReservationResponseToReservation(response))
        );
    }

    createReservation(request: CreateReservationRequest): Observable<Reservation> {
        return this.http.post<ReservationResponse>(`${env.contentApiUri}/session/reservation`, request).pipe(
            map((response) => this.mapReservationResponseToReservation(response))
        );
    }

    getOrderOverviewByTransaction(campaign: string, language: string, transactionId: string): Observable<OrderOverview> {
        return this.http.get<OrderOverviewByTransactionResponse>(`${env.contentApiUri}/session/order/status`, {
            params: {
                campaign: campaign,
                language: language,
                transaction: transactionId,
            }
        }).pipe(
            map((data) => {
                    return this.mapOrderOverview(data);
                }
            )
        );
    }

    getVouchersFromSession(voucherSessionUuid: string): Observable<GetVouchersFromSessionResponse> {
        return this.http.get<GetVouchersFromSessionResponse>(`${env.contentApiUri}/session/vouchers/${voucherSessionUuid}`);
    }

    removeVouchersFromSession(request: RemoveVouchersFromSessionRequest): Observable<void> {
        return this.http.delete<void>(`${env.contentApiUri}/session/vouchers`, {
            body: request
        });
    }

    addVouchersToSession(request: AddVouchersToSessionRequest): Observable<any> {
        return this.http.post<void>(`${env.contentApiUri}/session/vouchers`, request);
    }

    exchangeVouchers(request: ExchangeVouchersRequest): Observable<any> {
        return this.http.post<void>(`${env.contentApiUri}/plus/claim/voucher`, request);
    }

    private mapOrderOverview(data: OrderOverviewByTransactionResponse): OrderOverview {
        return new OrderOverview(
            data.PaymentState,
            data.OrderNumber,
            this.mapOrderOverviewResponseToReservation(data.Purchase)
        );
    }

    private mapProductEntryResponseToReservationProduct(response: ReservationPurchaseResponse): ReservationProduct[] {
        let products: ReservationProduct[] = [];

        response.ReservedProducts.forEach((product) => {
            products.push(new ReservationProduct(
                product.ProductUuid,
                product.Quantity,
                product.Price,
                product.ProductName,
                product.Sku,
                product.ProductType,
                product.Discount,
                !response.VisitDay ? new Date() : new Date(response.VisitDay),
                response.LocationName,
                response.ContentPartnerName,
                response.ContentPartnerUuid
            ));
        });

        return products;
    }

    private mapOrderOverviewResponseToReservation(response: ReservationPurchaseResponse): Reservation {
        const products = this.mapProductEntryResponseToReservationProduct(response);

        return new Reservation(
            response.OrderReservationUuid,
            response.ContentPartnerUuid,
            response.LocationUuid,
            response.TotalAmount,
            response.TotalDiscount,
            response.BookingCost,
            response.VisitGuarantee,
            0,
            products,
            response.VoucherSessionUuid,
            response.VisitDay != "" ? new Date(response.VisitDay) : undefined,
            []
        );
    }

    private mapReservationResponseToReservation(response: ReservationResponse): Reservation {

        return new Reservation(
            // little bit inconsistent
            response.OrderReservationUuid ? response.OrderReservationUuid : response.Purchase.OrderReservationUuid,
            response.Purchase.ContentPartnerUuid,
            response.Purchase.LocationUuid,
            response.Purchase.TotalAmount,
            response.Purchase.TotalDiscount,
            response.Purchase.BookingCost,
            response.Purchase.VisitGuarantee,
            response.Summary.MaxApplicableVouchers,
            this.mapProductEntryResponseToReservationProduct(response.Purchase),
            // little bit inconsistent
            response.VouchersSessions ? response.VouchersSessions.Uuid : response.Purchase.VoucherSessionUuid,
            response.Purchase.VisitDay != "" ? new Date(response.Purchase.VisitDay) : undefined,
            response.Summary.UsedVouchers.map(v => v.Code),
            response.Summary.LoyaltyReservationUuid != "" ? response.Summary.LoyaltyReservationUuid : undefined,
            response.Summary.TimeslotUuid != "" ? response.Summary.TimeslotUuid : undefined,
            response.Summary.TimeslotTime != "" ? response.Summary.TimeslotTime : undefined
        );
    }
}