import {ContentPartner} from "../content-partner/content-partner.model";

export class Category {
    constructor(
        private _categoryAlias: string,
        private _sortIndex: number,
        private _categoryUuid: string,
        private _caption: string,
        private _iconUuid: string,
        private _products: ContentPartner[]
    ) {}

    categoryAlias(): string {
        return this._categoryAlias;
    }

    iconUuid(): string {
        return this._iconUuid;
    }

    sortIndex(): number {
        return this._sortIndex;
    }

    categoryUuid(): string {
        return this._categoryUuid;
    }

    caption(): string {
        return this._caption;
    }

    products(): ContentPartner[] {
        return this._products;
    }


}