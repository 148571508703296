import {Injectable} from "@angular/core";
import {ReplaySubject} from "rxjs";
import {StandardEvent} from "../standard.event";


@Injectable()
export class CheckoutResetEvent extends StandardEvent<boolean> {

    constructor() {
        super(new ReplaySubject<boolean>(1))
    }

    dispatch(): void {
        this.subject.next(true);
    }
}