import {Component, OnInit} from "@angular/core";
import {AttractionProvider} from "../../providers/attraction.provider";
import {ContentPartner} from "../../models/content-partner/content-partner.model";
import {Subscription} from "rxjs";
import {UiService} from "../../services/ui.service";
import {Router} from "@angular/router";

@Component({
    selector: 'qup-see-map',
    templateUrl: '../../templates/promotion/promotion.component.html',
    styleUrls: ['../../templates/promotion/promotion.component.scss']
})
export class PromotionComponent implements OnInit {

    highlightedContentPartners: string[] = [
        'ouwehands-dierenpark',
    ];

    merchants: ContentPartner[] = [];

    private _subs: Subscription[] = [];
    highestDiscountPercentage = 0;

    isMobile: boolean = false;

    constructor(
        private attractionMainProvider: AttractionProvider,
        private uiS: UiService,
        private router: Router
    ) {
        this._subs.push(
            this.uiS.isMobileOverlay$.subscribe((isMobile) =>
                this.isMobile = isMobile
            )
        );
    }

    navigateToMerchant(): void {
        this.router.navigate(['content-partner/', this.merchants[0].alias()])
    }

    navigateToTrips(): void {
        this.router.navigate(['attractions']);
    }

    navigateToApp(): void {
        window.open('funtripsmobile://dagjeuitsparen.nl')
    }

    ngOnInit() {
        this.attractionMainProvider.loadAllProducts().subscribe((contentPartner) => {
            contentPartner.forEach((cp) => {
                if (this.highlightedContentPartners.includes(cp.alias())) {
                    this.merchants.push(cp);
console.log(cp)
                    if (cp.maxDiscountPercentageAsInteger() > this.highestDiscountPercentage) {
                        this.highestDiscountPercentage = cp.maxDiscountPercentageAsInteger();
                    }
                }
            });
        });
    }

}