import {DateTime} from "luxon";

export class Timeslot {
    constructor(
        private _availableStock: number,
        private _maxStock: number,
        private _timeslotFrom: string,
        private _timeslotTo: string,
        private _timeslotName: string,
    ) {}

    availableStock(): number {
        return this._availableStock;
    }

    maxStock(): number {
        return this._maxStock;
    }

    timeslotFrom(): string {
        return this._timeslotFrom;
    }

    timeslotName(): string {
        return this._timeslotName;
    }

    timeslotFancyName(): string {
        let fromTime = DateTime.fromISO(this._timeslotFrom, {zone: 'UTC'}).toFormat('HH:mm');
        let toTime = DateTime.fromISO(this._timeslotTo, { zone: 'UTC'}).toFormat('HH:mm');

        if (fromTime == toTime) {
            return 'Aankomsttijd ' + fromTime;
        }

        return 'Van ' + fromTime + ' tot ' + toTime;
    }

}