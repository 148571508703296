import {PlusWalletVoucher} from "./get-plus-wallet.model";

export class PlusWallet {
    constructor(
        private _balance: number,
        private _vouchers: PlusWalletVoucher[],
    ) {
    }

    vouchers(): PlusWalletVoucher[] {
        return this._vouchers;
    }

    balance(): number {
        return this._balance;
    }

    euroBalance(): number {
        return this._balance / 2;
    }
}