<div class="calendar-payoff mb-4 mt-2">
    <div class="row">
        <div class="col-2" *ngIf="embedded">
            <a href="?closeWebframe=true">
                <i _ngcontent-kqb-c111="" class="material-symbols-outlined">arrow_back</i>
            </a>
        </div>
    </div>
    <p>Normaal kost een ticket <s class="from-price"><span>&euro;{{highestUnitPrice | number: '1.2-2' }}</span></s>, nu vanaf <strong class="to-price">&euro;{{lowestEffectivePrice | number: '1.2-2' }}</strong></p>
    <p>Je bespaart dus tot wel <strong>{{contentPartner?.maxDiscountPercentageAsInteger()}}%!</strong> </p>
</div>
<div class="row calendar-legend mb-4" *ngIf="showCalendar">
    <div class="col-12" >
        <h5>{{ 'CHECKOUT.calendar_legend_title' | translate }}</h5>
        <ul>
            <li *ngFor="let item of legend">
                <div class="color {{item.color}}"></div>
                <div class="discount">{{ 'CHECKOUT.calendar_legend_from' | translate }} &euro;{{highestUnitPrice - item.max_discount | number: '1.2-2'}}</div>
            </li>
        </ul>
    </div>
</div>
<div class="calendar-container" *ngIf="showCalendar">
    <div class="month-bar col-12">
        <ul class="calendar-monthbar">
            <li class="left chevron active" (click)="previousMonth()">
                <i _ngcontent-kqb-c111="" class="material-symbols-outlined">chevron_left</i>
            </li>
            <li class="month-title">
                <span>{{monthTitle}}</span>
            </li>
            <li class="right chevron active" (click)="nextMonth()">
                <i _ngcontent-kqb-c111="" class="material-symbols-outlined">chevron_right</i>
            </li>
        </ul>
    </div>
    <div class="calendar col-12 mb-4" (touchstart)="swipeCalendarMonth($event, 'start')" (touchend)="swipeCalendarMonth($event, 'end')">
        <ul class="calendar-header">
            <li *ngFor="let dayOfTheWeek  of daysOfTheWeek">
                <span>{{dayOfTheWeek}}</span>
            </li>
        </ul>

        <div *ngFor="let calendarRow of calendarGrid">
            <ul class="calendar-row" >
                <li *ngFor="let calendarEntry of calendarRow" class="{{calendarEntry.date().hasSame(today, 'day') ? 'today':''}}">
                    <div class="day-container
                        {{selectedDay.getValue()?.hasSame(calendarEntry.date(), 'day') ? 'selected' : ''}}
                        {{calendarEntry.active() ? 'active' : ''}}
                        {{isCurrentMonth(calendarEntry.date()) ? 'current-month' : ''}}
                        {{calendarEntry.calendarItem()?.discountAmount()??0 ? 'has-discount' : ''}}
                        {{colorMap.get(calendarEntry.calendarItem()?.discountAmount()??0)}}"

                    (click)="calendarEntry.hasCalendarItem() && isCurrentMonth(calendarEntry.date()) ? selectDay(calendarEntry.date()) : ''">
                        <span class="day-label {{calendarEntry.date().hasSame(today, 'day') ? 'today':''}}">{{calendarEntry.date().day}}</span>
                        <strong class="effective-price" *ngIf="calendarEntry.hasCalendarItem()" >
                            &euro;{{calendarEntry.calendarItem()?.effectivePrice() | number: '1.2-2'}}
                        </strong>
                        <strong *ngIf="!calendarEntry.hasCalendarItem()">&nbsp;</strong>
                    </div>
                </li>
            </ul>
        </div>
    </div>

    <div class="row" *ngIf="checkDate();">
        <div class="col-12">
            <div class=" alert alert-warning" role="alert">
                <span>{{"CHECKOUT.late_warning" | translate}}</span>
            </div>
        </div>
    </div>
</div>