<div class="container mt-4 mb-4 p-0">

  <div class="row p-0 cookie_container">
    <div class="col-md-8 p-2">
      <div class="cookie_header col-12 px-0 mb-4 justify-content-between">
        <div>{{'COOKIES.cookie_statement_title' | translate}}</div>
        <div>
          <button (click)="showPref()" type="button" class="btn btn-primary">{{'COOKIES.change_preference' | translate}}</button>
        </div>
      </div>
      <p [innerHTML]="'COOKIES.cookie_statement_paragraph_1' | translate">.
      </p>
      <p>
        {{'COOKIES.cookie_statement_paragraph_2' | translate}}
      </p>
      <h3 class="cookie_subheader">{{'COOKIES.cookie_statement_question_1' | translate}}</h3>
      <p>
        {{'COOKIES.cookie_statement_question_1_text_1' | translate}}
      </p>
      <p>
        {{'COOKIES.cookie_statement_question_1_text_2' | translate}}
      </p>
      <h3 class="cookie_subheader">{{'COOKIES.cookie_statement_question_2' | translate}}</h3>
      <p>
        {{'COOKIES.cookie_statement_question_2_text' | translate}}
      </p>
      <h3 class="cookie_subheader">{{'COOKIES.cookie_statement_question_3' | translate}}</h3>
      <p>
        {{'COOKIES.cookie_statement_question_3_text' | translate}}
      </p>
      <h3 class="cookie_subheader">{{'COOKIES.cookie_statement_question_4' | translate}}</h3>
      <p>
        {{'COOKIES.cookie_statement_question_4_text' | translate}}
      </p>
      <h3 class="cookie_subheader">{{'COOKIES.cookie_statement_question_5' | translate}}</h3>
      <p>
        {{'COOKIES.cookie_statement_question_5_text_1' | translate}}
      </p>
      <p>
        {{'COOKIES.cookie_statement_question_5_text_2' | translate}}
      </p>
      <p>
        {{'COOKIES.cookie_statement_question_5_text_3' | translate}}
      </p>
      <h3 class="cookie_subheader">{{'COOKIES.cookie_statement_question_6' | translate}}</h3>
      <p>
        {{'COOKIES.cookie_statement_question_6_text_1' | translate}}
      </p>
      <p>
        {{'COOKIES.cookie_statement_question_6_text_2' | translate}}
      </p>
      <h3 class="cookie_subheader">{{'COOKIES.cookie_statement_question_7' | translate}}</h3>
      <p>
        {{'COOKIES.cookie_statement_question_7_text_1' | translate}}
      </p>
      <p [innerHTML]="'COOKIES.cookie_statement_question_7_text_2' | translate">
      </p>
      <h3 class="cookie_subheader">{{'COOKIES.cookie_statement_question_8' | translate}}</h3>
      <p>
        {{'COOKIES.cookie_statement_question_8_text' | translate}}
      </p>
      <h3 class="cookie_subheader">{{'COOKIES.cookie_statement_question_9' | translate}}</h3>
      <p [innerHTML]="'COOKIES.cookie_statement_question_9_text' | translate">
      </p>
      <p>
        {{'COOKIES.cookie_statement_note' | translate}}
      </p>
    </div>
    <div class="col-md-4 p-2 text-center">
      <img src="../../../assets/images/Cookies.png">
    </div>
  </div>
</div>
