import {Injectable} from "@angular/core";
import {LoyaltyReservation} from "../../../../models/loyalty/loyalty-reservation.model";
import {ReplaySubject} from "rxjs";
import {StandardEvent} from "../../../../dispatch/events/standard.event";

@Injectable()
export class LoyaltyReservationPlacedEvent extends StandardEvent<LoyaltyReservation> {

    constructor() {
        super(new ReplaySubject<LoyaltyReservation>());
    }

    dispatch(loyaltyReservation: LoyaltyReservation): void {
        this.subject.next(loyaltyReservation);
    }
}