import {Component, OnDestroy, OnInit} from "@angular/core";
import {Reservation} from "../../../../models/checkout/reservation.model";
import {CheckoutProvider} from "../../../../providers/checkout.provider";
import {PlusProvider} from "../../plus.provider";
import {BehaviorSubject, of, Subscription} from "rxjs";
import {PlusEventRegistry} from "../../plus-event.registry";
import {PlusWallet} from "../../models/plus-wallet.model";
import {catchError, debounceTime, first, map, tap} from "rxjs/operators";
import {WalletChangeQuantityEvent} from "../../dispatch/events/wallet-change-quantity.event";
import {flatMap} from "rxjs/internal/operators";
import {Voucher} from "../../../../interfaces/content-api.interface";
import {RAISE_ERROR} from "../../../../dispatch/events.registry";
import {GetPlusWalletEvent} from "../../dispatch/events/get-plus-wallet.event";
import {TranslateService} from "@ngx-translate/core";
import {formatNumber} from "@angular/common";

@Component({
    selector: 'qup-discount-component',
    templateUrl: '../../templates/discount/wallet-discount.component.html',
    styleUrls: ['../../templates/discount/wallet-discount.component.scss']
})
export class WalletDiscountComponent implements OnInit, OnDestroy {

    public reservation: Reservation|null = null;

    public wallet: PlusWallet|null = null;

    private _subs: Subscription[] = [];

    public currentVoucher: string = '';

    public min = 0;

    public max = 0;

    public step = 0.5;

    public voucherValue = 5;

    public currentValue = 0;

    public desiredDiscount: BehaviorSubject<number> = new BehaviorSubject(0);

    constructor(
        private checkoutProvider: CheckoutProvider,
        private plusProvider: PlusProvider,
        private plusEventRegistry: PlusEventRegistry,
        private getWalletEvent: GetPlusWalletEvent,
        private walletChangeQuantityEvent: WalletChangeQuantityEvent,
        private translateService: TranslateService
    ) { }

    ngOnInit(): void {
        this.getWalletEvent.dispatch();
        this._subs.push(
            this.checkoutProvider.reservation().subscribe((reservation) => {
                if (this.wallet && reservation) {
                    this.max = reservation.maxApplicableVouchers();
                    this.currentValue = reservation.discountTotal();
                }
                this.reservation = reservation;
            }),
            this.plusProvider.wallet().subscribe((wallet) => {
                this.wallet = wallet;
            }),
            this.desiredDiscount.pipe(debounceTime(1000)).subscribe((desiredDiscount) => {
                if (!this.reservation) {
                    return;
                }
                this.walletChangeQuantityEvent.dispatch([this.reservation, desiredDiscount]);
            })
        );
    }

    ngOnDestroy(): void {
        this._subs.forEach((sub) => sub.unsubscribe());
    }

    decreaseDiscount(): void {
        const newVoucherAmount = (this.currentValue- this.step) / this.step;

        if (newVoucherAmount <= 0) {
            return;
        }
        this.currentValue = newVoucherAmount * this.step;
        this.desiredDiscount.next(newVoucherAmount);
    }

    increaseDiscount(): void {
        const newVoucherAmount = (this.currentValue + this.step) / this.step;
        if (newVoucherAmount > this.max || newVoucherAmount > (this.wallet?.balance() ?? 0)) {
            return;
        }
        this.currentValue = newVoucherAmount * this.step;
        this.desiredDiscount.next(newVoucherAmount);
    }

    hasMaximumDiscount(): boolean {
        let currentVouchers = this.currentValue / this.step;
        return currentVouchers === this.max || currentVouchers === (this.wallet?.balance() ?? 0);
    }

    getMaximumFullVouchers(): number {
        return Math.ceil(((this.reservation?.maxApplicableVouchers()?? 0)* this.step) / this.voucherValue);
    }

    getCurrentAmountOfVouchers(): number {
        return Math.ceil(this.currentValue / this.voucherValue);
    }

    maximumVouchersUsed(): boolean {
        if (this.getCurrentAmountOfVouchers() == this.getMaximumFullVouchers()) {
            return true;
        }

        return false;
    }

    addVouchersToSession(): void {
        if (this.maximumVouchersUsed() || this.currentVoucher == '') {
            this.currentVoucher = '';
            return;
        }

        this.checkoutProvider.exchangeVouchers([ this.currentVoucher ]).pipe(
            map((result) => {
                return result.map((code) => {
                    return {
                        code: code,
                        origin: 'MANUAL'
                    } as Voucher;
                });
            }),
            catchError((error) => {
                RAISE_ERROR('Voucher niet juist of al gebruikt!');
                return of(undefined);
            }),
            flatMap((result) => {
                if (result === undefined) {
                    return of(this.reservation);
                }
                return this.checkoutProvider.replayReservation(undefined, undefined, undefined, result);
            }),
            tap((result) => {
                this.currentVoucher = '';
            }),
            first()
        ).subscribe();
    }

    discountDescription(): string {
        return this.translateService.instant('CHECKOUT.discount_description').replace('%amount%', formatNumber(this.reservation!.discountTotal(), 'nl_NL', '1.2-2'));
    }
}