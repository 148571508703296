import {StorageNS, StorageService} from 'src/app/services/storage.service';
import {TranslateService} from '@ngx-translate/core';
import {debounceTime} from 'rxjs/operators';
import {Injectable, OnDestroy} from '@angular/core';
import {environment as env} from '../../environments/environment';
import {BehaviorSubject, Subscription} from 'rxjs';
import {CookiePreferences} from '../interfaces/cookies.interface';
import {CookieService} from './cookie.service';
import {CookiePreferencesCode} from '../attractions/enums/cookie-preference.enum';
import {LocaleEnum} from "../../environments/enums/locale.enum";


@Injectable()
export class AppService implements OnDestroy {
  _subs: Subscription[] = [];
  _cookiePreferences: CookiePreferences | null;

  private _currentLanguage$ = new BehaviorSubject(LocaleEnum.nl_NL);
  currentLanguage$ = this._currentLanguage$
    .asObservable()
    .pipe(debounceTime(5));

  get currentLanguage(): LocaleEnum {
    return this._currentLanguage$.value;
  }

  set currentLanguage(locale: LocaleEnum) {
    if (locale !== this.currentLanguage) {
      this._currentLanguage$.next(locale);
    }
  }

  /**
   * sets the current language and stores the selection in local storage
   * */
  selectCurrentLanguage(currLanguage: LocaleEnum): void {
    this.currentLanguage = currLanguage;
    this.sS.selectedLanguage = this.currentLanguage;
  }

  get cookiePreferences(): CookiePreferences {
    return this._cookiePreferences;
  }

  set cookiePreferences(data: CookiePreferences) {
    if (data !== this._cookiePreferences) {
      this._cookiePreferences = data;
      // save preferences to local storage
      this.saveCookiePreferences();
    }
  }



  constructor(
    private transS: TranslateService,
    private sS: StorageService,
    private cS: CookieService
  ) {
    this._cookiePreferences = this.sS.cookiePreferences;

    this.currentLanguage = LocaleEnum.nl_NL;

    // HERE WE PASS THE CORRECT LOCALE NAMING TO THE ngx-trasnlate SERVICE
    this._subs.push(
      this.currentLanguage$.subscribe((l) =>
        this.transS.use(l)
      )
    );
  }


  get currentCalendarLocale(): string | null {
    const l = this.currentLanguage;
    const cL = l.substring(0,2);

    return cL || null;
  }

  saveCookiePreferences(): void {
    // save to local storage
    this.sS.cookiePreferences = this._cookiePreferences;

    // save to cookie
    if (this._cookiePreferences) {
      if (this.cookiePreferences?.tracking) {
        this.cS.cookiePreferences = CookiePreferencesCode.ALL;
      } else if (this.cookiePreferences?.functional) {
        this.cS.cookiePreferences = CookiePreferencesCode.FUNCTIONAL
      } else if (this.cookiePreferences?.technical) {
        this.cS.cookiePreferences = CookiePreferencesCode.MAIN;
      }
    }

    // reload the page so google tag manager can properly initialize
    window.location.reload();
  }

  // if cookie expired purge all local data
  purgeAllAndReload() {
    this.sS.deleteAll();
    this.cS.deleteAll();

    window.location.href = window.location.origin;
  }

  /**
   * Remove cookie preferences from local storage and cookies
  */
  deleteCookiePreferences(): void {
    this._cookiePreferences = null;
    this.sS.removeItem(StorageNS.COOKIE_PREFERENCES);
  }


  ngOnDestroy() {
    this._subs.forEach((s) => s?.unsubscribe());
  }
}
