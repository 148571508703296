<div class="container">
    <div class="row">
        <div class="col-12">
            <div>
                <div class="row">
                    <div class="col-12">
                        <h3>{{'LOGIN.recover_password_title' | translate}}</h3>
                    </div>
                </div>
            </div>
            <form [formGroup]="recoverForm" *ngIf="!environment.federatedLogin && recoverPasswordState === RecoverPasswordState.INITIAL" (ngSubmit)="submitRecover()">
                <div class="row">
                    <div class="col-12">
                        <p class="mb-2">{{'LOGIN.forgot_text_1' | translate}}</p>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-12">
                        <label>{{ 'LOGIN.email_label' | translate }}</label>
                        <input type="email" name="email" formControlName="email">
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button
                                type="submit" [disabled]="!recoverForm.valid" class="btn btn-lg login-button">
                            {{'LOGIN.recover_password_button' | translate}}
                        </button>
                    </div>
                </div>
            </form>

            <form [formGroup]="confirmation" *ngIf="recoverPasswordState === RecoverPasswordState.CONFIRMATION" (ngSubmit)="confirmRecovery()">
                <div class="row">
                    <div class="col-12">
                        <p class="mb-2">{{'LOGIN.forgot_text_1' | translate}}</p>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-12">
                        <label>{{ 'REGISTRATION.confirm_account_code_label' | translate }}</label>
                        <input type="text" name="confirmationCode" formControlName="confirmationCode">
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-12">
                        <label>{{ 'REGISTRATION.password_label' | translate }}</label>
                        <input type="password" name="password" formControlName="password">
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-12">
                        <label>{{ 'REGISTRATION.confirm_password_label' | translate }}</label>
                        <input type="password" name="repeatPassword" formControlName="repeatPassword">
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button
                                type="submit" [disabled]="!confirmation.valid" class="btn btn-lg login-button">
                            {{'LOGIN.recover_password_button' | translate}}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>