export enum CookiePreferencesCode {
  /**
   * Only main cookies and local storage data is accepted
   * necessary for proper app functioning
   */
  MAIN = 1,
  /**
   * Main && functional cookies and local storage data is accepted.
   * Necessary for Google Tag Manager
   */
  FUNCTIONAL,
  /**
   * All data is allowed to be used.
   * E-commerce event and Facebook pixel via GTM.
   */
  ALL
}
