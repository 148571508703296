import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import {
  CookiePreferences,
  CookieControls,
  CookieSettings,
} from 'src/app/interfaces/cookies.interface';

@Component({
  selector: 'qup-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss'],
})
export class PreferencesComponent implements OnInit, OnDestroy {
  _subs: Subscription[] = [];

  @Input() initConfig!: CookiePreferences;
  @Output() acceptSelectedClicked = new EventEmitter<CookieSettings>();
  @Output() acceptAllClicked = new EventEmitter();
  @Output() closeClicked = new EventEmitter();

  performance = 'SMOOTHLY';

  controls: CookieControls[] = [
    {
      name: 'COOKIES.preference_technical_label',
      desc:
       'COOKIES.preference_technical_description',
      disabled: true,
      default: true,
      key: 'technical',
    },
    {
      name: 'COOKIES.preference_functional_label',
      desc:
       'COOKIES.preference_functional_description',
      key: 'functional',
    },
    {
      name: 'COOKIES.preference_tracking_label',
      desc:
       'COOKIES.preference_tracking_description',
      key: 'tracking',
    },
  ];

  // initialize form
  form!: FormGroup;

  constructor(
    private fb: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.form = this.fb.group(
      this.controls.reduce((controls, c) => {
        const val = (this.initConfig && this.initConfig[c.key]) || !!c.default;

        controls[c.key] = [val];

        return controls;
      }, {} as any)
    );

    this.listenToFormControls();
  }

  // if tracking is enabled functional should be also enabled and vice versa
  listenToFormControls(): void {
    const functionalF = this.form.get('functional') as AbstractControl;
    const trackingF = this.form.get('tracking') as AbstractControl;

    this._subs.push(
      functionalF.valueChanges.subscribe((val) => {
        if (!val) {
          trackingF?.patchValue(false, { emitEvent: false });
        }
      })
    );

    this._subs.push(
      trackingF.valueChanges.subscribe((val) => {
        if (val) {
          functionalF?.patchValue(true, { emitEvent: false });
        }
      })
    );
  }

  formSubmit(): void {
    this.acceptSelectedClicked.next(this.form.getRawValue());
  }

  ngOnDestroy(): void {
    this._subs.forEach((s) => s?.unsubscribe());
  }
}
