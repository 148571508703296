import {ModuleWithProviders, NgModule} from "@angular/core";
import {ReservationSubscriber} from "./subscribers/checkout/reservation.subscriber";
import {TimeslotsSubscriber} from "./subscribers/timeslots/timeslots-subscriber";
import {QuantityChangeEvent} from "./events/checkout/quantity-change.event";
import {SelectTimeslotEvent} from "./events/timeslots/select-timeslot.event";
import {ReservationPlacedEvent} from "./events/checkout/reservation-placed.event";
import {CalendarDateSelectEvent} from "./events/content-partner/calendar-date-select.event";
import {ResetCheckoutSubscriber} from "./subscribers/checkout/reset-checkout.subscriber";
import {EventsDispatcher} from "./events.dispatcher";
import {EventRegistry} from "./events.registry";
import {SelectLocationEvent} from "./events/content-partner/select-location.event";
import {SelectTimeslotSubscriber} from "./subscribers/timeslots/select-timeslot.subscriber";
import {SelectLocationSubscriber} from "./subscribers/content-partner/select-location.subscriber";
import {CheckCheckoutConditionsSubscriber} from "./subscribers/checkout/check-checkout-conditions.subscriber";
import {CheckoutSubmitEvent} from "./events/checkout/checkout-submit.event";
import {NavigateBillingDetailsSubscriber} from "./subscribers/checkout/navigate-billing-details.subscriber";
import {CheckoutEnteredEvent} from "./events/checkout/checkout-entered.event";
import {RebuildCheckoutSubscriber} from "./subscribers/checkout/rebuild-checkout.subscriber";
import {LoadContentPartnerSubscriber} from "./subscribers/content-partner/load-content-partner-subscriber";
import {LoadProductsSubscriber} from "./subscribers/content-partner/load-products.subscriber";
import {SetCheckoutContextSubscriber} from "./subscribers/checkout/set-checkout-context.subscriber";
import {BillingDetailsSubmitEvent} from "./events/checkout/billing-details-submit.event";
import {NavigatePaymentSubscriber} from "./subscribers/checkout/navigate-payment.subscriber";
import {ChangeRefSubscriber} from "./subscribers/core/change-ref.subscriber";
import {ToggleVisitGuaranteeEvent} from "./events/checkout/toggle-visit-guarantee.event";
import {SetVisitGuaranteeSubscriber} from "./subscribers/checkout/set-visit-guarantee.subscriber";
import {LoadNoDateProductsSubscriber} from "./subscribers/content-partner/load-no-date-products.subscriber";
import {SetBillingDetailsSubscriber} from "./subscribers/checkout/set-billing-details.subscriber";
import {CheckoutProvider} from "../providers/checkout.provider";
import {BillingDetailsEnteredEvent} from "./events/checkout/billing-details-entered.event";
import {RebuildInitiatedEvent} from "./events/checkout/rebuild-initiated.event";
import {CreateOrderEvent} from "./events/checkout/create-order.event";
import {CreateOrderSubscriber} from "./subscribers/checkout/create-order.subscriber";
import {GetOrderOverviewEvent} from "./events/checkout/get-order-overview.event";
import {GetOrderOverviewSubscriber} from "./subscribers/checkout/get-order-overview.subscriber";
import {ReserveTimeslotSubscriber} from "./subscribers/timeslots/reserve-timeslot.subscriber";
import {RebuildBillingSubscriber} from "./subscribers/checkout/rebuild-billing.subscriber";
import {AttractionProvider} from "../providers/attraction.provider";
import {LoadCampaignDataSubscriber} from "./subscribers/home/load-campaign-data.subscriber";
import {LoadHomeCategoriesSubscriber} from "./subscribers/home/load-home-categories.subscriber";
import {LoadHomeDataEvent} from "./events/home/load-home-data.event";
import {AttractionMainSearchProductEvent} from "./events/attractions-main/attraction-main-search-product.event";
import {AttractionMainSearchProductSubscriber} from "./subscribers/attractions-main/attraction-main-search-product.subscriber";
import {HomePageSearchProductEvent} from "./events/home/home-page-search-product.event";
import {HomePageSearchProductSubscriber} from "./subscribers/home/home-page-search-product.subscriber";
import {FilterProductByCategoryCityEvent} from "./events/attractions-main/filter-product-by-category-city-event";
import {FilterProductByCategoryCitySubscriber} from "./subscribers/attractions-main/filter-product-by-category-city.subscriber";
import {LoadAllProductsEvent} from "./events/attractions-main/load-all-products.event";
import {LoadAllProductsSubscriber} from "./subscribers/attractions-main/load-all-products.subscriber";
import {CheckoutResetEvent} from "./events/checkout/checkout-reset.event";
import {OrderOverviewGeneratedEvent} from "./events/checkout/order-overview-generated.event";
import {RaiseErrorEvent} from "./events/core/raise-error.event";
import {ShowErrorPopupEvent} from "./subscribers/core/show-error-popup.event";
import {SelectContentPartnerEvent} from "./events/content-partner/select-content-partner.event";
import {SelectContentPartnerSubscriber} from "./subscribers/content-partner/select-content-partner.subscriber";
import {GetMerchantReferenceEvent} from "./events/checkout/get-merchant-reference.event";
import {GetMerchantReferenceSubscriber} from "./subscribers/checkout/get-merchant-reference.subscriber";
import {ChangeLanguageEvent} from "./events/home/change-language.event";
import {ChangeLanguageSubscriber} from "./subscribers/home/change-language.subscriber";
import {InvalidStateEvent} from "./events/checkout/invalid-state.event";
import {RecoverInvalidStateSubscriber} from "./subscribers/checkout/recover-invalid-state.subscriber";
import {ReservationReplayedEvent} from "./events/checkout/reservation-replayed.event";
import {ProductsLoadedEvent} from "./events/content-partner/products-loaded.event";
import {AllProductsLoadedEvent} from "./events/attractions-main/all-products-loaded.event";
import {OrderCompleteEvent} from "./events/checkout/order-complete.event";
import {ContentPartnerClickedEvent} from "./events/content-partner/content-partner-clicked.event";
import {CreateIngenicoOrderEvent} from "./events/checkout/create-ingenico-order.event";
import {CreateIngenicoOrderSubscriber} from "./subscribers/checkout/create-ingenico.order.subscriber";
import {ReplayReservationSubscriber} from "./subscribers/checkout/replay-reservation.subscriber";

@NgModule({
    providers: [
        EventsDispatcher,
        AttractionProvider,
        EventRegistry,
        ReservationSubscriber,
        TimeslotsSubscriber,
        QuantityChangeEvent,
        SelectTimeslotEvent,
        ReservationPlacedEvent,
        CalendarDateSelectEvent,
        ResetCheckoutSubscriber,
        SelectLocationEvent,
        SelectTimeslotSubscriber,
        CheckCheckoutConditionsSubscriber,
        SelectLocationSubscriber,
        SelectContentPartnerEvent,
        SelectContentPartnerSubscriber,
        CheckoutSubmitEvent,
        NavigateBillingDetailsSubscriber,
        CheckoutEnteredEvent,
        BillingDetailsEnteredEvent,
        ReserveTimeslotSubscriber,
        BillingDetailsSubmitEvent,
        SetBillingDetailsSubscriber,
        NavigatePaymentSubscriber,
        RebuildCheckoutSubscriber,
        LoadContentPartnerSubscriber,
        LoadProductsSubscriber,
        SetCheckoutContextSubscriber,
        ChangeRefSubscriber,
        ToggleVisitGuaranteeEvent,
        SetVisitGuaranteeSubscriber,
        LoadNoDateProductsSubscriber,
        RebuildInitiatedEvent,
        CreateOrderEvent,
        CreateOrderSubscriber,
        CreateIngenicoOrderEvent,
        CreateIngenicoOrderSubscriber,
        GetOrderOverviewEvent,
        GetOrderOverviewSubscriber,
        GetMerchantReferenceEvent,
        GetMerchantReferenceSubscriber,
        RebuildBillingSubscriber,
        AttractionMainSearchProductEvent,
        AttractionMainSearchProductSubscriber,
        LoadHomeDataEvent,
        HomePageSearchProductEvent,
        HomePageSearchProductSubscriber,
        ChangeLanguageEvent,
        ChangeLanguageSubscriber,
        LoadCampaignDataSubscriber,
        LoadHomeCategoriesSubscriber,
        FilterProductByCategoryCityEvent,
        FilterProductByCategoryCitySubscriber,
        LoadAllProductsEvent,
        LoadAllProductsSubscriber,
        CheckoutResetEvent,
        OrderOverviewGeneratedEvent,
        RaiseErrorEvent,
        ShowErrorPopupEvent,
        InvalidStateEvent,
        RecoverInvalidStateSubscriber,
        ReservationReplayedEvent,
        ProductsLoadedEvent,
        AllProductsLoadedEvent,
        OrderCompleteEvent,
        ContentPartnerClickedEvent,
        ReplayReservationSubscriber
    ],

})
export class EventsModule {
    static forRoot(): ModuleWithProviders<EventsModule> {
        return {
            ngModule: EventsModule,
            providers: [
                CheckoutProvider,
            ]
        };
    }
}

