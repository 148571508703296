import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgInitDirective } from './directives/ng-init.directive';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorPopupComponent } from './popups/error-popup/error-popup.component';
import { TextPopoverComponent } from './popups/text-popover/text-popover.component';

@NgModule({
  declarations: [
    NgInitDirective,
    SafeHtmlPipe,
    ErrorPopupComponent,
    TextPopoverComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule
  ],
    exports: [
        NgInitDirective,
        SafeHtmlPipe,
        TranslateModule,
        ErrorPopupComponent,
        TextPopoverComponent
    ]
})
export class SharedModule { }
