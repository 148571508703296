import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Subject } from 'rxjs';

export interface KlassMapping {
  [prop: string]: (val?: any) => string;
}

// add class logic here
// keys are properties from CusAutcompleteClass
const KLASS_MAPPING: KlassMapping = {
  _multiSelect: (val) => val ? 'cus-multiselect' : 'cus-singleselect',
};

@Component({
  selector: 'qup-cus-autocomplete',
  templateUrl: './cus-autocomplete.component.html',
  styleUrls: ['./cus-autocomplete.component.scss'],
})
export class CusAutocompleteComponent implements OnInit {
  @ViewChild(NgSelectComponent, { static: true }) control!: NgSelectComponent;

  @Input() visible = true;
  @Input() set multiSelect(d: boolean) {
    if (d !== this._multiSelect) {
      this._multiSelect = d;
      this.updateSelectClass();
    }
  }
  @Input() items: any[] = [];
  @Input() selectNum = 0;
  @Input() bindLabel = 'label';
  @Input() bindValue = 'id';
  @Input() placeholder = '';
  @Input() subClass = '';
  @Input() clearable = true;
  @Input() controlName: string | null = null;
  @Input() set bypassFormTypeCheck(form: any) {
    const f = form as unknown as FormGroup;

    this.formGroup = f;
  }
  @Input() formGroup!: FormGroup;
  @Input() set typeahead(s: Subject<string>) {
    this._typeahead = s;
  }
  @Output() modelChange = new EventEmitter<any>();

  @Input() searchable = true;

  _typeahead!: Subject<string>;
  _multiSelect = false;

  klass = '';

  constructor() {}

  ngOnInit(): void {
    // set typeahead if available
    this.control.typeahead = this._typeahead;

    this.updateSelectClass();
  }

  change(c: any): void {
    this.modelChange.next(c);
  }

  updateSelectClass(): void {
    const klasses: string[] = Object.entries(KLASS_MAPPING).map(
      ([prop, func]) => {
        const dis = this as Record<string, any>;

        return func(dis[prop]);
      }
    );

    this.klass = klasses.join(' ');
  }
}
