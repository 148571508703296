import {Injectable} from "@angular/core";
import {Resolve} from "@angular/router";
import {LoadHomeDataEvent} from "../dispatch/events/home/load-home-data.event";

@Injectable({providedIn: 'root'})
export class CampaignResolver implements Resolve<void> {
    constructor(private loadHomeDataEvent: LoadHomeDataEvent) {}

    resolve(): void {
        this.loadHomeDataEvent.dispatch()
    }
}