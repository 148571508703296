import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {retry} from "rxjs/operators";

export class TranslationLoaderService {
    constructor(
        private http: HttpClient,
        private prefix: string = "/assets/i18n/",
        private suffix: string = ".json") {}

    public getTranslation(lang: string): Observable<any> {
        return this.http.get(`${this.prefix}${lang}${this.suffix}`).pipe(
            retry(3)
        );
    }
}