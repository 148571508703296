import {Injectable} from "@angular/core";
import {SubscriberContract} from "../../contracts/subscriber.contract";
import {Reservation} from "../../../models/checkout/reservation.model";
import {CheckoutProvider} from "../../../providers/checkout.provider";
import { catchError } from 'rxjs/operators';
import {of} from "rxjs";


@Injectable()
export class RebuildCheckoutSubscriber implements SubscriberContract<Reservation|null> {
    constructor(private checkoutProvider: CheckoutProvider) {}

    async handle(): Promise<Reservation|null> {
        return this.checkoutProvider.rebuildCheckout().pipe(
            catchError(error => of(null))
        ).toPromise();
    }
}