<div class="timeslot-wrap-container mb-4" *ngIf="availableTimeslots.length > 0 && reservation">
    <h4 class="card_title">
        {{ 'CHECKOUT.choose_timeslot' | translate }}
    </h4>
    <div class="main-content style-1 timeslots">
        <div class="row m-0">
            <div class="col-12 pl-0 pr-0 timeslot-container mt-2">
                <p>{{ 'CHECKOUT.timeslot_description_1' | translate }}</p>
                <p>{{ 'CHECKOUT.timeslot_description_2' | translate }}</p>
<!--                <div class="row">-->
<!--                    <div *ngFor="let timeslot of availableTimeslots" class="timeslot mb-1 mr-1 col-5" (click)="selectTimeslot(timeslot)"-->
<!--                         [ngClass]="{'selected': timeslot === selectedTimeslot }">-->
<!--                        {{timeslot?.timeslotName()}}-->
<!--                    </div>-->
<!--                </div>-->
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12">
                <strong>{{'CHECKOUT.timeslot_label' | translate }}</strong>
            </div>
        </div>
        <div class="row mt-1">
            <div class="col-12">
                <select class="timeslot-select-box" (change)="selectTimeslot($event)">
                    <option value="" disabled selected><span>Selecteer je tijdslot</span></option>
                    <option *ngFor="let timeslot of availableTimeslots" [value]="timeslot.timeslotFrom()"
                            [selected]="timeslot === selectedTimeslot">{{timeslot?.timeslotFancyName()}}</option>
                </select>
            </div>
        </div>
    </div>
</div>
