import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { CookieStatementComponent } from './cookie-statement/cookie-statement.component';
import {AuthResolver} from "../resolvers/auth.resolver";
import {CampaignResolver} from "../resolvers/campaign.resolver";
import {environment} from "../../environments/environment";
import {NewsletterComponent} from "../components/newsletter/newsletter.component";
import {PromotionComponent} from "../components/promotion/promotion.component";

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    resolve: {
      token: AuthResolver,
    },
    children: [
      {
        path: '',
        loadChildren: (): any =>
          import('./../home/home.module').then((m) => m.HomeModule),
        data: {
          showHeaderSearch: true
        },
        resolve: {
          campaign: CampaignResolver
        }
      },
      {
        path: environment.attractionsRoutingPathName,
        loadChildren: (): any =>
          import('./../attractions/attractions.module').then(
            (m) => m.AttractionsModule
          ),
      },
      {
        path: 'checkout',
        loadChildren: (): any =>
            import('./../components/checkout/checkout.module').then(
                (m) => m.CheckoutModule
            ),
      },
      {
        path: 'how-it-works',
        loadChildren: (): any =>
          import('./../how-it-works/how-it-works.module').then(
            (m) => m.HowItWorksModule
          ),
      },
      {
        path: 'change-date',
        loadChildren: (): any =>
            import('../modules/change-date/change-date.module').then(
                (m) => m.ChangeDateModule
            ),
      },
      {
        path: 'lost-tickets',
        loadChildren: (): any =>
            import('../modules/lost-tickets/lost-tickets.module').then(
                (m) => m.LostTicketsModule
            ),
      },
      {
        path: 'cookie-statement',
        data: { hideDialog: true },
        pathMatch: 'full',
        component: CookieStatementComponent,
      },
      {
        path: 'newsletter',
        component: NewsletterComponent
      },
      {
        path: 'nieuwsbrief',
        component: NewsletterComponent
      },
      {
        path: 'promotion/20240425',
        component: PromotionComponent
      },
      {
        path: 'account',
        loadChildren: (): any =>
            import('../modules/plus/plus.module').then(
                (m) => m.PlusModule
            ),
      },
      {
        path: '**',
        pathMatch: 'full',
        redirectTo: '',
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule],
})
export class CoreRoutingModule { }
