import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CookieSettings } from 'src/app/interfaces/cookies.interface';
import { AppService } from 'src/app/services/app.service';
import { DialogConfig } from '../../dialog/dialog-config';
import { DialogRef } from '../../dialog/dialog-ref';

const SETTING_KEYS: Array<keyof CookieSettings> = [
  'technical',
  'functional',
  'tracking'
];

@Component({
  selector: 'qup-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss'],
})
export class CookiesComponent implements OnInit, OnDestroy {
  _subs: Subscription[] = [];
  // show preferences
  showPref = false;
  intiConfig = this.appS.cookiePreferences;

  constructor(
    private dialogRef: DialogRef,
    private appS: AppService,
    private router: Router,
    private aR: ActivatedRoute,
    private config: DialogConfig,
  ) {}

  ngOnInit(): void {
    this.showPref = !!this.config?.data?.showPref;

    this._subs.push(
      this.router.events
        .pipe(filter((e) => e instanceof NavigationEnd))
        .subscribe((r) => {
          const closeD = this.aR?.snapshot?.firstChild?.firstChild?.data
            ?.hideDialog;

          if (closeD) {
            this.close();
          }
        })
    );
  }

  acceptAllClicked(): void {
    // all values TRUE
    this.savePreferences(
      SETTING_KEYS.reduce((settings, key) => {
        settings[key] = true;

        return settings;
      }, {} as CookieSettings)
    );
    this.close();
  }

  acceptSelected(data: CookieSettings): void {
    this.savePreferences(data);
    this.close();
  }

  savePreferences(data: CookieSettings): void {
    this.appS.cookiePreferences = data;
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this._subs.forEach((s) => s?.unsubscribe());
  }
}
