import {Component, Input, OnDestroy, OnInit, Pipe, PipeTransform} from '@angular/core';
import {AppService} from "../../../services/app.service";
import {QuantityChangeEvent} from "../../../dispatch/events/checkout/quantity-change.event";
import {Product} from "../../../models/content-partner/product.model";
import {CheckoutProvider} from "../../../providers/checkout.provider";
import {BehaviorSubject, interval, Subscription} from "rxjs";
import {SelectedProduct} from "../../../models/checkout/selected-product.model";
import {LocaleEnum} from "../../../../environments/enums/locale.enum";
import {catchError, debounceTime, map, skip} from "rxjs/operators";

@Pipe({
  name: 'removeZeroDecimals'
})
export class RemoveZeroDecimalsPipe implements PipeTransform {
  transform(value: any): string {
    // Convert the number to a string
    const stringValue = value.toString();

    // Check if the number has a decimal point
    if (stringValue.includes('.')) {
      // Split the number into integer and decimal parts
      const [integerPart, decimalPart] = stringValue.split('.');

      // Check if the decimal part is '00' or '0'
      if (decimalPart === '00' || decimalPart === '0') {
        // Return only the integer part
        return integerPart;
      }
    }

    // Return the original value if no changes are needed
    return stringValue;
  }
}

@Component({
  selector: 'qup-ticket-dropdown',
  templateUrl: '../../../templates/checkout/ticket/ticket-select-v2.component.html',
  styleUrls: ['../../../templates/checkout/ticket/ticket-select-v2.component.scss']
})
export class TicketSelectComponent implements OnInit, OnDestroy {

  private _subs: Subscription[] = [];

  public lastTicketWarning = false;

  constructor(
      private appService: AppService,
      private reservationEvent: QuantityChangeEvent,
      private checkoutProvider: CheckoutProvider
  ) { }

  products: Product[] = [];
  @Input() locationUuid: string | undefined;
  @Input('mobile') isMobile: boolean = false;

  public maxSelectableQuantity = 20;
  public reservationProducts: BehaviorSubject<Map<string, SelectedProduct>> = new BehaviorSubject(new Map());
  public emptyDiscountStickerText = false;
  ngOnInit(): void {
    this._subs.push(
        this.checkoutProvider.products().pipe(
            map((products) => {

              this.products = products;
              this.products.sort((a, b) => {
                if (a.productName().includes('9:30')) {
                  if (b.productName().includes('Kind')) {
                    return 1;
                  }
                  return -1;
                }
                if (b.productName().includes('9:30')) {
                  if (a.productName().includes('Kind')) {
                    return -1;
                  }
                  return 1;
                }
                return a.productName().localeCompare(b.productName());
              });

              this.checkoutProvider.reservation().pipe(
                  catchError((e) => {
                    throw e;
                  }),
              ).subscribe((reservation) => {

                if (reservation == null) {
                  this.reservationProducts.next(new Map());
                  return;
                }

                reservation.aggregatedReservationProducts().forEach((reservationProduct) => {
                  this.products.forEach((product) => {
                    if (product.productUuid() == reservationProduct.productUuid()) {
                      this.reservationProducts.getValue().set(product.productUuid(), new SelectedProduct(product, reservationProduct.quantity()!));
                    }
                  });
                });
              });
            })
        ).subscribe((data) => {
          this.products.forEach((product) => {
            this.lastTicketWarning = product.availableStock() < 20;
          });
        }),
        this.appService.currentLanguage$.subscribe((data) => {
          this.emptyDiscountStickerText = data == LocaleEnum.en_GB;
        }),
        this.reservationProducts.pipe(
            skip(1),
            debounceTime(1000)
        ).subscribe((data) => {
          this.reservationEvent.dispatch(data);
        })
    );
  }

  increaseQuantity(product: Product): void {
    let currentReservation = this.reservationProducts.getValue();
    let oldQuantity = Math.min(currentReservation.get(product.productUuid())?.quantity()?? 0, this.maxSelectableQuantity);
    currentReservation.set(product.productUuid(), new SelectedProduct(product, oldQuantity + 1));

    this.reservationProducts.next(currentReservation);
  }

  decreaseQuantity(product: Product): void {
    let currentReservation = this.reservationProducts.getValue();
    let oldQuantity = Math.min(currentReservation.get(product.productUuid())?.quantity()?? 0, this.maxSelectableQuantity);

    if (oldQuantity == 0) {
      return;
    }

    currentReservation.set(product.productUuid(), new SelectedProduct(product, oldQuantity - 1));

    this.reservationProducts.next(currentReservation);
  }

  ngOnDestroy(): void {
    this._subs.forEach((sub) => sub.unsubscribe());
  }
}