import {Injectable} from "@angular/core";
import {EventsDispatcher} from "../../dispatch/events.dispatcher";
import {AddPlusVoucherSubscriber} from "./dispatch/subscribers/add-plus-voucher.subscriber";
import {ApplyPlusWalletVouchersSubscriber} from "./dispatch/subscribers/apply-plus-wallet-vouchers.subscriber";
import {ReservationPlacedEvent} from "../../dispatch/events/checkout/reservation-placed.event";
import {AddPlusVoucherEvent} from "./dispatch/events/add-plus-voucher.event";
import {GetPlusWalletSubscriber} from "./dispatch/subscribers/get-plus-wallet.subscriber";
import {CheckoutEnteredEvent} from "../../dispatch/events/checkout/checkout-entered.event";
import {WalletChangeQuantityEvent} from "./dispatch/events/wallet-change-quantity.event";
import {ReplayReservationSubscriber} from "../../dispatch/subscribers/checkout/replay-reservation.subscriber";
import {WalletReservationPlacedEvent} from "./dispatch/events/wallet-reservation-placed.event";
import {GetPlusWalletEvent} from "./dispatch/events/get-plus-wallet.event";


@Injectable()
export class PlusEventRegistry {
    constructor(
        private dispatcher: EventsDispatcher
    ) {
        dispatcher
            .register(CheckoutEnteredEvent, [GetPlusWalletSubscriber])
            .register(AddPlusVoucherEvent, [AddPlusVoucherSubscriber, GetPlusWalletSubscriber])
            .register(ReservationPlacedEvent, [ApplyPlusWalletVouchersSubscriber])
            .register(WalletChangeQuantityEvent, [ApplyPlusWalletVouchersSubscriber], EventsDispatcher.arrayAsArguments)
            .register(WalletReservationPlacedEvent, [ReplayReservationSubscriber])
            .register(GetPlusWalletEvent, [GetPlusWalletSubscriber])
        ;

    }
}