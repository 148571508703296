import {ModuleWithProviders, NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../../components/shared/shared.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CusAutocompleteModule} from "../../components/shared/cus-autocomplete/cus-autocomplete.module";
import {EventsDispatcher} from "../../dispatch/events.dispatcher";
import {LostTicketsComponent} from "./components/lost-tickets.component";
import {LostTicketsRoutingModule} from "./lost-tickets-routing.module";
import {LostTicketsProvider} from "./lost-tickets.provider";
import {LostTicketsEventRegistry} from "./lost-tickets-event.registry";
import {SendLostTicketsEmailEvent} from "./dispatch/events/send-lost-tickets-email.event";
import {SendLostTicketsEmailSubscriber} from "./dispatch/subscribers/send-lost-tickets-email.subscriber";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        CusAutocompleteModule,
        LostTicketsRoutingModule
    ],
    providers: [],
    declarations: [
        LostTicketsComponent
    ],
    exports: [
        LostTicketsComponent
    ]
})
export class LostTicketsModule {
    static forRoot(): ModuleWithProviders<LostTicketsModule> {
        return {
            ngModule: LostTicketsModule,
            providers: [
                LostTicketsProvider,
                SendLostTicketsEmailEvent,
                SendLostTicketsEmailSubscriber,
                {
                    provide: LostTicketsEventRegistry,
                    useFactory: LoadLostTicketsEventRegistry,
                    deps: [EventsDispatcher]
                }
            ]
        };
    }
}

export function LoadLostTicketsEventRegistry(dispatcher: EventsDispatcher): any {
    return new LostTicketsEventRegistry(dispatcher);
}