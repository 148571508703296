import {CheckoutProvider} from "../../../providers/checkout.provider";
import {Injectable} from "@angular/core";
import {SubscriberContract} from "../../contracts/subscriber.contract";
import {ContentPartner} from "../../../models/content-partner/content-partner.model";
import {ContentApiService} from "../../../services/content-api.service";
import {tap} from "rxjs/operators";
import {of} from "rxjs";
import {SelectLocationEvent} from "../../events/content-partner/select-location.event";
import {CheckoutContext} from "../../../models/checkout/checkout-context.model";


@Injectable()
export class LoadContentPartnerSubscriber implements SubscriberContract<ContentPartner|null> {
    constructor(
        private checkoutProvider: CheckoutProvider,
        private contentService: ContentApiService,
        private selectLocationEvent: SelectLocationEvent
    ) {}

    async handle(): Promise<ContentPartner|null> {

        const context = this.checkoutProvider.checkoutContext().getValue();

        if (context == null) {
            return of(null).toPromise();
        }

        return this.contentService.getContentPartner(
            context.language(),
            context.campaignUuid(),
            context.contentPartnerAlias()
        ).pipe(
            tap((contentPartner) => {
                this.checkoutProvider.contentPartner().next(contentPartner);
                this.checkoutProvider.locations().next(contentPartner.locations());

                this.checkoutProvider.checkoutContext().next(
                    new CheckoutContext(
                        context.language(),
                        context.campaignUuid(),
                        context.contentPartnerAlias(),
                        undefined,
                        contentPartner.uuid()
                    )
                );

                if (contentPartner.locations().length == 1) {
                    this.selectLocationEvent.dispatch(contentPartner.locations()[0]);
                } else {
                    this.checkoutProvider.selectedLocation().next(null);
                }
            })
        ).toPromise();
    }
}