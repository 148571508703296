import { AuthInterceptor } from './auth.interceptor';
/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {LoadingStateInterceptor} from "./loading-state.interceptor";

/** Http interceptor providers in outside-in order */
export const interceptorsProvider = [
    /** Order matters; the interceptors are wrapped around each other, so work in a bottom-up fashion! **/
    { provide: HTTP_INTERCEPTORS, useClass: LoadingStateInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];