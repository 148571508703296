import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable, ReplaySubject} from "rxjs";
import {AddPlusVoucher} from "./models/add-plus-voucher.model";
import {map} from "rxjs/operators";
import {PlusService} from "./plus.service";
import {PlusWallet} from "./models/plus-wallet.model";

@Injectable()
export class PlusProvider {

    private walletSubscription = new BehaviorSubject<PlusWallet>(new PlusWallet(0, []));
    private addPlusVoucherSubscription = new ReplaySubject<AddPlusVoucher>();
    private latestVoucherSubscription = new BehaviorSubject<string>('');

    constructor(
        private plusService: PlusService,
    ) {}

    wallet(): BehaviorSubject<PlusWallet> {
        return this.walletSubscription;
    }

    plusVoucher(): ReplaySubject<AddPlusVoucher> {
        return this.addPlusVoucherSubscription;
    }

    getWalletVouchers(): Observable<PlusWallet> {
        return this.plusService.getWallet().pipe(
            map((data) => {
                this.wallet().next(data);
                return data;
            })
        );
    }

    isLoggedIn() {

    }

    latestVoucher(): BehaviorSubject<string> {
        return this.latestVoucherSubscription;
    }
}