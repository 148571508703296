import {Component, OnDestroy, OnInit} from "@angular/core";
import {CheckoutProvider} from "../../../providers/checkout.provider";
import {Timeslot} from "../../../models/timeslots/timeslot";
import {of, Subscription} from "rxjs";
import {SelectTimeslotEvent} from "../../../dispatch/events/timeslots/select-timeslot.event";
import {Reservation} from "../../../models/checkout/reservation.model";
import {catchError} from "rxjs/operators";
import {RAISE_ERROR} from "../../../dispatch/events.registry";


@Component({
    selector: 'qup-timeslots-component',
    templateUrl: '../../../templates/checkout/timeslots/timeslots.component.html',
    styleUrls: ['../../../templates/checkout/timeslots/timeslots.component.scss']
})

export class TimeslotsComponent implements OnInit, OnDestroy{

    selectedTimeslot!: Timeslot | null;
    public availableTimeslots: Timeslot[] = [];

    public reservation: Reservation | null = null;

    private _subs: Subscription[] = [];

    constructor(
        private checkoutProvider: CheckoutProvider,
        private selectTimeslotEvent: SelectTimeslotEvent,
    ) {
    }

    ngOnInit(): void {
        this._subs.push(
            this.checkoutProvider.reservation().subscribe((reservation) => {
                this.reservation = reservation;
            }),
            this.checkoutProvider.timeslots().pipe(
                catchError((e) => {
                    RAISE_ERROR('Er ging iets mis bij het ophalen van de tijdsloten, probeer het later opnieuw.');
                    return of(null);
                })
            ).subscribe((data) => {
                if (data == null) {
                    this.availableTimeslots = [];
                    return;
                }
                this.availableTimeslots = data?.availableTimeslots();
            })
        );
    }

    selectTimeslot(event: any): void {
        const target = event.target as HTMLTextAreaElement;

        let timeslot = this.availableTimeslots.find((ts) => ts.timeslotFrom() === target.value);
        if (!timeslot) {
            return;
        }

        this.selectedTimeslot = timeslot;
        this.selectTimeslotEvent.dispatch(timeslot);
    }


    ngOnDestroy(): void {
        this._subs.forEach((sub) => sub.unsubscribe());
    }
}