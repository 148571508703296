import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {GetWalletVoucher} from "../../models/lidl-sso/get-wallet-voucher.model";
import {map} from "rxjs/operators";

@Injectable()
export class VouchersProvider {
    private walletVouchersSubscription = new BehaviorSubject<GetWalletVoucher[] | null>([]);
    private voucherErrorMessageSubscription = new BehaviorSubject<string>('');

    constructor(
    ) {
    }

    walletVouchers(): BehaviorSubject<GetWalletVoucher[] | null> {
        return this.walletVouchersSubscription;
    }

}