import { CookieService } from '../services/cookie.service';
import { StorageService } from 'src/app/services/storage.service';


/**
 * Set cookies
 */

export function initApp(storageService: StorageService, cookieService: CookieService): any {
  return async () => (new Promise(resolve => {
    const cookiePrefS = storageService.cookiePreferences;
    const cookiePref = cookieService.cookiePreferences;

    // if at app initialization there is no cookie, but there are preferences stored in local storage
    // (local storage doesn't have an expire attr) that means that the cookie most likely expired,
    // so all data should be purged
    if (cookiePrefS && !cookiePref) {
      storageService.deleteAll();
      cookieService.deleteAll();

      window.location.reload();
    }

    resolve(null);
  }))
}

