import {Component, OnInit} from "@angular/core";
import {AttractionProvider} from "../../providers/attraction.provider";
import {ContentPartner} from "../../models/content-partner/content-partner.model";
import {Subscription} from "rxjs";
import {UiService} from "../../services/ui.service";
import {Router} from "@angular/router";

@Component({
    selector: 'qup-newsletter',
    templateUrl: '../../templates/newsletter/newsletter.component.html',
    styleUrls: ['../../templates/newsletter/newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {

    highlightedContentPartners: string[] = [
        'wildlands',
        'madurodam',
        'lovers-alf'
    ];

    merchants: ContentPartner[] = [];

    private _subs: Subscription[] = [];
    highestDiscountPercentage = 0;

    isMobile: boolean = false;

    constructor(
        private attractionMainProvider: AttractionProvider,
        private uiS: UiService,
        private router: Router
    ) {
        this._subs.push(
            this.uiS.isMobileOverlay$.subscribe((isMobile) =>
                this.isMobile = isMobile
            )
        );
    }

    navigateToTrips(): void {
        this.router.navigate(['attractions']);
    }

    ngOnInit() {
        this.attractionMainProvider.loadAllProducts().subscribe((contentPartner) => {
            contentPartner.forEach((cp) => {
                if (this.highlightedContentPartners.includes(cp.alias())) {
                    this.merchants.push(cp);

                    if (cp.maxDiscountPercentageAsInteger() > this.highestDiscountPercentage) {
                        this.highestDiscountPercentage = cp.maxDiscountPercentageAsInteger();
                    }
                }
            });
        });
    }

}