import {ModuleWithProviders, NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../../components/shared/shared.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CusAutocompleteModule} from "../../components/shared/cus-autocomplete/cus-autocomplete.module";
import {EventsDispatcher} from "../../dispatch/events.dispatcher";
import {ChangeDateEventRegistry} from "./change-date-event.registry";
import {ChangeDateProvider} from "./change-date.provider";
import {ChangeDateComponent} from "./components/change-date.component";
import {ChangeDateRoutingModule} from "./change-date-routing.module";
import {SendChangeDateEmailEvent} from "./dispatch/events/send-change-date-email.event";
import {SendChangeDateEmailSubscriber} from "./dispatch/subscribers/send-change-date-email.subscriber";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        CusAutocompleteModule,
        ChangeDateRoutingModule
    ],
    providers: [],
    declarations: [
        ChangeDateComponent
    ],
    exports: [
        ChangeDateComponent
    ]
})
export class ChangeDateModule {
    static forRoot(): ModuleWithProviders<ChangeDateModule> {
        return {
            ngModule: ChangeDateModule,
            providers: [
                ChangeDateProvider,
                SendChangeDateEmailEvent,
                SendChangeDateEmailSubscriber,
                {
                    provide: ChangeDateEventRegistry,
                    useFactory: LoadDateChangeEventRegistry,
                    deps: [EventsDispatcher]
                }
            ]
        };
    }
}

export function LoadDateChangeEventRegistry(dispatcher: EventsDispatcher): any {
    return new ChangeDateEventRegistry(dispatcher);
}