import {Injectable} from "@angular/core";
import {SubscriberContract} from "../../../../dispatch/contracts/subscriber.contract";
import {LoyaltyProvider} from "../../loyalty.provider";

@Injectable()
export class ResetLoyaltySubscriber implements SubscriberContract<void> {
    constructor(private loyaltyProvider: LoyaltyProvider) {}

    async handle(): Promise<void> {
        return this.loyaltyProvider.resetLoyalty()
    }
}