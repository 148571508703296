import {Injectable} from "@angular/core";
import {CheckoutProvider} from "../../../providers/checkout.provider";
import {Reservation} from "../../../models/checkout/reservation.model";
import {SubscriberContract} from "../../contracts/subscriber.contract";
import {catchError} from "rxjs/operators";
import {of} from "rxjs";
import {SelectedProduct} from "../../../models/checkout/selected-product.model";

@Injectable()
export class ReservationSubscriber implements SubscriberContract<Reservation|null>  {
    constructor(private checkoutProvider: CheckoutProvider) {}

    async handle(event: SelectedProduct[]): Promise<Reservation|null>{

        if (event.length == 0) {
            this.checkoutProvider.reservation().next(null);
            return of(null).toPromise();
        }

        return this.checkoutProvider.placeReservation(event).pipe(
            catchError((e) => {
                this.checkoutProvider.reservation().next(null);
                return of(null);
            })
        ).toPromise();
    }
}