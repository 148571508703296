import {PspData} from "./payment/psp-data.model";

export class Order {
    constructor(
        private _orderNumber: string,
        private _orderUuid: string,
        private _paymentId: string,
        private _redirectUrl: string,
        private _pspProvider: string,
        private _pspData: PspData | null,
    ) {
    }

    orderNumber(): string {
        return this._orderNumber;
    }

    orderUuid(): string {
        return this._orderUuid;
    }

    paymentId(): string {
        return this._paymentId;
    }

    redirectUrl(): string {
        return this._redirectUrl;
    }

    pspProvider(): string {
        return this._pspProvider;
    }

    pspData(): PspData | null {
        return this._pspData;
    }


}