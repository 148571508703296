<div class="container">
    <div class="row">
        <div class="{{isMobile ? 'col-12' : 'col-8'}}">
            <div class="row">
                <div class="col-12">
                    <h3>{{'REGISTRATION.title' | translate}}</h3>
                </div>
            </div>
            <div *ngIf="!environment.federatedLogin">
                <div class="row">
                    <div class="col-12">
                        <p class="mb-2">{{'REGISTRATION.intro_text_1' | translate}}</p>
                    </div>
                </div>
                <form [formGroup]="registration" *ngIf="registrationState === RegistrationState.INITIAL" (ngSubmit)="submitRegistration()">
                    <div class="row mb-2">
                        <div class="col-12">
                            <label>{{ 'REGISTRATION.email_label' | translate }}</label>
                            <input type="email" name="email" formControlName="email">
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-12">
                            <label>{{ 'REGISTRATION.password_label' | translate }}</label>
                        </div>
                        <div class="col-12">
                            <span class="small">{{ 'REGISTRATION.password_requirement' | translate }}</span>
                        </div>
                        <div class="col-12">
                            <input type="password" name="password" formControlName="password">
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-6">
                            <label>{{ 'REGISTRATION.confirm_password_label' | translate }}</label>
                            <input type="password" name="password" formControlName="repeatPassword">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <button
                                    type="submit" [disabled]="!registration.valid" class="btn btn-lg login-button">
                                {{'REGISTRATION.title' | translate}}
                            </button>
                        </div>
                    </div>
                </form>

                <form [formGroup]="confirmation" *ngIf="registrationState === RegistrationState.CONFIRMATION" (ngSubmit)="confirmRegistration()">
                    <div class="row">
                        <div class="col-12">
                            <p class="mb-2">{{'REGISTRATION.intro_text_2' | translate}}</p>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-12">
                            <label>{{ 'REGISTRATION.confirm_account_code_label' | translate }}</label>
                            <input type="text" name="confirmationCode" formControlName="confirmationCode">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <button
                                    type="submit" [disabled]="!registration.valid" class="btn btn-lg login-button">
                                {{'REGISTRATION.confirm_account' | translate}}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<qup-download-app-banner [isMobile]="isMobile"></qup-download-app-banner>