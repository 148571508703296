import {Reservation} from "../reservation.model";

export class OrderOverview {
    constructor(
        private _paymentState: string,
        private _orderNumber: string,
        private _reservation: Reservation
    ) {
    }

    paymentState(): string {
        return this._paymentState;
    }

    orderNumber(): string {
        return this._orderNumber;
    }

    reservation(): Reservation {
        return this._reservation;
    }

}