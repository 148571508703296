import {StandardEvent} from "../standard.event";
import {ReplaySubject} from "rxjs";
import {Injectable} from "@angular/core";

@Injectable()
export class ToggleVisitGuaranteeEvent extends StandardEvent<boolean> {

    constructor() {
        super(new ReplaySubject<boolean>());
    }

    dispatch(enabled: boolean):void {
        this.subject.next(enabled);
    }

}