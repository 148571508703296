import {Injectable} from "@angular/core";
import {SubscriberContract} from "../../contracts/subscriber.contract";
import {Router} from "@angular/router";
import {BillingDetails} from "../../../models/checkout/billing-details.model";
import {Reservation} from "../../../models/checkout/reservation.model";

@Injectable()
export class NavigatePaymentSubscriber implements SubscriberContract<any> {
    constructor(private router: Router) {}

    async handle(event: [BillingDetails, Reservation]): Promise<boolean> {
        return this.router.navigate(['checkout/payment']);
    }
}