import {Injectable} from "@angular/core";
import {SubscriberContract} from "../../contracts/subscriber.contract";
import {Router} from "@angular/router";
import {environment} from "../../../../environments/environment";

@Injectable()
export class HomePageSearchProductSubscriber implements SubscriberContract<any> {
    constructor(private router: Router) {}

    async handle(searchTerm: string): Promise<boolean> {
        return this.router.navigate([environment.attractionsRoutingPathName],{
            queryParams: {searchTerm: searchTerm},
        });
    }
}