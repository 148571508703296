export class CalendarItem {
    constructor(
        private _date: Date,
        private _discountAmount: number,
        private _discountPercentage: number,
        private _effectivePrice: number,
        private _listPrice: number
    ) {
    }

    date(): Date {
        return this._date;
    }

    discountAmount(): number {
        return this._discountAmount;
    }

    discountPercentage(): number {
        return this._discountPercentage;
    }

    effectivePrice(): number {
        return this._effectivePrice;
    }

    listPrice(): number {
        return this._listPrice;
    }
}