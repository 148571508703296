import {ModuleWithProviders, NgModule} from "@angular/core";
import {LoyaltySubscriber} from "./dispatch/subscribers/loyalty.subscriber";
import {LoyaltyReservationPlacedEvent} from "./dispatch/events/loyalty-reservation-placed.event";
import {ApplyLoyaltyDiscountSubscriber} from "./dispatch/subscribers/apply-loyalty-discount.subscriber";
import {LoyaltyQuantityChangeEvent} from "./dispatch/events/loyalty-quantity-change.event";
import {LoyaltyProvider} from "./loyalty.provider";
import {LoyaltyDiscountComponent} from "./components/loyalty-discount.component";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../../components/shared/shared.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CusAutocompleteModule} from "../../components/shared/cus-autocomplete/cus-autocomplete.module";
import {LoyaltyEventRegistry} from "./loyalty-event.registry";
import {EventsDispatcher} from "../../dispatch/events.dispatcher";
import {ResetLoyaltySubscriber} from "./dispatch/subscribers/reset-loyalty.subscriber";
import {LoyaltyOrderOverviewComponent} from "./components/loyalty-order-overview.component";
import {InjectLoyaltyOverviewSubscriber} from "./dispatch/subscribers/inject-loyalty-overview.subscriber";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        CusAutocompleteModule,
    ],
    providers: [],
    declarations: [
        LoyaltyDiscountComponent,
        LoyaltyOrderOverviewComponent
    ],
    exports: [
        LoyaltyDiscountComponent,
        LoyaltyOrderOverviewComponent
    ]
})
export class LoyaltyModule {
    static forRoot(): ModuleWithProviders<LoyaltyModule> {
        return {
            ngModule: LoyaltyModule,
            providers: [
                ResetLoyaltySubscriber,
                LoyaltyProvider,
                LoyaltySubscriber,
                LoyaltyReservationPlacedEvent,
                ApplyLoyaltyDiscountSubscriber,
                LoyaltyQuantityChangeEvent,
                InjectLoyaltyOverviewSubscriber,
                {
                    provide: LoyaltyEventRegistry,
                    useFactory: LoadLoyaltyEventRegistry,
                    deps: [EventsDispatcher]
                }
            ]
        }
    }
}

export function LoadLoyaltyEventRegistry(dispatcher: EventsDispatcher): any {
    return new LoyaltyEventRegistry(dispatcher);
}