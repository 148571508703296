import {CheckoutProvider} from "../../../providers/checkout.provider";
import {Injectable} from "@angular/core";
import {of} from "rxjs";
import {SubscriberContract} from "../../contracts/subscriber.contract";
import {CheckoutContext} from "../../../models/checkout/checkout-context.model";

@Injectable()
export class SetCheckoutContextSubscriber implements SubscriberContract<boolean> {
    constructor(private checkoutProvider: CheckoutProvider) {}

    async handle(context: CheckoutContext): Promise<boolean> {
        this.checkoutProvider.checkoutContext().next(context);
        return of(true).toPromise()
    }
}