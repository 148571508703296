import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {finalize} from "rxjs/operators"
import {LoadingStateService} from "../services/loading-state.service";

@Injectable()
export class LoadingStateInterceptor implements HttpInterceptor {

    public static stack = 0;
    public static loading = false;

    constructor(private loadingStateService: LoadingStateService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (!LoadingStateInterceptor.loading) {
            LoadingStateInterceptor.loading = true;
            this.loadingStateService.start();
        }

        LoadingStateInterceptor.stack++;

       return next.handle(request).pipe(
           finalize( () => {
               this.handleEnd();
           })
       );
    }

    handleEnd(): void {
        LoadingStateInterceptor.stack--;

        if (LoadingStateInterceptor.stack <= 0 && LoadingStateInterceptor.loading) {
            LoadingStateInterceptor.loading = false;
            LoadingStateInterceptor.stack = 0;
            this.loadingStateService.end();
        }
    }
}