import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttractionCardComponent } from './attraction-card.component';
import { SharedModule } from '../shared.module';
import {FormsModule} from "@angular/forms";

@NgModule({
  declarations: [AttractionCardComponent],
  imports: [CommonModule, SharedModule, FormsModule],
  exports: [AttractionCardComponent]
})
export class AttractionCardModule {}
