import { SharedModule } from '../../components/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './dialog.component';
import { InsertionDirective } from './directives/insertion.directive';

@NgModule({
  declarations: [DialogComponent, InsertionDirective],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class DialogModule { }
