<div class="bannerContainer" *ngIf="!embedded">
    <ng-image-slider *ngIf="!isMobile" [autoSlide]="{interval: 5}" [infinite]="true" [imageSize]="{
        width: '730px',
        height: '450px'
    }" [images]="images" #imageSlider></ng-image-slider>

    <ng-image-slider *ngIf="isMobile" [autoSlide]="{interval: 5}" [infinite]="true" [imageSize]="{
        width: '365px',
        height: '225px'
    }" [images]="images" #imageSlider></ng-image-slider>

    <div class="discount-container">
        <div class="discount-preamb">
            <span>{{'ATTRACTION_CARD.up_to' | translate}}</span>
        </div>
        <div class="discount-amount">
            <span>{{contentPartner?.maxDiscountPercentageAsInteger()}}%</span>
        </div>
        <div class="discount-postamb">
            <span>{{'ATTRACTION_CARD.discount' | translate}}</span>
        </div>
    </div>
</div>