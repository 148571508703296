import {Injectable} from "@angular/core";
import {SubscriberContract} from "../../../../dispatch/contracts/subscriber.contract";
import {AnalyticsProvider} from "../../analytics-provider";
import {OrderOverview} from "../../../../models/checkout/order-overview/order-overview.model";

@Injectable()
export class FinalizeCheckoutSubscriber implements SubscriberContract<void> {
    constructor(private analyticsProvider: AnalyticsProvider) {}

    async handle(data: OrderOverview): Promise<void> {
        this.analyticsProvider.purchase(data);
    }
}