import {Component, OnInit} from "@angular/core";
import {LoyaltyProvider} from "../loyalty.provider";
import {LoyaltyReservation} from "../../../models/loyalty/loyalty-reservation.model";

@Component({
    selector: 'qup-loyalty-order-overview',
    templateUrl: '../../../templates/checkout/order-overview/loyalty-order-overview.component.html',
    styleUrls: ['../../../templates/checkout/order-overview/loyalty-order-overview.component.scss'],
})
export class LoyaltyOrderOverviewComponent implements OnInit{

    public loyaltyReservation: LoyaltyReservation|null = null;

    constructor(
        private loyaltyProvider: LoyaltyProvider
    ) {
    }

    ngOnInit(): void {
        this.loyaltyProvider.loyalty().subscribe((loyaltyReservation) => {
            this.loyaltyReservation = loyaltyReservation;
        })
    }
}