<table class="loyalty-table">
    <tr *ngIf="loyaltyReservation">
        <td class="table_column_text1 pt-2">
            <span class="mr-1">{{'CHECKOUT.used_points' | translate}}</span>
        </td>
        <td class="table_column_text2 pt-2" colspan="2"></td>
        <td class="table_column_text2 pt-2 text-right">
            <span><i class="material-symbols-outlined" style="color: gold">toll</i>{{loyaltyReservation.amount()}}</span>
        </td>
    </tr>
</table>