import {ReplaySubject} from "rxjs";
import {EventContract} from "../contracts/event.contract";

export abstract class StandardEvent<T, J = T> implements EventContract<T>{

    protected subject: ReplaySubject<T>;

    protected constructor(subject: ReplaySubject<T>) {
        this.subject = subject
    }

    listen(): ReplaySubject<T> {
        return this.subject;
    }

    abstract dispatch(subject: J):  void;
}