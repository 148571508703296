import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable()
export class ChangeDateProvider {

    constructor(
    ) {
    }

    private changeDateMessageSubscription = new BehaviorSubject<string>('');

    changeDateMessage(): BehaviorSubject<string> {
        return this.changeDateMessageSubscription;
    }

}