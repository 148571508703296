import {ActionData} from "./action-data.model";

export class PspData {
    constructor(
        private _merchantReference: string,
        private _pspReference: string,
        private _resultCode: string,
        private _amount : { value: number, currency: string },
        private _paymentData: string,
        private _details: { key: string, type: string},
        private _redirect: { method: string, url: string},
        private _action: ActionData|null,
    ) { }


    merchantReference(): string {
        return this._merchantReference;
    }

    resultCode(): string {
        return this._resultCode;
    }

    amount(): { value: number, currency: string } {
        return this._amount;
    }

    action(): ActionData|null {
        return this._action;
    }

}