<div class="select-location-container container-fluid card">
    <h4 class="select-location-title">
        {{'CHECKOUT.select_location' | translate}}
    </h4>
    <div class="row mt-2">
        <div class="col-8">
            <qup-cus-autocomplete id="location-control" style="display: contents;"
                                  [clearable]="false" controlName="location"
                                  subClass="qup-ng-select" [items]="locations"
                                  [searchable]="true"
                                  (modelChange)="changeSelectedLocation($event)" [multiSelect]="false"
                                  bindLabel="_caption" bindValue="_uuid"
                                  placeholder="{{'ATTRACTION_SPECIFIC.city' | translate}}"
                                  [formGroup]="form">
            </qup-cus-autocomplete>
        </div>
    </div>
</div>


