<div class="attractions-toolbar">
    <div class="row mb-2">
        <div class="col-12">
            <h3>
                Filter op..
            </h3>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-12">
            <div class="categories">
                <div class="category {{currentCategory === category.id ? 'selected': ''}}" *ngFor="let category of typeOptions" (click)="filterCategory(category.id)">
                    <div class="row">
                        <div class="col-9">
                            <div class="category-title">
                                <span>{{category.label}}</span>
                            </div>
                            <div class="category-subtitle">
                                <span>{{category.count}} uitjes</span>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="category-icon">
<!--                                <img src="{{category.icon}}" alt="{{category.label}}" />-->
                                <i style="font-size: 30px" class="material-symbols-outlined">{{getCategoryIcon(category.id)}}</i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row see-all mt-2 mb-2" *ngIf="currentCategory !== null" (click)="filterCategory(null)">
        <div class="col-12">
            <div class="clear-filters">
                <span>Bekijk alle uitjes</span>
            </div>
        </div>
    </div>
    <div class="row map-container mt-2 mb-2" *ngIf="!hideMap">
        <div class="col-12">
            <div class="see-map" (click)="navigateMapPage()">
                <i class="material-symbols-outlined">map</i>
                <span> Bekijk Dagjes Uit op de kaart</span>
            </div>
        </div>
    </div>
    <div class="row title-container mt-8 mb-2" *ngIf="!hideMap" >
        <div class="col-12">
            <h3>Ontdek de leukste {{categoryLabel}}!</h3>
        </div>
    </div>
</div>