import {Injectable} from '@angular/core';
import {environment as env} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {ChangeDatePayload, ChangeDateResponse} from "../interfaces/change-date.interface";
import {Subscription} from "rxjs";
import {ChangeDateProvider} from "../modules/change-date/change-date.provider";


const URLS = {
    changeDateOrderEndpoint: () => `${env.changeDateUri}/order`,
};

@Injectable({
    providedIn: 'root'
})

export class ChangeDateService {

    constructor(
        private http: HttpClient,
        private changeDateProvider: ChangeDateProvider
    ) {
    }

    sendChangeDateEmail(payload: ChangeDatePayload): Subscription {
        return this.http.post<ChangeDateResponse>(URLS.changeDateOrderEndpoint(), payload).subscribe((data) => {
            if (data) {
                this.changeDateProvider.changeDateMessage().next('DATE_CHANGE.email_success_sent');
            }
        }, e => {
            switch (e?.error?.title) {
                case "checking existing order error":
                    this.changeDateProvider.changeDateMessage().next('DATE_CHANGE.error_existing_order_session');
                    break;
                case "couldn't get order details":
                    this.changeDateProvider.changeDateMessage().next('DATE_CHANGE.error_wrong_order');
                    break;
                default:
                    this.changeDateProvider.changeDateMessage().next('DATE_CHANGE.error_general');
                    break;
            }
        });
    }
}
