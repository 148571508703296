import {Injectable} from "@angular/core";
import {ReplaySubject} from "rxjs";
import {Reservation} from "../../../models/checkout/reservation.model";
import {StandardEvent} from "../standard.event";

@Injectable()
export class RebuildInitiatedEvent extends StandardEvent<Reservation> {

    constructor() {
        super(new ReplaySubject<Reservation>())
    }

    dispatch(reservation: Reservation): void {
        this.subject.next(reservation);
    }
}