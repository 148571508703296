<div class="container mt-4 mb-8 p-0">
    <div class="row p-0 justify-content-center change_date_container mx-2">
        <div class="col-12 col-sm-4 col-md-3 mt-8 text-center">
            <img class="change_date_image" src="assets/images/calendar.svg" alt="calendar image"/>
        </div>
        <div *ngIf="!isSent" class="col-12 mt-2 change_date_main_text">
            {{'DATE_CHANGE.title' | translate}}
        </div>
        <div *ngIf="!isSent" class="row w-100 m-0 p-0 mb-2 justify-content-center">
            <p class="col-12 col-md-6 mt-2 p-2 change_date_description_text">
                {{'DATE_CHANGE.subtitle' | translate}}
            </p>
        </div>
        <div *ngIf="!isSent" class="row w-100 p-0 email_container justify-content-center">
            <div class="error-msg">
                {{ message | translate }}
            </div>
            <form style="display: contents;" [formGroup]="form" (ngSubmit)="submit()">
                <div class="col-12 m-0 p-0 text-center order_container">
                    <input formControlName='orderNumber' class="order_input" type="text"
                           placeholder="{{'DATE_CHANGE.order_number' | translate}}">
                </div>
                <div class="col-12 m-0 mt-2 p-0 text-center order_container">
                    <input formControlName='email' class="order_input" type="text"
                           placeholder="{{'DATE_CHANGE.email_address' | translate}}">
                </div>
                <div class="col-12 m-0 mt-2 p-0 text-center order_container">
                    <button type="submit" [disabled]="!form.valid"
                            class="btn btn-primary email_button">{{'DATE_CHANGE.send' | translate}}</button>
                </div>
            </form>
        </div>

        <div *ngIf="isSent" class="row w-100 p-0 m-0 justify-content-center">
            <div class="col-12 col-md-6 pl-2 pr-2 change_date_description_text">
                <img class="email_sent_icon" src="assets/images/paper-plane.png" />
                <span class="email_sent_text">{{'DATE_CHANGE.sent' | translate}}</span>
            </div>
        </div>
        <div *ngIf="isSent" class="row w-100 p-0 email_container justify-content-center">
            <div class="col-12 col-md-6 p-2 email_sent_description">
                {{'DATE_CHANGE.email_success_sent' | translate}}
            </div>
        </div>

    </div>
</div>
