import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable} from "rxjs";
import Auth from "@aws-amplify/auth";
import {CognitoService} from "../../../services/cognito.service";
import {map} from "rxjs/operators";

@Injectable()
export class PlusGuard implements CanActivate {
    constructor(
        private router: Router,
        private cognitoService: CognitoService
    ) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.cognitoService.isLoggedIn().pipe(
            map((isLoggedIn) => {
                if (!isLoggedIn) {
                    this.router.navigate(['/account/login']);
                }
                return isLoggedIn;
            })
        );
    }
}