<div class="container">
    <div class="row">
        <div class="col-12" *ngIf="!missingParams">
            <h3>
                We hebben een Dagje Uit voucher t.w.v. € 5.- voor je!
            </h3>
            <p>
                Iemand wil graag een voucher t.w.v. 5.- met je delen.
            </p>
            <p>
                Klik op onderstaande knop om dit te accepteren.
            </p>
            <button
                    type="submit" class="btn btn-lg share-button mt-4" (click)="attemptDeeplink()">
                {{'SHARE.button' | translate}}
            </button>
        </div>
        <div class="col-8" *ngIf="missingParams">
            <h3>
                Helaas er ging iets mis.
            </h3>
            <p>
                Het delen van de voucher is helaas niet gelukt, maar geen zorgen, je kunt het gewoon opnieuw proberen!
            </p>
        </div>
    </div>
</div>