import {Injectable} from "@angular/core";
import {SubscriberContract} from "../../../../dispatch/contracts/subscriber.contract";
import {LostTicketsPayload} from "../../../../interfaces/lost-tickets.interface";
import {LostTicketsService} from "../../../../services/lost-tickets.service";

@Injectable()
export class SendLostTicketsEmailSubscriber implements SubscriberContract<void> {
    constructor(
        private lostTicketsService: LostTicketsService
    ) {}

    async handle(event: LostTicketsPayload): Promise<void> {
         this.lostTicketsService.sendLostTicketsEmail(event);
    }
}
