import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AccountComponent} from "./components/account/account.component";
import {LoginComponent} from "./components/login/login.component";
import {PlusGuard} from "./guard/plus.guard";
import {DownloadAppComponent} from "./components/download-app/download-app.component";
import {RegisterComponent} from "./components/register/register.components";
import {ForgotPasswordComponent} from "./components/forgot-password/forgot-password.component";
import {DeleteAccountComponent} from "./components/delete-account/delete-account.component";
import {HowToDeleteComponent} from "./components/how-to-delete/how-to-delete.component";
import {ShareComponent} from "./components/share/share.component";

const routes: Routes = [
    {
        path: 'home',
        component: AccountComponent,
        canActivate: [PlusGuard]
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'register',
        component: RegisterComponent
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent
    },
    {
        path: 'download-app',
        component: DownloadAppComponent
    },
    {
        path: 'delete',
        component: DeleteAccountComponent,
        canActivate: [PlusGuard]
    },
    {
        path: 'how-to-delete',
        component: HowToDeleteComponent,
    },
    {
        path: 'voucher/share/scan/:code',
        component: ShareComponent
    }
];


@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PlusRoutingModule { }