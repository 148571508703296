import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable()
export class LostTicketsProvider {

    constructor() {
    }

    private lostTicketsMessageSubscription = new BehaviorSubject<string>('');
    private sentEmailSubscription = new BehaviorSubject<boolean>(false);

    lostTicketsMessage(): BehaviorSubject<string> {
        return this.lostTicketsMessageSubscription;
    }

    sentEmail(): BehaviorSubject<boolean> {
        return this.sentEmailSubscription;
    }

}