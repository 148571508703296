export class LoyaltyReservation {
    constructor(
        private _loyaltyReservationUuid: string,
        private _balance: number,
        private _effectiveBalance: number,
        private _voucherSessionUuid: string,
        private _amount: number,
        private _conversionRatio: number,
        private _applicationType: string
    ) {}

    loyaltyReservationUuid(): string {
        return this._loyaltyReservationUuid;
    }

    voucherSessionUuid(): string {
        return this._voucherSessionUuid;
    }

    balance(): number {
        return this._balance;
    }

    effectiveBalance(): number {
        return this._effectiveBalance;
    }

    amount(): number {
        return this._amount;
    }

    conversionRatio(): number {
        return this._conversionRatio;
    }

    applicationType(): string {
        return this._applicationType;
    }
}