export class AddPlusVoucher {
    constructor(
        private _savedVouchers: string[] | null,
        private _existingVouchers: string[] | null,
        private _invalidVouchers: string[] | null

    ) {}

    savedVouchers(): string[] | null {
        return this._savedVouchers;
    }

    existingVouchers(): string[] | null{
        return this._existingVouchers;
    }

    invalidVouchers(): string[] | null {
        return this._invalidVouchers;
    }
}