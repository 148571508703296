import {Injectable} from "@angular/core";
import {ReplaySubject} from "rxjs";
import {StandardEvent} from "../standard.event";
import {CreateOrderEventPayload} from "../../../interfaces/payment.interface";


@Injectable()
export class CreateOrderEvent extends StandardEvent<CreateOrderEventPayload> {

    constructor() {
        super(new ReplaySubject<CreateOrderEventPayload>(1))
    }

    dispatch(data: CreateOrderEventPayload): void {
        this.subject.next(data);
    }
}