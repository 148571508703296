import {Injectable} from "@angular/core";
import {SubscriberContract} from "../../contracts/subscriber.contract";
import {of} from "rxjs";
import {ContentPartner} from "../../../models/content-partner/content-partner.model";
import {AttractionProvider} from "../../../providers/attraction.provider";

@Injectable()
export class SelectContentPartnerSubscriber implements SubscriberContract<ContentPartner> {
    constructor(private attractionMainProvider: AttractionProvider) {}

    async handle(event: ContentPartner): Promise<ContentPartner> {
        this.attractionMainProvider.selectedContentPartner().next(event);
        return of(event).toPromise();
    }
}