import {Injectable} from "@angular/core";
import {SubscriberContract} from "../../../../dispatch/contracts/subscriber.contract";
import {AnalyticsProvider} from "../../analytics-provider";

@Injectable()
export class InitializeCheckoutSubscriber implements SubscriberContract<void> {
    constructor(private analyticsProvider: AnalyticsProvider) {}

    async handle(reservationId: string|undefined): Promise<void> {
        this.analyticsProvider.startCheckout();
    }
}