import {ModuleWithProviders, NgModule} from "@angular/core";
import {PlusProvider} from "./plus.provider";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../../components/shared/shared.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CusAutocompleteModule} from "../../components/shared/cus-autocomplete/cus-autocomplete.module";
import {PlusEventRegistry} from "./plus-event.registry";
import {EventsDispatcher} from "../../dispatch/events.dispatcher";
import {GetPlusWalletSubscriber} from "./dispatch/subscribers/get-plus-wallet.subscriber";
import {AddPlusVoucherEvent} from "./dispatch/events/add-plus-voucher.event";
import {ApplyPlusWalletVouchersSubscriber} from "./dispatch/subscribers/apply-plus-wallet-vouchers.subscriber";
import {WalletDiscountComponent} from "./components/discount/wallet-discount.component";
import {WalletChangeQuantityEvent} from "./dispatch/events/wallet-change-quantity.event";
import {WalletReservationPlacedEvent} from "./dispatch/events/wallet-reservation-placed.event";
import {AccountComponent} from "./components/account/account.component";
import {LoginComponent} from "./components/login/login.component";
import {PlusRoutingModule} from "./plus-routing.module";
import {RemoveZeroDecimalsPipe} from "../../components/checkout/calendar-v2/calendar.component";
import {GetPlusWalletEvent} from "./dispatch/events/get-plus-wallet.event";
import {AddPlusVoucherSubscriber} from "./dispatch/subscribers/add-plus-voucher.subscriber";
import {PlusGuard} from "./guard/plus.guard";
import {DownloadAppComponent} from "./components/download-app/download-app.component";
import { DownloadAppBannerComponent} from "./components/download-app-banner/download-app-banner";
import {RegisterComponent} from "./components/register/register.components";
import {ForgotPasswordComponent} from "./components/forgot-password/forgot-password.component";
import {DeleteAccountComponent} from "./components/delete-account/delete-account.component";
import {LostTicketsModule} from "../lost-tickets/lost-tickets.module";
import {LostTicketsEventRegistry} from "../lost-tickets/lost-tickets-event.registry";
import {SendLostTicketsEmailEvent} from "../lost-tickets/dispatch/events/send-lost-tickets-email.event";
import {LostTicketsProvider} from "../lost-tickets/lost-tickets.provider";
import {HowToDeleteComponent} from "./components/how-to-delete/how-to-delete.component";
import {ShareComponent} from "./components/share/share.component";
import {RouterStateSnapshot} from "@angular/router";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        CusAutocompleteModule,
        PlusRoutingModule,
        LostTicketsModule
    ],
    providers: [
        PlusGuard,
        LostTicketsEventRegistry,
        SendLostTicketsEmailEvent,
        LostTicketsProvider
    ],
    declarations: [
        WalletDiscountComponent,
        AccountComponent,
        LoginComponent,
        DownloadAppComponent,
        DownloadAppBannerComponent,
        RegisterComponent,
        ForgotPasswordComponent,
        DeleteAccountComponent,
        RemoveZeroDecimalsPipe,
        HowToDeleteComponent,
        ShareComponent
    ],
    exports: [
        WalletDiscountComponent,
        AccountComponent,
        LoginComponent,
        DownloadAppComponent,
        RemoveZeroDecimalsPipe
    ]
})

export class PlusModule {
    static forRoot(): ModuleWithProviders<PlusModule> {
        return {
            ngModule: PlusModule,
            providers: [
                PlusProvider,
                GetPlusWalletEvent,
                GetPlusWalletSubscriber,
                AddPlusVoucherEvent,
                AddPlusVoucherSubscriber,
                ApplyPlusWalletVouchersSubscriber,
                WalletChangeQuantityEvent,
                WalletReservationPlacedEvent,
                {
                    provide: PlusEventRegistry,
                    useFactory: LoadPlusEventRegistry,
                    deps: [EventsDispatcher]
                }
            ]
        };
    }
}

function LoadPlusEventRegistry(dispatcher: EventsDispatcher): any {
    return new PlusEventRegistry(dispatcher);
}