import {Injectable} from "@angular/core";
import {SubscriberContract} from "../../contracts/subscriber.contract";
import {ReserveTimeslot} from "../../../models/timeslots/reserve-timeslot.model";
import {CheckoutProvider} from "../../../providers/checkout.provider";
import {of} from "rxjs";

@Injectable()
export class ReserveTimeslotSubscriber implements SubscriberContract<ReserveTimeslot | null>  {
    constructor(private checkoutProvider: CheckoutProvider) {}

    async handle(orderReservationId: string): Promise<ReserveTimeslot | null>{
        const selectedTimeslot = this.checkoutProvider.selectedTimeslot().getValue();

        if (selectedTimeslot) {
            return this.checkoutProvider.reserveTimeslot(selectedTimeslot.timeslotFrom(), orderReservationId).toPromise();
        } else {
            return of(null).toPromise();
        }
    }
}