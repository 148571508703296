import {Injectable} from "@angular/core";
import {Observable, ReplaySubject} from "rxjs";
import {LoyaltyReservation} from "../../models/loyalty/loyalty-reservation.model";
import {LoyaltyReservationPlacedEvent} from "./dispatch/events/loyalty-reservation-placed.event";
import {
    PlaceLoyaltyReservationProductsRequest,
    PlaceLoyaltyReservationRequest
} from "../../interfaces/loyalty.interface";
import {map} from "rxjs/operators";
import {LoyaltyService} from "../../services/loyalty.service";
import {Reservation} from "../../models/checkout/reservation.model";

@Injectable()
export class LoyaltyProvider {

    private loyaltySubscription = new ReplaySubject<LoyaltyReservation | null>(1);

    constructor(
        private loyaltyService: LoyaltyService,
        private loyaltyReservationPlacedEvent: LoyaltyReservationPlacedEvent,
    ) {}

    loyalty(): ReplaySubject<LoyaltyReservation | null> {
        return this.loyaltySubscription;
    }

    resetLoyalty(): void {
        this.loyaltySubscription.next(null);
    }

    placeLoyaltyReservation(reservation: Reservation, amount?: number): Observable<LoyaltyReservation|null> {

        let loyaltyReservationProducts: PlaceLoyaltyReservationProductsRequest[] = [];
        reservation.reservationProducts().forEach((product) => {
            let productQuantity = product.quantity()
            if (productQuantity) {
                loyaltyReservationProducts.push({
                    quantity: productQuantity,
                    product_uuid: product.productUuid()
                });
            }
        });

        const loyaltyRequest: PlaceLoyaltyReservationRequest = {
            max_applicable_vouchers: reservation.maxApplicableVouchers(),
            amount: amount,
            location_uuid: reservation.locationUuid(),
            visit_date: reservation.visitDateAsString(),
            products: loyaltyReservationProducts
        }

        return this.loyaltyService.placeReservation(loyaltyRequest).pipe(
            map((data) => {
                this.loyaltySubscription.next(data);

                if (data != null) {
                    this.loyaltyReservationPlacedEvent.dispatch(data);
                }

                return data;
            })
        );
    }
}