import {Injectable} from "@angular/core";
import {SubscriberContract} from "../../../../dispatch/contracts/subscriber.contract";
import {Reservation} from "../../../../models/checkout/reservation.model";
import {CheckoutProvider} from "../../../../providers/checkout.provider";
import {first, map} from "rxjs/operators";
import {StorageNS, StorageService} from "../../../../services/storage.service";
import {PlusService} from "../../plus.service";
import {PlusProvider} from "../../plus.provider";
import {WalletReservationPlacedEvent} from "../events/wallet-reservation-placed.event";

@Injectable()
export class ApplyPlusWalletVouchersSubscriber implements SubscriberContract<void> {
    constructor(
        private checkoutProvider: CheckoutProvider,
        private storageService: StorageService,
        private plusService: PlusService,
        private plusProvider: PlusProvider,
        private walletReservationPlacedEvent: WalletReservationPlacedEvent
    ) {
    }

    async handle(reservation: Reservation, amount?: number): Promise<void> {
        //slice
        return this.plusProvider.wallet().pipe(
            first(),
            map(((wallet) => {
                if (!wallet || !wallet.balance()) {
                    return;
                }

                let voucherSessionUuid: string = this.storageService.getItem(StorageNS.VOUCHER_SESSION_UUID) ?? reservation.voucherSessionUuid();

                this.checkoutProvider.getVouchersOfVoucherSession(voucherSessionUuid).pipe(
                    first()
                ).subscribe((currentVouchers) => {
                    amount = amount ? Math.min(amount, wallet.balance(), reservation.maxApplicableVouchers()) : Math.min(reservation.maxApplicableVouchers(), wallet.balance());

                    if (currentVouchers.length == amount) {
                        return;
                    }

                    if (currentVouchers.length > amount) {
                        this.checkoutProvider.removeVouchersFromVoucherSession(voucherSessionUuid, currentVouchers.slice(0, currentVouchers.length - amount)).pipe(first()).subscribe(() => {
                            this.walletReservationPlacedEvent.dispatch(reservation);
                        });
                        return;
                    }

                    const unusedVouchers = wallet.vouchers().filter((walletVoucher) => {

                        for (let i = 0; i < currentVouchers.length; i++) {
                            if (currentVouchers[i] == walletVoucher.coin()) {
                                return false;
                            }
                        }

                        return true;
                    });

                    let vouchers = unusedVouchers.slice(0, (amount - currentVouchers.length)).map((voucher) => {
                        return voucher.coin();
                    });

                    this.checkoutProvider.addVouchersToVoucherSession(voucherSessionUuid, vouchers).pipe(
                        first()
                    ).subscribe((result) => {
                        this.walletReservationPlacedEvent.dispatch(reservation);
                    });
                });
            })
        )).toPromise();
    }
}