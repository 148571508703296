<nav aria-label="Page navigation example" *ngIf="!isMobile">
  <ul class="pagination">
      <li [ngClass]="{'disabled': (this.index == 1 || this.totalPages == 1 || this.totalPages == 2 || this.totalPages == 3)}"
          class="page-item" tabindex="-1" aria-disabled="true" (click)="previousPage()">
          <span class="page-link">
              <svg class="icon btn-icon icon-16" xmlns="http://www.w3.org/2000/svg" role="img"
                  focusable="false">
                  <title>Previous</title>
                  <use xlink:href="assets/images/icon__sprite.svg#arrow-left">
                  </use>
              </svg>
          </span>
      </li>

      <ng-container *ngIf="totalPages === 1">
        <li class="page-item active">
          <span class="page-link">1</span>
        </li>
      </ng-container>

      <ng-container *ngIf="totalPages === 2">
        <li class="page-item" [ngClass]="{'active': this.index == 1}" (click)="changePage(1)">
          <span class="page-link">1</span>
        </li>
        <li class="page-item" [ngClass]="{'active': this.index == 2}" (click)="changePage(2)">
          <span class="page-link">2</span>
        </li>
      </ng-container>

      <ng-container *ngIf="totalPages === 3">
        <li class="page-item" [ngClass]="{'active': this.index == 1}" (click)="changePage(1)">
          <span class="page-link">1</span>
        </li>
        <li class="page-item" [ngClass]="{'active': this.index == 2}" (click)="changePage(2)">
          <span class="page-link">2</span>
        </li>
        <li class="page-item" [ngClass]="{'active': this.index == 3}" (click)="changePage(3)">
          <span class="page-link">3</span>
        </li>
      </ng-container>

      <ng-container *ngIf="totalPages != 1 && totalPages != 2 && totalPages != 3">
        <ng-container *ngIf="index === 1">
          <li class="page-item active">
            <span class="page-link">{{index}}</span>
          </li>
          <li class="page-item" aria-current="page" (click)="changePage(index+1)">
              <span class="page-link">{{index+1}}</span>
          </li>
          <li class="page-item" (click)="changePage(index+2)">
              <span class="page-link">{{index+2}}</span>
          </li>
        </ng-container>

        <ng-container *ngIf="index != 1 && index != totalPages">
          <li class="page-item" (click)="changePage(index-1)">
            <span class="page-link">{{index-1}}</span>
          </li>
          <li class="page-item active" aria-current="page">
              <span class="page-link">{{index}}</span>
          </li>
          <li class="page-item" (click)="changePage(index+1)">
              <span class="page-link">{{index+1}}</span>
          </li>
        </ng-container>

        <ng-container *ngIf="index === totalPages">
          <li class="page-item" (click)="changePage(index-2)">
            <span class="page-link">{{index-2}}</span>
          </li>
          <li class="page-item" (click)="changePage(index-1)" aria-current="page">
              <span class="page-link">{{index-1}}</span>
          </li>
          <li class="page-item active">
              <span class="page-link">{{index}}</span>
          </li>
        </ng-container>
      </ng-container>

      <li [ngClass]="{'disabled': (this.index == this.totalPages || this.totalPages == 1 || this.totalPages == 2 || this.totalPages == 3)}"
          class="page-item" (click)="nextPage()">
          <span class="page-link">
            <svg class="icon btn-icon icon-16" xmlns="http://www.w3.org/2000/svg" role="img"
                focusable="false">
                <title>Next</title>
                <use xlink:href="assets/images/icon__sprite.svg#arrow-right">
                </use>
            </svg>
          </span>
      </li>
  </ul>
</nav>
