import {Injectable} from "@angular/core";
import {SubscriberContract} from "../../contracts/subscriber.contract";
import {CoreProvider} from "../../../providers/core.provider";

@Injectable()
export class ChangeRefSubscriber implements SubscriberContract<void> {
    constructor(private coreProvider: CoreProvider) {}

    async handle(): Promise<void> {
        this.coreProvider.changeDetector().next();
    }
}