import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import { environment as env } from '../environments/environment';
import { DialogService } from './core/dialog/services/dialog.service';
import { CookiesComponent } from './core/dialogs/cookies/cookies.component';
import { AppService } from './services/app.service';
import { UiService } from './services/ui.service';
import {CoreProvider} from "./providers/core.provider";
import {ActivatedRoute} from "@angular/router";
import {debounceTime, first, map} from "rxjs/operators";
import {CookiePreferences} from "./interfaces/cookies.interface";
import {HttpClient} from "@angular/common/http";


interface IpResponse {
  ip: string;
}

@Component({
  selector: 'qup-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  _subs: Subscription[] = [];
  title = 'Attractions & Events';
  try: any = 0;


  constructor(

    private renderer: Renderer2,
    private appS: AppService,
    private dialog: DialogService,
    private uiS: UiService,
    private coreProvider: CoreProvider,
    private changeDetectorRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient
  ) {
    renderer.addClass(document.body, env.theme);
  }

  ngOnInit(): void {

    this.coreProvider.changeDetector().subscribe(() => this.changeDetectorRef.detectChanges());

    this._subs.push(
      this.uiS.isMobileOverlay$.subscribe((isMobile) =>
        isMobile ? this.addIsMobileClass() : this.removeIsMobileClass()
      )
    );
  }

  ngAfterViewInit(): void {
    this.activatedRoute.queryParams.pipe(debounceTime(1000)).subscribe((queryParams) => {
      if (this.cookieConsentMissing()) {
        if (!queryParams.embedded) {
          this.openCookieDialog();
        } else {
          this.appS.cookiePreferences = {
            technical: true,
            functional: true
          } as CookiePreferences;
        }
      }
    });

    return;
  }

  cookieConsentMissing(): boolean {
    return !this.appS.cookiePreferences;
  }

  addIsMobileClass(): void {
    this.renderer.addClass(document.body, 'is-mobile');
  }

  removeIsMobileClass(): void {
    this.renderer.removeClass(document.body, 'is-mobile');
  }

  openCookieDialog(): void {
    const dRef = this.dialog.open(CookiesComponent, {
      overlayClass: 'cookie-dialog-overlay',
    });
  }

  cleanup(): void {
    this.removeIsMobileClass();
  }

  ngOnDestroy(): void {
    this._subs.forEach(s => s?.unsubscribe());

    this.cleanup();
  }
}
