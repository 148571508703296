import {Injectable} from "@angular/core";
import {StandardEvent} from "../../../../dispatch/events/standard.event";
import {ReplaySubject} from "rxjs";
import {LostTicketsPayload} from "../../../../interfaces/lost-tickets.interface";

@Injectable()
export class SendLostTicketsEmailEvent extends StandardEvent<LostTicketsPayload> {
    constructor() {
        super(new ReplaySubject<LostTicketsPayload>());
    }

    dispatch(payload: LostTicketsPayload): void {
        this.subject.next(payload);
    }
}