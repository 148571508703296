import {Injectable} from "@angular/core";
import {ReplaySubject} from "rxjs";
import {StandardEvent} from "../../../../dispatch/events/standard.event";
import {Reservation} from "../../../../models/checkout/reservation.model";

@Injectable()
export class WalletReservationPlacedEvent extends StandardEvent<Reservation> {

    constructor() {
        super(new ReplaySubject<Reservation>());
    }

    dispatch(cargo: Reservation): void {
        this.subject.next(cargo);
    }
}