import {Injectable} from "@angular/core";
import {SubscriberContract} from "../../contracts/subscriber.contract";
import {AttractionProvider} from "../../../providers/attraction.provider";
import {ContentPartner} from "../../../models/content-partner/content-partner.model";
import {tap} from "rxjs/operators";
import {FiltersInput} from "../../../interfaces/content-api.interface";


@Injectable()
export class FilterProductByCategoryCitySubscriber implements SubscriberContract<ContentPartner[]> {
    constructor(private attractionsMainProvider: AttractionProvider) {
    }

    async handle(filters: FiltersInput): Promise<ContentPartner[]> {
        return this.attractionsMainProvider.filterPartnersByCategoryCity(filters.categories, filters.cities).pipe(
            tap((response) => {

                if (filters.sortBy) {
                    switch (filters.sortBy) {
                        case 'name_asc':
                            response.sort((a, b) => a.caption().localeCompare(b.caption()));
                            break;
                        case 'name_desc':
                            response.sort((a, b) => b.caption().localeCompare(a.caption()));
                            break;
                        case 'discount_desc':
                            response.sort((a, b) => b.maxDiscountPercentage() - a.maxDiscountPercentage())
                            break;
                        case 'index':
                            response.sort((a, b) => a.sortIndex() - b.sortIndex())
                            break;
                    }
                }

                this.attractionsMainProvider.contentPartners().next(response);
            })
        ).toPromise();
    }
}